
import styled from 'styled-components'
import TopMenu from 'components/Product/Organisms/TopMenu';
import { MENU_STATE } from 'components/Product/Organisms/SideMenu/SideMenu';

export const MenuBar = styled.div`
z-index: 5;
position: fixed;
top: 0;
width: 100%;
${props => props.app ? `height:${props.theme.style.topMenuHeight}px;` : ``}
-webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; 
-moz-user-select: none; -ms-user-select: none; user-select: none;

${props => props.menuState === MENU_STATE.COLLAPSED ?
    `margin-left:${props.theme.style.sideMenuCollapsedWidth}px;` :
    props.menuState === MENU_STATE.EXPANDED ?
    `margin-left:${props.theme.style.sideMenuWidth}px;` :
    `margin-left:0;`}
`

export const TopMenuInApp = styled(TopMenu)`
${props => props.menuState === MENU_STATE.COLLAPSED ?
    `width: calc(100vw - ${props.theme.style.sideMenuCollapsedWidth}px);` :
    props.menuState === MENU_STATE.EXPANDED ?
    `width: calc(100vw - ${props.theme.style.sideMenuWidth}px);` :
    `width: 100%;`}

`