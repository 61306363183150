import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button, { BUTTON_STYLE, BUTTON_SIZE } from 'components/Product/Atoms/Button/Button'
import Icon from 'components/Product/Atoms/Icon/Icon'

import { appTheme } from "config/theme"
import { getRGBA } from 'shared/utils'

const fixedHeight = 72;

export const STATE = {
  UPLOADING: "UPLOADING",
  ANALYZING: "ANALYZING",
  DONE: "DONE",
  ERROR: "ERROR",
}

const getColorBasedOnState = (theme, currentState) => {
  const stateToColorMapping = {
    UPLOADING: theme.colors.uploadingColor,
    ANALYZING: theme.colors.analyzingColor,
    DONE: theme.colors.analyzingColor,
    ERROR: theme.colors.errorColor,
  }
  return stateToColorMapping[currentState]
}

const getTextBasedOnState = (currentState, labels) => {
  const stateToTextMapping = {
    UPLOADING: labels.uploading,
    ANALYZING: labels.analyzing,
    DONE: labels.done,
    ERROR: labels.error,
  }
  return stateToTextMapping[currentState]
}
const getDescriptionBasedOnState = (currentState, labels, currentValue, minutesLeft) => {
  let minutes = minutesLeft
  if (minutesLeft === undefined || minutesLeft < 0) {
    minutes = "??"
  }
  const stateToDescriptionMapping = {
    UPLOADING: `${currentValue}% • ${minutes} ${labels.uploadingDescription}`,
    ANALYZING: `${currentValue}% • ${labels.analyzingDescription}`,
    DONE: labels.doneDescription,
    ERROR: labels.errorDescription,
  }
  return stateToDescriptionMapping[currentState]
}

const renderButtonBasedOnState = (currentState, labels, theme, id, onClick, onAbort) => {
  const stateToButtonLabelMapping = {
    UPLOADING: {
      label: labels.abort,
      buttonStyle: BUTTON_STYLE.FOURTH,
      clickEvent: onAbort,
    },
    DONE: {
      label: labels.checkResultButton,
      buttonStyle: BUTTON_STYLE.SECONDARY,
      clickEvent: onClick,
    },
    // ERROR: {
    //   label: labels.checkResultButton,
    //   buttonStyle: BUTTON_STYLE.FOURTH,
    //   clickEvent: onClick,
    // },
  }
  if (stateToButtonLabelMapping[currentState] === undefined) {
    return null
  }
  const data = stateToButtonLabelMapping[currentState];

  return <Button id={`${id}_button`} label={data.label} buttonStyle={data.buttonStyle} theme={theme} onClick={() => {data.clickEvent(currentState)}} size={BUTTON_SIZE.NORMAL} />
}

const UploadProgressIndicatorContainer = styled.div`
  height: ${fixedHeight}px;
  position: relative;
  transition: ${props => props.theme.style.transitionTime};
`

const UploadProgressIndicatorContent = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  height: ${fixedHeight}px;
  justify-content: space-between;
  left: 0;
  padding: ${props => props.theme.style.paddingLarge.join("px ")}px;
  position: absolute;
  right: 0;
  top: 0;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Text = styled.span`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fonts.small}px;
  ${props => props.bold ? 'font-weight:' + props.theme.fonts.boldWeight + ';' : ''}
  ${props => props.uppercase ? 'text-transform: uppercase;' : ''}
`

const StyledIndicator = styled.div`
  height: ${fixedHeight}px;

  ${props => props.value > 0 ? `width: ${parseInt(props.value)}%;` : `display: none;`};
  ${props => (props.state === STATE.DONE || props.state === STATE.ERROR) ? `width: 100%;` : ``}; 

  background: ${props => getRGBA(getColorBasedOnState(props.theme, props.state), 0.2)};
    -webkit-animation-name: pulse; /* Chrome, Safari, Opera */
    -webkit-animation-duration: 3s; /* Chrome, Safari, Opera */
    -webkit-animation-iteration-count: infinite;
    animation-name: pulse;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }


  @-webkit-keyframes pulse {
    0% {opacity: 1;}
    10% {opacity: 0.9;}
    20% {opacity: 0.8;}
    30% {opacity: 0.7;}
    40% {opacity: 0.6;}
    50% {opacity: 0.5;}
    60% {opacity: 0.6;}
    70% {opacity: 0.7;}
    80% {opacity: 0.8;}
    90% {opacity: 0.9;}
    100% {opacity: 1;}
  }

  @keyframes pulse {
    0% {opacity: 1;}
    25% {opacity: 0.8;}
    50% {opacity: 0.6;}
    75% {opacity: 0.8;}
    100% {opacity: 1;}
  }
`

export default function UploadProgressIndicator({
  id, collapsed, state, value, minutesLeft, minimal, renderButton,
  onClick, onAbort,
  ...props
}) {

  return <UploadProgressIndicatorContainer id={id} collapsed={collapsed} {...props} onClick={() => {onClick(state)}} data-state={state} data-progress={value}>
    <UploadProgressIndicatorContent theme={props.theme}>
      {(!collapsed) ?
        <>
          <TextContainer>
            <Text bold uppercase style={{ color: props.theme.colors.textPrimary }} theme={props.theme} >
              {getTextBasedOnState(state, props.labels)}
            </Text>
            {!minimal &&
              <Text style={{ color: props.theme.colors.textSecondary }} theme={props.theme}>
                {getDescriptionBasedOnState(state, props.labels, value, minutesLeft)}
              </Text>}
          </TextContainer>
          {renderButton && renderButtonBasedOnState(state, props.labels, props.theme, id, onClick, onAbort)}
        </>
        :
        <>
          <Icon iconKey="Upload" style={{ height: props.theme.style.iconSize, width: props.theme.style.iconSize }}
            fill={props.theme.colors.textPrimary} />
        </>
      }
    </UploadProgressIndicatorContent>
    <StyledIndicator id={`${id}_progress`} state={state} max={100} value={value} theme={props.theme} />
  </UploadProgressIndicatorContainer>
}

UploadProgressIndicator.propTypes = {
  /**
   * Component ID
   */
  id: PropTypes.string,
  /**
   * Component collapsed
   */
  collapsed: PropTypes.bool,
  /**
   * Component should be minimal
   */
  minimal: PropTypes.bool,
  /**
   * Component state
   */
  state: PropTypes.oneOf(Object.values(STATE)),
  /**
   * Component value unit
   */
  value: PropTypes.number,
  /**
   * Component time left in minutesLeft
   */
  minutesLeft: PropTypes.number,
  /**
   * Render interactive button for state?
   */
  renderButton: PropTypes.bool,
  /**
   * Card labels
   */
  labels: PropTypes.shape({
    analyzing: PropTypes.string.isRequired,
    uploading: PropTypes.string.isRequired,
    done: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    abort: PropTypes.string,
    uploadingDescription: PropTypes.string.isRequired,
    analyzingDescription: PropTypes.string.isRequired,
    doneDescription: PropTypes.string.isRequired,
    errorDescription: PropTypes.string.isRequired,
    checkResultButton: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Component brand theme
   */
  theme: PropTypes.object.isRequired,
  /**
   * Component click handle event
   */
  onClick: PropTypes.func,
  onAbort: PropTypes.func,
}

UploadProgressIndicator.defaultProps = {
  id: undefined,
  collapsed: false,
  minimal: false,
  state: STATE.UPLOADING,
  value: undefined,
  minutesLeft: undefined,
  renderButton: true,
  theme: {...appTheme},
  onClick: undefined,
  onAbort: undefined,
}