import React from 'react';
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from 'components/Toast'

export const showToast = (params) => {
  //const autoClose = (params.autoClose === false) ? false : toastTime
  switch (params.type) {
    case "error":
      toast.error(params.message, {
        toastId: params.id,
        theme: "colored",
        autoClose: params.autoClose,
      });
      break;
    
    case "warning":
      toast.warning(params.message, {
        toastId: params.id,
        theme: "colored",
        autoClose: params.autoClose,
      });
      break;
    
    case "success":
      toast.success(params.message, {
        toastId: params.id,
        theme: "colored",
        autoClose: params.autoClose,
      });
      break;
    
    case "info":
      toast.info(params.message, {
        toastId: params.id,
        theme: "colored",
        autoClose: params.autoClose,
      });
      break;
    
    case "motd":
      const urlOpen = (url) => {
        if(url && (url !== "")){
          const element = document.createElement("A")
          element.setAttribute("href", url)
          element.setAttribute("target", "_blank")
          document.body.appendChild(element)
          element.click()
        }
      }
      toast.info(params.message, {
        toastId: params.id,
        theme: "colored",
        autoClose: false,
        closeButton: false,
        closeOnClick: (typeof params.closeOnClick === "undefined") ? false : params.closeOnClick,
        draggable: false,
        onClose: (params.closeOnClick) ? ({ uid }) => urlOpen(params.url) : undefined,
        onClick: (!params.closeOnClick) ? ({ uid }) => urlOpen(params.url) : undefined
      });
      break;
    
    case "message":
      const toastId = params.key || "message"
      /* istanbul ignore next */
      if (!toast.isActive(toastId)) {
        toast(
          <Toast id={params.id} dataId={params.dataId} type="message" message={params.message} title={params.title} theme={params.theme} />, { 
            autoClose: params.autoClose, className: "message-box", toastId: toastId,
            //position:"top-center", 
            closeButton: false, transition: Zoom })
      }
      break;
  
    default:
      toast(params.message, { autoClose: params.autoClose })
      break;
  }
}