/**
 * TagManager class to manage injecting GTM or GA4 codes into all pages
 * Then manage sending custom events based on what is enabled
 * 
 * If GTM is set up, it takes precedence over GA4, only ONE will be loaded
 * When GTM is set up, automatic event tracking for GA4 only works if it was set up in the GTM settings
 * Event tracking can be set up with "OMNICOACH*" key so all events are passed properly in GTM
 */
class TagManager {
	constructor(gtagId, ga4MeasurementID) {
		this.gtagId = gtagId;
		this.ga4MeasurementID = ga4MeasurementID;
		this.userData = {}
		this.isGtmEnabled = gtagId !== undefined && (gtagId !== "")
		this.isGa4Enabled = !this.isGtmEnabled && ga4MeasurementID !== undefined && (ga4MeasurementID !== "")
	}
	initUserData(userData) {
		this.userData = userData;
	}
	injectHeadCode() {
		if (this.isGtmEnabled) {
			return <script>{`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTAG_ID');`.replace("GTAG_ID", this.gtagId)}
			</script>
		} else if (this.isGa4Enabled) {
			return <>
				<script async src={"https://www.googletagmanager.com/gtag/js?id=" + this.ga4MeasurementID}></script>
				<script>{`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'MEASUREMENT_ID');
                `.replace("MEASUREMENT_ID", this.ga4MeasurementID)}
				</script>
			</>
		}
	}
	injectBodyCode() {
		if (this.isGtmEnabled) {
			return <script>{`
              <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTAG_ID"
              height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
              `.replace("GTAG_ID", this.gtagId)}
			</script>
		}
	}
	sendEvent(eventType, contextData) {
		if (this.isGtmEnabled && window.dataLayer) {
			window.dataLayer.push({
				event: eventType,
				...contextData,
				...this.userData,
			})
		} else if (this.isGa4Enabled && window.gtag) {
			window.gtag("event", eventType, {
				...contextData,
				...this.userData,
			});
		}
	}
}


export default TagManager