import PropTypes from 'prop-types';
import styled from 'styled-components'

import { appTheme } from "config/theme"
import Icon, { ICON_KEY } from 'components/Product/Atoms/Icon/Icon'
import { getRGBA } from 'shared/utils'

export const TEXT_INPUT_TYPE = {
  STRING: "string",
  TEXT: "text"
}

export const TEXT_INPUT_SIZE = {
  NORMAL: "normal",
  SMALL: "small",
}

const Input = styled.input`
  background-color: ${props => props.theme.colors.midPanelBackground};
  border-radius: ${props => props.theme.style.borderRadius.join("px ")}px;
  border: ${props => props.theme.style.border} ${props => props.error ? props.theme.colors.borderColorError : props.theme.colors.borderColor};
  box-sizing: border-box;
  color: ${props => props.theme.colors.textPrimary};
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fonts.small}px;
  padding: ${props => props.inputPadding.join("px ")}px;
  position: relative;
  width: 100%;

  height: ${props => (props.size === TEXT_INPUT_SIZE.NORMAL)
    ? props.theme.style.inputHeight : props.theme.style.inputHeightSmall}px;
  
  &::placeholder {
    color: ${props => props.theme.colors.placeholderColor};
    opacity: 1; /* Firefox */
  }
  
  &:-ms-input-placeholder {
    color: ${props => props.theme.colors.placeholderColor}
    opacity: 1; /* Firefox */
  }
  
  &:-ms-input-placeholder {
    color: ${props => props.theme.colors.placeholderColor}
    opacity: 1; /* Firefox */
  }
  
  &:disabled {
    color: ${props => getRGBA(props.theme.colors.textPrimary, 0.5)};
  };

	&:active,
	&:focus {
		outline: none;
		border: ${props => props.theme.style.border} ${props => props.theme.colors.textPrimary};
	}
  input:-internal-autofill-selected {
    background-color: transparent;
  }
`

const TextArea = styled.textarea`
font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fonts.small}px;
  border-radius: ${props => props.theme.style.borderRadius.join("px ")}px;
  border: ${props => props.theme.style.border} ${props => props.error ? props.theme.colors.borderColorError : props.theme.colors.borderColor};
  color: ${props => props.theme.colors.textPrimary};
  background-color: ${props => props.theme.colors.midPanelBackground};
  padding: ${props => props.theme.style.paddingAllSmall.join("px ")}px;
  width: 100%;
  box-sizing: border-box;
  &:placeholder {
    color: ${props => props.theme.colors.placeholderColor};
  };
  &:-ms-input-placeholder {
    color: ${props => props.theme.colors.placeholderColor}
  };
  &:-ms-input-placeholder {
    color: ${props => props.theme.colors.placeholderColor}
  };
  &:disabled {
    color: ${props => getRGBA(props.theme.colors.textPrimary, 0.5)};
  };

	&:active,
	&:focus {
		outline: none;
		border: ${props => props.theme.style.border} ${props => props.theme.colors.textPrimary};
	}
`

const ComponentFrame = styled.div`
  position: relative;
  width: 100%;
`

export default function TextInput({
  textType, size, icon,
  onChange, onBlur, onEnter,
  ...props
}) {
  const blurEvent = (event) => {
    event.stopPropagation();
    if (onBlur) {
      onBlur()
    }
  }
  const keyDownEvent = (event) => {
    event.stopPropagation();
    if (onEnter && event.keyCode === 13) {
      onEnter(props.value)
    }
  }
  const changeEvent = (event) => {
    event.stopPropagation();
    if (onChange) {
      onChange(event.target.value)
    }
  }
  if (textType === TEXT_INPUT_TYPE.TEXT) {
    return <TextArea {...props}
      onChange={changeEvent}
      onKeyDown={keyDownEvent}
      onBlur={blurEvent}
    />
  }
  const iconSize = (size === TEXT_INPUT_SIZE.SMALL)
    ? props.theme.style.iconSizeSmall : props.theme.style.iconSizeSmall
  const iconTop = (size === TEXT_INPUT_SIZE.SMALL)
    ? (props.theme.style.inputHeightSmall - iconSize) / 2
    : (props.theme.style.inputHeight - iconSize) / 2
  const iconLeft = props.theme.style.paddingSmall[props.theme.style.paddingSmall.length - 1]

  const inputPadding = (!icon)
    ? props.theme.style.paddingSmall
    : [props.theme.style.paddingSmall[0],
    props.theme.style.paddingSmall[props.theme.style.paddingSmall.length - 1] + iconLeft / 2 + iconSize]
  return <ComponentFrame>
    {(icon) ? <Icon iconKey={icon}
      style={{
        position: "absolute", zIndex: 1,
        top: iconTop, left: iconLeft,
        height: iconSize, width: iconSize,
        fill: props.theme.colors.textPrimary,
        verticalAlign: "middle"
      }} /> : null}
    <Input {...props}
      size={size} inputPadding={inputPadding}
      onChange={changeEvent}
      onKeyDown={keyDownEvent}
      onBlur={blurEvent}
    />
  </ComponentFrame>

}

TextInput.propTypes = {
  /**
    * Text type
    */
  textType: PropTypes.oneOf(Object.values(TEXT_INPUT_TYPE)).isRequired,
  /**
   * Component size
   */
  size: PropTypes.oneOf(Object.values(TEXT_INPUT_SIZE)).isRequired,
  /**
   * Component value
   */
  value: PropTypes.string.isRequired,
  /**
   * Show/hide error border
   */
  error: PropTypes.bool.isRequired,
  /**
   * Component icon object
   */
  icon: PropTypes.oneOf(ICON_KEY),
  /**
   * Component brand theme
   */
  theme: PropTypes.object.isRequired,
  /**
   * Value change event
   */
  onChange: PropTypes.func,
  /**
   * Lost focus event
   */
  onBlur: PropTypes.func,
  /**
   * Enter key event
   */
  onEnter: PropTypes.func,
}

TextInput.defaultProps = {
  textType: TEXT_INPUT_TYPE.STRING,
  size: TEXT_INPUT_SIZE.NORMAL,
  value: "",
  error: false,
  icon: undefined,
  theme: {...appTheme},
  onChange: undefined,
  onBlur: undefined,
  onEnter: undefined,
}
