import 'whatwg-fetch';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(resp) {
  if (resp && resp.status && resp.body) {
    return resp
  }

  if (!resp || resp === "null") {
    return {}
  } else {
    return resp
  }
}

function getBody(response) {
  if (response.headers.get('content-type').split(";")[0] === "application/json") {
    return response.json()
  }
  return response
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) { 
    return response;
  }

  const error = new Error(response.statusText);
  //error.response = response;
  error.ecode = response.status
  if(response.headers.get('content-type').split(";")[0] === "application/json"){
    return response.json().then(function(parsedJson) {
      error.response = parsedJson
      throw error;
    })
  } else {
    throw error;
  }
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  return fetch(url, options)
    .then(checkStatus)
    .then(getBody)
    .then(parseJSON);
}
