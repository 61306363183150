import PropTypes from 'prop-types';
import styled from 'styled-components'

import { appTheme } from "config/theme"
import Icon, { ICON_KEY } from 'components/Product/Atoms/Icon/Icon'

export const BUTTON_STYLE = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  THIRD: "third",
  FOURTH: "fourth",
  OAUTH: "OAuth",
}

export const BUTTON_ORIENTATION = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical"
}

export const BUTTON_SIZE = {
  NORMAL: "normal",
  SMALL: "small",
}

const backgroundStyle = (props) => {
  const background = {
    "primary": `linear-gradient(90deg, ${props.theme.colors.brandColor} 0%, ${props.theme.colors.brandColorHighlight} 50%, ${props.theme.colors.brandColor} 100%)`,
    "secondary": props.theme.colors.secondaryBackground,
    "third": props.theme.colors.thirdBackground,
    "fourth": props.theme.colors.fourthBackground,
    "OAuth": props.theme.colors.textPrimary,
  }
  return background[props.buttonStyle]
}

const colorStyle = (props) => {
  const color = {
    "primary": props.theme.colors.primaryButtonText,
    "secondary": props.theme.colors.secondaryButtonText,
    "third": props.theme.colors.thirdButtonText,
    "fourth": props.theme.colors.fourthButtonText,
    "OAuth": props.theme.colors.popupBackground,
  }
  return color[props.buttonStyle]
}

const shineStyle = (props) => {
  if (((props.buttonStyle === BUTTON_STYLE.THIRD) || (props.buttonStyle === BUTTON_STYLE.SECONDARY))
    && (props.orientation === BUTTON_ORIENTATION.HORIZONTAL) && (props.theme.colors.thirdBackgroundShadow || props.theme.colors.secondaryBackgroundShadow)) {
    return `
    position: relative;
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      -webkit-box-shadow: 0 0 30px ${props.buttonStyle === BUTTON_STYLE.THIRD ? props.theme.colors.thirdBackgroundShadow : props.theme.colors.secondaryBackgroundShadow};
      box-shadow: 0 0 30px ${props.buttonStyle === BUTTON_STYLE.THIRD ? props.theme.colors.thirdBackgroundShadow : props.theme.colors.secondaryBackgroundShadow};
      bottom: 0px;
      left: 10%;
      right: 10%;
      width: 80%;
      height: 50%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      
    }
    `
  }
  return ``
}

const StyledButton = styled.button`
  align-items: center;
  border: 0px solid transparent;
  cursor: pointer;
  display: ${props => props.wide ? `flex` : `table-row`};
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fonts.small}px;
  font-weight: bold;
  justify-content: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${props => colorStyle(props)};
  background: ${props => backgroundStyle(props)};
  width: ${props => props.wide ? `100%` : `fit-content`};
  border-radius: ${props => props.orientation === BUTTON_ORIENTATION.VERTICAL ? props.theme.style.borderRadiusVertical.join("px ") : props.theme.style.borderRadius.join("px ")}px;
  padding: ${props => (!props.label || (props.label === ""))
    ? props.theme.style.paddingButtonIcon.join("px ")
    : props.size === BUTTON_SIZE.NORMAL
      ? (props.orientation === BUTTON_ORIENTATION.VERTICAL) ? props.theme.style.paddingButtonNormalVertical.join("px ") : props.theme.style.paddingLarge.join("px ")
      : (props.orientation === BUTTON_ORIENTATION.VERTICAL) ? props.theme.style.paddingButtonSmallVertical.join("px ") : props.theme.style.paddingLarge.join("px ")
  }px;
  ${props => shineStyle(props)}
  ${props => ((props.orientation !== BUTTON_ORIENTATION.VERTICAL)) ? props.size === BUTTON_SIZE.NORMAL ? `height: ${props.theme.style.inputHeight}px` : `height: ${props.theme.style.inputHeightSmall}px` : ``};
  ${props => props.fixed && `
    position: fixed;
    top: 50vh;
    right: 0;
    transform: translate(0, -50%);
  `}

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
`

const IconContainer = styled.div`
  line-height: 0;
  ${props => (props.orientation === BUTTON_ORIENTATION.VERTICAL)
    ? `display: table-row;` : `display: table-cell; vertical-align: middle;`}
  ${props =>
    (props.label && (props.label !== ""))
      ? (props.orientation === BUTTON_ORIENTATION.HORIZONTAL)
        ? `width: ${(props.size === BUTTON_SIZE.NORMAL) ? props.theme.style.iconSize + 8
          : props.theme.style.iconSizeSmall + 8}px; padding-right: 8px;`
        : `height: ${(props.size === BUTTON_SIZE.NORMAL)
          ? props.theme.style.iconSize + 6
          : props.theme.style.iconSizeSmall + 6}px; padding-bottom: 8px;`
      : (props.size === BUTTON_SIZE.NORMAL)
        ? `width: ${props.theme.style.inputHeight - props.theme.style.paddingButtonIcon[1] * 2}px;`
        : `width: ${props.theme.style.inputHeightSmall - props.theme.style.paddingButtonIcon[1] * 2}px;`
  }
`

const TextContainer = styled.div`
  display: table-cell;
  vertical-align: middle;

  ${props => (props.orientation === BUTTON_ORIENTATION.VERTICAL)
    ? `writing-mode: vertical-lr; transform: rotate(180deg);` : ``}
`

export default function Button({
  id, label, icon, showIcon, className,
  onClick,
  ...props
}) {
  return <StyledButton
    id={id ? id : undefined}
    label={label ? label : undefined}
    className={className}
    {...props}
    onClick={onClick ? (event) => {
      event.stopPropagation()
      onClick(event)
    } : undefined}
  >
    {(icon && showIcon) ? <IconContainer label={label} {...props} ><Icon iconKey={icon}
      style={{
        height: (props.size === BUTTON_SIZE.NORMAL) ? props.theme.style.iconSize : props.theme.style.iconSizeSmall,
        width: (props.size === BUTTON_SIZE.NORMAL) ? props.theme.style.iconSize : props.theme.style.iconSizeSmall,
        fill: colorStyle(props),
        verticalAlign: "middle"
      }} />
    </IconContainer> : null}
    {(label !== "") && <TextContainer {...props}>{label}</TextContainer>}
  </StyledButton >
}

Button.propTypes = {
  id: PropTypes.string,
  /**
   * Component class name
   */
  className: PropTypes.string,
  /**
   * Component style
   */
  buttonStyle: PropTypes.oneOf(Object.values(BUTTON_STYLE)).isRequired,
  /**
   * Component size
   */
  size: PropTypes.oneOf(Object.values(BUTTON_SIZE)).isRequired,
  /**
   * Component orientation
   */
  orientation: PropTypes.oneOf(Object.values(BUTTON_ORIENTATION)).isRequired,
  /**
   * Component icon object
   */
  icon: PropTypes.oneOf(ICON_KEY),
  /**
   * Show/hide icon
   */
  showIcon: PropTypes.bool.isRequired,
  /**
   * Component label
   */
  label: PropTypes.string.isRequired,
  /**
   * Component full width
   */
  wide: PropTypes.bool,
  /**
   * Component brand theme
   */
  theme: PropTypes.object.isRequired,
  /**
   * Component click handle event
   */
  onClick: PropTypes.func,
}

Button.defaultProps = {
  id: "",
  className: undefined,
  buttonStyle: BUTTON_STYLE.PRIMARY,
  size: BUTTON_SIZE.NORMAL,
  orientation: BUTTON_ORIENTATION.HORIZONTAL,
  showIcon: false,
  icon: undefined,
  label: "",
  wide: false,
  theme: {...appTheme},
  onClick: undefined,
}
