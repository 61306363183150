import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import { appTheme } from "config/theme"

export const StyledText = styled.span`
  font-size: ${props => props.fontSize}px;
  line-height: ${props => props.lineHeight};
  transition: ${props => props.theme.style.transitionTime}s;
  ${props => props.align ? `text-align: ${props.align};` : ``}
  ${props => props.block ? `display: block; width:100%;` : ``}
  ${props => props.bold ? `font-weight: ${props.theme.fonts.boldWeight};` : ``}
  ${props => props.centered ? `margin:auto; display:table;` : ``}
  ${props => props.color ? `color: ${props.color};` : ``}
  ${props => props.fontFamily ? `font-family: ${props.fontFamily};` : ``}
  ${props => props.fontStyle ? `font-style: ${props.fontStyle};` : ``}
  ${props => props.italic ? `font-style: italic;` : ``}
  ${props => props.lineThrough ? `text-decoration:line-through;` : ``}
  ${props => props.margin ? `margin: ${String(props.margin).split(",").join("px ")}px;` : ``}
  ${props => props.nowrap ? `white-space: nowrap;` : ``}
  ${props => props.padding ? `padding: ${String(props.padding).split(",").join("px ")}px;` : ``}
  ${props => props.cursor ? `cursor: ${props.cursor};` : ``}
  ${props => props.letterSpacing ? `letter-spacing: ${props.letterSpacing};` : ``}
  ${props => props.vAlign ? `vertical-align: ${props.vAlign};` : ``}
  ${props => props.underline ? `text-decoration: underline;` : ``}
  ${props => props.uppercase ? `text-transform: uppercase;` : ``}
  ${props => props.weight ? `font-weight: ${props.weight};` : ``}
  ${props => props.width ? `width: ${props.width};` : ``}
  ${props => props.animated ? `
	animation: fadeinout 0.6s;
	@keyframes fadeinout {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
  ` : ``}
  ${props => props.disabled ? `opacity: 0.3;` : ``}
`

export const TEXT_STYLES = {
  TEXT_NORMAL: "TEXT_NORMAL",
  TEXT_SMALL: "TEXT_SMALL",
  TEXT_LARGE: "TEXT_LARGE",
  TEXT_FORM_LABEL: "TEXT_FORM_LABEL",
  TEXT_BUTTON: "TEXT_BUTTON",
  TEXT_TAG: "TEXT_TAG",
  TEXT_REGION: "TEXT_REGION",
  TITLE_TITLE: "TITLE_TITLE",
  TITLE_H2: "TITLE_H2",
  TITLE_EXERCISE: "TITLE_EXERCISE",
  TITLE_WELCOME: "TITLE_WELCOME",
  TITLE_PANEL: "TITLE_PANEL",
}

const getStyledText = (props, textStyle) => {
  const titleCommonStyles = {
    color: props.theme.colors.textPrimary,
    fontFamily: props.theme.fonts.secondary,
    fontSize: props.theme.fonts.jumbo,
    fontStyle: "normal",
    uppercase: true,
    weight: props.theme.fonts.boldWeight,
    letterSpacing: "-0.02em"
  }
  
  const textCommonStyles = {
    color: props.theme.colors.textPrimary,
    fontFamily: props.theme.fonts.primary,
    fontSize: props.theme.fonts.normal,
    fontStyle: "normal",
  }
  const text = props.label ? props.label : props.children;
  const ret = {
    [TEXT_STYLES.TEXT_NORMAL]:
      <StyledText {...textCommonStyles} {...props} fontSize={props.theme.fonts.normal} lineHeight={props.lineHeight || props.theme.fonts.normalLineHeight}>
          {text}
      </StyledText>,
    [TEXT_STYLES.TEXT_SMALL]:
      <StyledText {...textCommonStyles} {...props} fontSize={props.theme.fonts.small} lineHeight={props.lineHeight || props.theme.fonts.smallLineHeight}>
          {text}
      </StyledText>,
    [TEXT_STYLES.TEXT_LARGE]:
      <StyledText {...textCommonStyles} {...props} fontSize={props.theme.fonts.large} lineHeight={props.lineHeight || props.theme.fonts.largeLineHeight} >
          {text}
      </StyledText>,
    [TEXT_STYLES.TEXT_FORM_LABEL]:
      <StyledText {...textCommonStyles} {...props} fontSize={props.theme.fonts.normal} lineHeight={props.lineHeight || props.theme.fonts.normalLineHeight} weight={props.theme.fonts.nearBoldWeight} color={props.theme.colors.textSecondary}>
          {text}
      </StyledText>,
    [TEXT_STYLES.TEXT_BUTTON]:
      <StyledText {...textCommonStyles} {...props} fontSize={props.theme.fonts.small} lineHeight={props.lineHeight || props.theme.fonts.smallLineHeight} weight={props.theme.fonts.nearBoldWeight} letterSpacing={"0.1em"} uppercase={true}>
          {text}
      </StyledText>,
    [TEXT_STYLES.TEXT_TAG]:
      <StyledText {...textCommonStyles} {...props} fontSize={props.theme.fonts.tiny} lineHeight={props.lineHeight || props.theme.fonts.tinyLineHeight} weight={props.theme.fonts.nearBoldWeight} uppercase={true}>
          {text}
      </StyledText>,
    [TEXT_STYLES.TEXT_REGION]:
      <StyledText {...textCommonStyles} {...props} fontSize={props.theme.fonts.small} lineHeight={props.lineHeight || props.theme.fonts.smallLineHeight} weight={props.theme.fonts.nearBoldWeight} letterSpacing={"0.1em"} uppercase={true}>
          {text}
      </StyledText>,
    [TEXT_STYLES.TITLE_TITLE]:
      <StyledText {...titleCommonStyles} {...props} fontSize={props.theme.fonts.jumbo} lineHeight={props.lineHeight || props.theme.fonts.jumboLineHeight}>
          {text}
      </StyledText>,
    [TEXT_STYLES.TITLE_H2]:
      <StyledText {...titleCommonStyles} {...props} fontSize={props.theme.fonts.xxxlarge} lineHeight={props.lineHeight || props.theme.fonts.xxxlargeLineHeight}>
          {text}
      </StyledText>,
    [TEXT_STYLES.TITLE_EXERCISE]:
      <StyledText {...titleCommonStyles} {...props} fontSize={props.theme.fonts.xxlarge} lineHeight={props.lineHeight || props.theme.fonts.xxlargeLineHeight} weight={props.theme.fonts.nearBoldWeight}>
          {text}
      </StyledText>,
    [TEXT_STYLES.TITLE_WELCOME]:
      <StyledText {...titleCommonStyles} {...props} fontSize={props.theme.fonts.xlarge} lineHeight={props.lineHeight || props.theme.fonts.xlargeLineHeight}>
          {text}
      </StyledText>,
    [TEXT_STYLES.TITLE_PANEL]:
      <StyledText {...titleCommonStyles} {...props} fontSize={props.theme.fonts.small} lineHeight={props.lineHeight || props.theme.fonts.smallLineHeight} weight={props.theme.fonts.nearBoldWeight} letterSpacing={"0.1em"}>
          {text}
      </StyledText>,
  }
  return ret[textStyle]
}

export default function Text({
  textStyle,
  ...props
}) {
  return getStyledText(props, textStyle)
}

Text.propTypes = {
  /**
   * Text style
   */
  textStyle: PropTypes.oneOf(Object.values(TEXT_STYLES)).isRequired,
  /**
   * Text content
   */
  label: PropTypes.string,
  /**
   * Component brand theme
   */
  theme: PropTypes.object.isRequired,
}

Text.defaultProps = {
  textStyle: TEXT_STYLES.TEXT_NORMAL,
  label: "",
  theme: {...appTheme},
}
