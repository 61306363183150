const TagEventTypes = {
	// General events
	OMNICOACH_GENERAL_LOGIN: "OMNICOACH_GENERAL_LOGIN",
	OMNICOACH_GAME_RECORDER_DOWNLOAD: "OMNICOACH_GAME_RECORDER_DOWNLOAD",

	// Menu events
	OMNICOACH_MENU_ITEM_CLICK: "OMNICOACH_MENU_ITEM_CLICK",
	OMNICOACH_MENU_PRO_MODE_CLICK: "OMNICOACH_MENU_PRO_MODE_CLICK",
	OMNICOACH_MENU_GAME_SWITCH_CLICK: "OMNICOACH_MENU_GAME_SWITCH_CLICK",
	OMNICOACH_MENU_CHANGE_LANGUAGE: "OMNICOACH_MENU_CHANGE_LANGUAGE",
	
	// Site functionality related
	OMNICOACH_UPLOAD_UPLOAD_START: "OMNICOACH_UPLOAD_UPLOAD_START",
	OMNICOACH_UPLOAD_UPLOAD_ABORT: "OMNICOACH_UPLOAD_UPLOAD_ABORT",
	OMNICOACH_UPLOAD_UPLOAD_SUCCESS: "OMNICOACH_UPLOAD_UPLOAD_SUCCESS",
	OMNICOACH_UPLOAD_ERROR: "OMNICOACH_UPLOAD_ERROR",
	OMNICOACH_UPLOAD_SAME_VIDEO: "OMNICOACH_UPLOAD_SAME_VIDEO",
	OMNICOACH_UPLOAD_VIDEO_CHECK_FAIL: "OMNICOACH_UPLOAD_VIDEO_CHECK_FAIL",
	
	OMNICOACH_QUEST_QUEST_PREVIEW: "OMNICOACH_QUEST_QUEST_PREVIEW",
	OMNICOACH_QUEST_QUEST_DETAIL: "OMNICOACH_QUEST_QUEST_DETAIL",
	OMNICOACH_QUEST_QUEST_REWARD_CLICK: "OMNICOACH_QUEST_QUEST_REWARD_CLICK",
	OMNICOACH_QUEST_QUEST_COUPON_CLICK: "OMNICOACH_QUEST_QUEST_COUPON_CLICK",

	OMNICOACH_MATCHGALLERY_OPEN_MATCH: "OMNICOACH_MATCHGALLERY_OPEN_MATCH",
	OMNICOACH_MATCHGALLERY_ANALYZING_CLICK: "OMNICOACH_MATCHGALLERY_ANALYZING_CLICK",
	OMNICOACH_MATCHGALLERY_INVALID_CLICK: "OMNICOACH_MATCHGALLERY_INVALID_CLICK",

	OMNICOACH_MATCH_HIGHLIGHT_CREATE_CLICK: "OMNICOACH_MATCH_HIGHLIGHT_CREATE_CLICK",
	OMNICOACH_MATCH_HIGHLIGHT_DOWNLOAD_CLICK: "OMNICOACH_MATCH_HIGHLIGHT_DOWNLOAD_CLICK",
	OMNICOACH_MATCH_EVENT_SHARE_COPY_CLICK: "OMNICOACH_MATCH_EVENT_SHARE_COPY_CLICK",
	OMNICOACH_MATCH_EVENT_BANNER_CLICK: "OMNICOACH_MATCH_EVENT_BANNER_CLICK",
	OMNICOACH_MATCH_EVENT_NAVIGATION_CLICK: "OMNICOACH_MATCH_EVENT_NAVIGATION_CLICK",
	OMNICOACH_MATCH_VIDEO_PLAY: "OMNICOACH_MATCH_VIDEO_PLAY",

	OMNICOACH_MATCH_QUEST_REWARD_CLICK: "OMNICOACH_MATCH_REWARD_QUEST_CLICK",
	OMNICOACH_MATCH_QUEST_PREVIEW: "OMNICOACH_MATCH_QUEST_PREVIEW",
	OMNICOACH_MATCH_QUEST_COUPON_CLICK: "OMNICOACH_MATCH_QUEST_COUPON_CLICK",

	OMNICOACH_MATCH_STATISTICS_CLICK: "OMNICOACH_MATCH_STATISTICS_CLICK",
	OMNICOACH_MATCH_EVENT_TYPE_CLICK: "OMNICOACH_MATCH_EVENT_TYPE_CLICK",

	OMNICOACH_MATCH_CHANGE_VIEW: "OMNICOACH_MATCH_CHANGE_VIEW",
}

export default TagEventTypes;
