import { useContext } from 'react';
import update from 'immutability-helper';

import AppStore from 'containers/App/context'
import { UserProfile, PROFILE_TYPE } from 'components/Product/Pages/UserProfile/UserProfile.js'
import { Games, VIDEO_SHARE_TYPE, RESTRICTIONS } from 'config/enums';

export const getSettings = (params) => {
  let result = {
    values: (params.config.values) ? { ...params.config.values } : {},
    required: (params.config.required) ? [...params.config.required] : [],
    rows: []
  }
  if (params.config.source) {
    result.source = params.config.source
  }
  if (params.config.rows) {
    params.config.rows.forEach(crow => {
      let row = { ...crow }
      if (row.separator) {
        row.separator = params.getText(`upload_${row.separator}`, row.separator)
      }
      row.cells.forEach(cell => {
        cell.label = params.getText(`upload_${cell.id}`, cell.id)
        if (result.required.includes(cell.id)) {
          cell.placeholder = params.getText("upload_required")
        }
        result.values[cell.id] = (params.sources[cell.id]) ? params.sources[cell.id] : ""
        if (cell.input === "select") {
          cell.options = []
          if (params.sources[cell.source]) {
            cell.options = params.sources[cell.source].map(
              (option) => {
                return { value: String(option), label: String(option).toUpperCase() }
              }
            )
          }
        }
      });
      result.rows.push(row)
    });
  }
  return result
}

export const Profile = (props) => {
  const { data, actions } = useContext(AppStore);
  //const dialogRef = useRef(null);

  const userData = data.session.data.user
  const brandTheme = data.session.provider.brandTheme
  const userMeta = userData.meta || {}
  const userAddress = (userMeta.user_address)
    ? (typeof userMeta.user_address === "string")
      ? JSON.parse(userMeta.user_address)
      : userMeta.user_address
    : {}
  let profileTypes = [
    { id: PROFILE_TYPE.GENERAL, label: "General", disabled: false }
  ]

  let address = undefined
  if(!data.session.restrictions.includes(RESTRICTIONS.PROFILE_ADDRESS)){
    profileTypes.push(
      { id: PROFILE_TYPE.ADDRESS, label: "Address", disabled: false }
    )
    address = {
      name: userAddress.name || "",
      country: userAddress.country || "",
      city: userAddress.city || "",
      post_code: userAddress.post_code || "",
      address: userAddress.address || "",
    }
  }

  let settings = undefined
  if (data.upload.settings[data.session.provider.private.game]){
    if(!(data.session.provider.private.game === Games.VALORANT && data.session.restrictions.includes(RESTRICTIONS.RIOT_API))) {
      profileTypes.push(
        { id: PROFILE_TYPE.GAME, label: "Game Details", disabled: false }
      )
    }
    settings = getSettings({
      config: data.upload.settings[data.session.provider.private.game],
      getText: actions.getText,
      sources: {
        match_regions: data.session.data.enums.riot_api_region,
        summoner_name: (data.session.data.user.meta.user_lol_summoner_name)
          ? data.session.data.user.meta.user_lol_summoner_name : "",
        summoner_region: (data.session.data.user.meta.user_lol_server_region)
          ? data.session.data.user.meta.user_lol_server_region
          : (data.session.provider.default_riot_region
            ? data.session.provider.default_riot_region
            : data.upload.summoner_region),
        account_name: (data.session.data.user.meta.user_valorant_user_name)
        ? data.session.data.user.meta.user_valorant_user_name : "",
        account_tag: (data.session.data.user.meta.user_valorant_user_tag)
        ? data.session.data.user.meta.user_valorant_user_tag : "",
        match_region: data.upload.match_region,
        video_share_type: Object.values(VIDEO_SHARE_TYPE)
      }
    })
  }

  const labels = {
    title: "Settings",
    cancel: "Cancel",
    save: "Save",
    required_value: "Required value",
    address_country: "Country",
    address_city: "City",
    address_post_code: "Zip/Postal code",
    address_address: "Address (Street, House, etc)",
    address_name: "Recipient name",
    user_name: "User name",
    user_role: "Role",
    user_language: "Language",
    user_email: "Email address",
    user_reference_id: "User reference id",
  }

  const mapMetaField = (fieldname) => {
    const fieldMap = {
      name: "user_nickname",
      email: "user_email",
      language: "user_language",
      summoner_name: "user_lol_summoner_name",
      summoner_region: "user_lol_server_region",
      account_name: "user_valorant_user_name",
      account_tag: "user_valorant_user_tag",
      match_region: "user_valorant_server_region",
    }
    return fieldMap[fieldname] || fieldname
  }

  return (params) => {
    const { onChange, updateUser } = params
    const form = (_props) => {
      return <UserProfile { ..._props } />
    }
    let formProps = update({}, {$set: {
      theme: brandTheme,
      userData,
      profileTypes: profileTypes,
      selectedType: PROFILE_TYPE.GENERAL,
      languages: params.languages,
      data: {
        name: userMeta.user_nickname || "",
        role: userData.role,
        reference_id: userData.reference_id,
        language: params.language,
        email: userMeta.user_email || undefined,
        showRole: false
      },
      address,
      settings,
      labels,
      onCancel: async () => {
        //dialogRef.current.className += " zoomOut"
        //await new Promise(resolve => setTimeout(resolve, 750));
        onChange(null)
      }, 
      updateUser: () => {
        formProps = update(formProps, {userData: {$merge: {
          dirty: false
        }}})
        updateUser(formProps.userData, form(formProps))
      },
      changeMeta: (key, value) => {
        formProps = update(formProps, {userData: {meta: {$merge: {
          [key]: value
        }}}})
        formProps = update(formProps, {userData: {$merge: {
          dirty: true
        }}})
        onChange(form(formProps))
      },
      changeUserAddressMeta: (key, value) => {
        if(formProps.userData.meta.user_address === undefined) {
          formProps.userData.meta.user_address = {}
        }
        if(typeof formProps.userData.meta.user_address === 'string') {
          formProps.userData.meta.user_address = JSON.parse(formProps.userData.meta.user_address)
        }
        formProps = update(formProps, {userData: {meta: { 'user_address' : {$merge: {
          [key]: value
        }}}}})
        formProps = update(formProps, {userData: {$merge: {
          dirty: true
        }}})
        formProps.address[key] = value
        onChange(form(formProps))
      },
      onPageEvent: ({ key, fieldname, value }) => {
        switch (key) {
          case "cancel":
            formProps.onCancel()
            break;
          
          case "save":
            formProps.updateUser()
            break;

          case "profile_type":
            formProps.selectedType = value
            onChange(form(formProps))
            break;

          case "user":
            if(fieldname !== "role"){
              formProps.data[fieldname] = value
              formProps.changeMeta(mapMetaField(fieldname), value)
            }
            break;

          case "settings":
            formProps.settings.values[fieldname] = value
            formProps.changeMeta(mapMetaField(fieldname), value)
            break;
          
          case "address":
            formProps.changeUserAddressMeta(fieldname, value)
            break;
        
          default:
            break;
        }
      },
    }})
    onChange(form(formProps))
  }
}