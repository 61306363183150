import { useCallback } from 'react';
import PropTypes from 'prop-types';

import introJs from 'intro.js';

import "intro.js/introjs.css";
//import "intro.js/themes/introjs-dark.css";
//import "intro.js/themes/introjs-flattener.css";
//import "intro.js/themes/introjs-modern.css";
//import "intro.js/themes/introjs-nassim.css";
//import "intro.js/themes/introjs-nazanin.css";
//import "intro.js/themes/introjs-royal.css";

export const TOOLTIP_POSITION = {
  TOP: "top", 
  LEFT: "left", 
  RIGHT: "right", 
  BOTTOM: "bottom", 
  BOTTOM_LEFT_ALIGNED: "bottom-left-aligned", 
  BOTTOM_MIDDLE_ALIGNED: "bottom-middle-aligned", 
  BOTTOM_RIGHT_ALIGNED: "bottom-right-aligned",
  AUTO: "auto"
}

export const SCROLL_TARGET = {
  ELEMENT: "element",
  TOOLTIP: "tooltip"
}

const Intro = ({
  options, steps, filter, onExit, onStart
}) => {
  const intro = introJs()
  intro._showAgain = true
  intro.setDontShowAgain = (dontShowAgain) => {
    intro._showAgain = !dontShowAgain
    return this;
  }
  const fSteps = (filter.length > 0) ? steps.filter(step => (filter.includes(step.id))) : steps
  intro.onexit(() => {
    if(onExit){
      onExit(intro._showAgain);
    }
  });
  const introRef = useCallback(iRef => {
    if(iRef){
      if(fSteps.length > 0){
        intro.setOptions({ 
          ...options,
          dontShowAgain: true,
          dontShowAgainCookieDays: 0,
          steps: fSteps.map(step => ({
            element: step.element || null,
            title: step.title || null,
            intro: step.intro || "",
            position: step.position || null,
            tooltipClass: step.tooltipClass || "",
            highlightClass: step.highlightClass || "",
          })) 
        }).start()
        if(onStart){
          onStart(intro)
        }
      }
    }
  }, [fSteps, intro, options, onStart])

  return <div id="intro" ref={introRef} />

}

Intro.propTypes = {
  options: PropTypes.shape({
    /* Next button label */
    nextLabel: PropTypes.string,
    /* Next button label */
    prevLabel: PropTypes.string,
    /* Skip button label */
    skipLabel: PropTypes.string,
    /* Done button label */
    doneLabel: PropTypes.string,
    /* Hide the previous button in the first step? Otherwise, it will render a disabled button. */
    hidePrev: PropTypes.bool,
    /* Hide the next button in the last step? Otherwise, it will render a disabled button. 
      (Note: this will also hide the "Done" button) */
    hideNext: PropTypes.bool,
    /* Default tooltip position */
    tooltipPosition: PropTypes.oneOf(Object.values(TOOLTIP_POSITION)),
    /* Adding CSS class to all tooltips */
    tooltipClass: PropTypes.string,
    /* Additional CSS class for the helperLayer */
    highlightClass: PropTypes.string,
    /* Exit introduction when pressing Escape button, true or false */
    exitOnEsc : PropTypes.bool,
    /* Exit introduction when clicking on overlay layer, true or false */
    exitOnOverlayClick: PropTypes.bool,
    /* Show steps number in the red circle or not, true or false */
    showStepNumbers: PropTypes.bool,
    /* Navigating with keyboard or not, true or false */
    keyboardNavigation: PropTypes.bool,
    /* Show introduction navigation buttons or not, true or false */
    showButtons: PropTypes.bool,
    /* Show introduction bullets or not, true or false */
    showBullets: PropTypes.bool,
    /* Show introduction progress or not, true or false */
    showProgress: PropTypes.bool,
    /* Auto scroll to highlighted element if it’s outside of viewport, true or false */
    scrollToElement: PropTypes.bool,
    /* Target element to scroll to (element or tooltip). */
    scrollTo: PropTypes.oneOf(Object.values(SCROLL_TARGET)),
    /* Padding of scroll in px. Default is 30. Applies when scrollToElement is true */
    scrollPadding: PropTypes.number,
    /* Adjust the overlay opacity, Number between 0 and 1 */
    overlayOpacity: PropTypes.number,
    /* To disable interactions with elements inside the highlighted box, true or false */
    disableInteraction: PropTypes.bool,
    /* To display the "Don't show again" checkbox in the tour */
    dontShowAgainLabel: PropTypes.string,
  }).isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    /** Filter id */
    id: PropTypes.string,
    /* CSS selector to use for the step */
    element: PropTypes.string,
    /* The tooltip title (optional) */
    title: PropTypes.string,
    /* The tooltip content. */
    intro: PropTypes.string,
    /* Define the position of tooltip */
    position: PropTypes.oneOf(Object.values(TOOLTIP_POSITION)),
    /* Define a CSS class for the tooltip (optional) */
    tooltipClass: PropTypes.string,
    /* Append a CSS class to the helperLayer (optional) */
    highlightClass: PropTypes.string,
  })).isRequired,
  filter: PropTypes.arrayOf(PropTypes.string),
  onExit: PropTypes.func,
  onStart: PropTypes.func
}

Intro.defaultProps = {
  options: {
    nextLabel: "Next",
    prevLabel: "Previous",
    skipLabel: "x",
    doneLabel: "Done",
    hidePrev: true,
    hideNext: false,
    tooltipPosition: TOOLTIP_POSITION.BOTTOM,
    tooltipClass: "",
    highlightClass: "",
    exitOnEsc : true,
    exitOnOverlayClick: false,
    showStepNumbers: false,
    keyboardNavigation: true,
    showButtons: true,
    showBullets: true,
    showProgress: true,
    scrollToElement: true,
    scrollTo: SCROLL_TARGET.TOOLTIP,
    scrollPadding: 30,
    overlayOpacity: 0.5,
    disableInteraction: true,
    dontShowAgainLabel: "Don't show this again",
  },
  steps: [],
  filter: [],
  onExit: undefined,
  onStart: undefined
}

export default Intro;