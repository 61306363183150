import update from 'immutability-helper';
import { formatISO, subMonths } from 'date-fns'

import version from '../../package.json';
import * as venus_api from '../api/venus.json';

import { provider } from './provider'
import { defaultLocale } from 'locales'
import {
  current, login, video, upload, quest, quests, cards,
  rewards, exercises, users, gallery, community, menu,
  home, subscription, signup, sharing
} from './modul'

export const appUI = {
  toastTime: 15000,
  topMenuHeight: 64,
  paginationPage: 10,
  cardPagination: 10,
  dateFormat: "yyyy-MM-dd",
  timeFormat: "HH:mm",
  timeIntervals: 15,
  searchSubtract: 3,
  maxPageWidth: 1140,
  infoWidth: 350,
  paginationTop: false,
  paginatonScroll: false,
  tableFilter: true,
  tablePadding: "8px 8px",
  paginationVideoStat: 6,
  sideMenuWidth: 220,
  sideIconMenuWidth: 74
}

// Default read and write application context data
export const store = {
  tagManager: undefined,
  version: version,
  session: {
    restrictions: [],
    app: {
      venus_api,
      corePath: "/api/v1",
      frontendPath: "/api/frontend",
      shopPath: "/api/shop",
      proxy: process.env.REACT_APP_PROXY||"",
      providerPath: process.env.REACT_APP_PROVIDER||window.location.hostname,
      lang: defaultLocale,
      token: localStorage.getItem("omni_token") || null,
      sseEnabled: (typeof(process.env.REACT_APP_SSE) === "undefined") ? true : (process.env.REACT_APP_SSE==="true"),
      gameRecorder: {
        url: "https://www.overwolf.com/app/Omnicoach_Ltd.-Omnicoach_Game_Recorder",
        read: "https://omnicoach.gg/game-recorder-jp/",
        apex: "/config/brands/default/omnicoach_game_recorder_apex.png",
        lol: "/config/brands/default/omnicoach_game_recorder_lol.png",
        valorant: "/config/brands/default/omnicoach_game_recorder_valorant.png",
        csgo: "/config/brands/default/omnicoach_game_recorder_csgo.png",
        home: "/config/brands/default/home_game_recorder.png",
      },
      defaultMatchCardImage: {
        lol: "/config/brands/default/lol_cover.jpg",
        valorant: "/config/brands/default/valorant_cover.jpg",
        apex: "/config/brands/default/apex_cover.jpg",
        csgo: "/config/brands/default/csgo_cover.jpg",
      },
      debugFlag: false,
      console: {},
      videoOptions: {
        //https://videojs.com/guides/options/
        techOrder: ["html5", "youtube"],
        autoplay: false,
        controls: true,
        loop: true,
        muted: true,
        preload: "auto",
        responsive: true,
        fluid: true,
        liveui: true
      },
    },
    provider: provider,
  },
  current,
  login,
  menu,
  home,
  gallery,
  upload,
  video,
  quest,
  signup,
  quests: update(quests, {search: {$merge: {
    date_from: formatISO(subMonths(new Date(), appUI.searchSubtract), { representation: 'date' }) 
  }}}),
  community: update(community, {search: {$merge: {
    date_from: formatISO(subMonths(new Date(), appUI.searchSubtract), { representation: 'date' }) 
  }}}),
  cards,
  rewards,
  exercises,
  users,
  subscription,
  shop: {
    pricingData: {}
  },
  sharing
}

export const categories = {
  POSITIONING: "Position",
  AIMING: "Aiming",
  ABILITY_USAGE: "AbilityUsage",
  ULTIMATE_USAGE: "UltimateUsage",
  TEAMWORK: "TeamWork",
  WEAPON_KNOWLEDGE: "WeaponKnowledge",
  CHARACTER_SYNERGIES: "CharacterSynergies",
  CHARACTER_KNOWLEDGE: "CharacterKnowledge",
  MAP_KNOWLEDGE: "MapKnowledge",
  UNDERSTANDING_STATS: "UnderstandingStats",
  MECHANICAL_SKILLS: "MechanicalSkills",
  TARGET_FOCUS: "TargetFocus",
  GAME_SENSE: "GameSense",
  SURVIVABILITY: "Survive"
}

export const colorMapping = {
  GREEN: "#0a8d77",
  MAGENTA: "#c90dd3",
  NEON_BLUE: "#0accd3",
  ORANGE: "#a96814",
  PINK: "#c95fa9",
  YELLOW: "#c9cc14",
  GOLD: "#c9ae14",
  TAWNY: "#a94f14",
  RED: "#a00d22",
  PURPLE: "#6a0d74",
  AQUA: "#473cd3",
  LIME_GREEN: "#a3cc14",
  BLUE: "#278cbd"
}

export const designGlobals = {
  borderRadius: "4px",
  buttonTransitionScaleHover: "1.1",
  buttonTransitionScale: "1.2",
  transitionTime: "0.2s",
  fontSizeButton: "12px",
  iconSize: "12px",
  iconDistance: "8",
  letterSpacingButton: "0.1em",
  padding: "10px 30px",
  paddingSmall: "4px 8px",
  titleH1FontSize: "36px",
  titleH2FontSize: "32px",
  titleH3FontSize: "26px",
  titleH4FontSize: "18px",
  titleH5FontSize: "12px",
  textH1FontSize: "36px",
  textH2FontSize: "32px",
  textH3FontSize: "26px",
  textH4FontSize: "18px",
  textH5FontSize: "16px",
  textH6FontSize: "14px",
  textPFontSize: "12px",
  textMiniFontSize: "10px",
  tagBorderRadius: "2px",
}