import React from 'react';
import { withTheme } from 'styled-components'

import { Middle, Modal } from 'components/Controls';
import LoadingIndicator from 'components/LoadingIndicator';

export default withTheme(React.memo((props) => {
  return (
    <Modal>
      <Middle zIndex="20" background={props.theme.secondary} padding="10" round="4" opacity="0.8" >
        <LoadingIndicator />
      </Middle>
    </Modal>
    )
}));
