export const LoginProviders = {
	PASSWORD: "password",
	GOOGLE: "google",
	FACEBOOK: "facebook",
	TWITTER: "twitter",
	MICROSOFT: "microsoft",
}

export const Games = {
  LOL: "LOL",
  VALORANT: "VALORANT",
  APEX: "APEX",
  CSGO: "CSGO",
}

export const Languages = {
  en_us: "en_us",
  hu_hu: "hu_hu",
  jp_jp: "jp_jp",
}

export const UserMeta = {
  user_nickname: "user_nickname",
  user_email: "user_email",
  user_language: "user_language",
  user_subscription: "user_subscription",
}

export const GameResult = {
  VICTORY: "VICTORY",
  DEFEAT: "DEFEAT",
  DRAW: "DRAW",
  UNKNOWN: "UNKNOWN",
}

export const MatchStatus = {
  DONE : "done",
  ERROR : "error",
  ANALYZING : "analyzing",
  UPLOADING : "uploading",
}

export const UploadType = {
  YOUTUBE : "YouTube",
  FILE : "File",
}

export const VIDEO_SHARE_TYPE = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
  UNLISTED: "UNLISTED"
}

export const RESTRICTIONS = {
  ADMIN: "ADMIN", // SUPERUSER
  OWNER: "OWNER", // MATCH/VIDEO PAGE
  MENU: "MENU", // GUEST state
  ADMIN_REWARDS: "REWARDS", // menu
  ADMIN_QUESTS: "QUESTS", // menu
  ADMIN_USERS: "USERS", // menu
  ADMIN_EXERCISES: "EXERCISES", // menu
  ADMIN_COMMUNITY: "COMMUNITY", // menu
  HOME: "HOME", // menu
  PUBLIC_MATCH: "SHARING", // menu
  QUEST: "CARDS", // menu, MATCH/VIDEO PAGE
  UPLOAD: "UPLOAD", // menu
  UPLOAD_FILE: "UPLOAD_FILE", // Upload type
  UPLOAD_YOUTUBE: "UPLOAD_YOUTUBE", // Upload type
  MATCH_GALLERY: "GALLERY", // menu
  MATCH_EXERCISE: "MATCH_EXERCISE", // MATCH/VIDEO PAGE
  MATCH_STATISTICS: "MATCH_STATISTICS", // MATCH/VIDEO PAGE
  HIGHLIGHT: "HIGHLIGHT", // MATCH/VIDEO PAGE
  RECORDING_REQUIREMENTS: "RECORDING_REQUIREMENTS", // UPLOAD PAGE
  RECORDING_TOOL: "RECORDING_TOOL", // UPLOAD PAGE
  HOME_PAGE_SHARING: "HOME_PAGE_SHARING", // HOME PAGE
  HOME_PAGE_QUEST: "HOME_PAGE_QUEST", // HOME PAGE
  HOME_PAGE_ANALYSIS: "HOME_PAGE_ANALYSIS", // HOME PAGE
  HOME_PAGE_RECORDER: "HOME_PAGE_RECORDER", // HOME PAGE
  PROFILE_ADDRESS: "PROFILE_ADDRESS", // User profile section
  LOGIN_ALLOW_SIGNUP: "LOGIN_ALLOW_SIGNUP", // Login signup,
  RIOT_API: "RIOT_API", // UPLOAD/UserProfile Riot API settings
  USER_MENU: "USER_MENU", // personal user menu (TopMenu and SideMenu)
  ONBOARDING_PROMPTS: "ONBOARDING_PROMPTS" // intro guide
}