import PropTypes from 'prop-types';
import styled from 'styled-components'
import { Select as DropdownSelect } from "react-dropdown-select";

import { appTheme } from "config/theme"
import Icon, { ICON_KEY } from 'components/Product/Atoms/Icon/Icon'
import SearchIcon from './Search.svg';

export const DROP_SIZE = {
  NORMAL: "normal",
  SMALL: "small",
}

export const DROP_STYLE = {
  DEFAULT: "default",
  LANGUAGE: "language",
}

const dropStyle = (props) => {
  if (props.dropStyle === DROP_STYLE.DEFAULT) {
    const border = props.theme.style.border
    const borderColor = (props.error) ? props.theme.colors.borderColorError : props.theme.colors.borderColor
    return `${border} ${borderColor}`
  }
  return "none"
}

const getHeight = (props) => {
  if (props.height) {
    return props.height
  }
  if (props.size === DROP_SIZE.NORMAL) {
    return props.theme.style.inputHeight
  }
  return props.theme.style.inputHeightSmall
}

const DropSelect = styled(DropdownSelect)`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.fontSize ? props.fontSize : `${props.theme.fonts.small}px`};
  border-radius: ${props => props.theme.style.borderRadius.join("px ")}px !important;
  border: ${props => dropStyle(props)} !important;
  color: ${props => props.theme.colors.textPrimary};
  background-color: ${props => (props.dropStyle === DROP_STYLE.DEFAULT) ? props.theme.colors.midPanelBackground : `none`};
  padding: ${props => props.theme.style.paddingSmall.join("px ")}px !important;
  min-height: ${props => props.height ? props.height : props.theme.style.inputHeightSmall}px !important;
  height: ${props => getHeight(props)}px;
  width: ${props => (props.width) ? props.width : `100%`} !important;
  &svg {
    fill: ${props => props.theme.colors.textPrimary};
  }
  .react-dropdown-select-input::placeholder {
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.colors.placeholderColor};
    opacity: 1;
  };
  &:disabled {
    opacity: 0.5;
  };
  .react-dropdown-select-clear,
  .react-dropdown-select-dropdown-handle,
  .react-dropdown-select-input {
    color: ${props => props.theme.colors.textPrimary};
  }
  .react-dropdown-select-dropdown {
    top: ${props => (props.height)
    ? props.height
    : (props.size === DROP_SIZE.NORMAL) ? 34 : 22}px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 0;
    color: ${props => props.theme.colors.textPrimary};
    background-color: ${props => props.theme.colors.popupBackground};
    border: ${props => props.theme.style.border} ${props => props.theme.colors.borderColor};
    white-space: nowrap;
  }
  .react-dropdown-select-input {
    ${props => (props.minWidth) ? `width: ${props.minWidth};` : ``}
    font-size: ${props => props.fontSize ? props.fontSize : `${props.theme.fonts.small}px`};
    ${props => (props.searchable === false) ? `display: none;` : ``}
    ${props => !props.value ?
    `background-image: url(${SearchIcon});
     background-repeat: no-repeat;
     background-size: ${props.theme.style.iconSizeSmall}px;
     background-position: left center;
     background-origin: padding-box;
     padding-left: ${props.theme.style.paddingAllSmall[0] * 2}px !important;` : ``}
  }
  .item-selected {
    color: ${props => props.theme.colors.textHighlight};
    background-color: ${props => props.theme.colors.popupBackground};
    border: none;
  }
  .item-disabled {
    color: ${props => props.theme.colors.textPrimary};
    background-color: ${props => props.theme.colors.popupBackground};
    border: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const DropSelectElement = styled.div`
  display: table-row;
  padding: ${props => props.theme.style.paddingSmall.join("px ")}px !important;
  :hover {
    color: ${props => props.theme.colors.textHighlight};
  }
`
const IconContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: ${props => props.theme.style.iconSize + 16}px !important;
  height: ${props => (props.height)
    ? props.height : props.theme.style.inputHeightSmall}px;
  border: none !important;
  padding: 0 !important;
`
const TextContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  height: ${props => (props.height)
    ? props.height : props.theme.style.inputHeightSmall}px;
  border: none !important;
  padding: 0 !important;
`

const IconOption = styled.span`
  padding-right: 8px;
`

const TextOption = styled.span`
  vertical-align: middle;
`

export default function DropDown({
  id, value, error, readOnly, options,
  onChange,
  ...props
}) {
  const valueItem = options.filter(item => (item.value === value))
  return <DropSelect {...props} 
    additionalProps={{ id: id, property: value }} value={value} error={error}
    multi={true} closeOnSelect={true}
    labelField="label"
    valueField="value"
    sortBy={props.sortBy || "label"}
    backspaceDelete={!props.required}
    values={valueItem}
    options={options}
    onChange={(values) => {
      if (values[0]) {
        const newItem = values[values.length - 1]
        if (values.length > 1) {
          values.splice(0, values.length, (readOnly) ? valueItem[0] : newItem)
        }
        onChange(newItem.value)
      } else if(readOnly || props.required) {
        values.splice(0, 0, valueItem[0])
      } else {
        onChange("")
      }
    }}
    noDataRenderer={({ props, state, methods }) => (null)}
    optionRenderer={({ item, props, state, methods }) => {
      return <span>
        {(item.icon) ? <IconOption theme={props.theme}><Icon iconKey={item.icon} theme={props.theme}
          style={{
            height: props.theme.style.iconSize,
            width: props.theme.style.iconSize,
            fill: props.theme.colors.textPrimary,
            verticalAlign: "middle"
          }} /></IconOption> : null}
        <TextOption theme={props.theme}>{item.label}</TextOption>
      </span>
    }}
    itemRenderer={({ item, methods }) => (
      <DropSelectElement  {...props} role="option"
        className={
          `${methods.isSelected(item) ? `item-selected` : ``} ${item.disabled ? `item-disabled` : ``}`}
        onClick={() => item.disabled ? null : methods.addItem(item)}>
        {(item.icon) ?
          <IconContainer {...props}>
            <Icon iconKey={item.icon} theme={props.theme}
              style={{
                height: props.theme.style.iconSize,
                width: props.theme.style.iconSize,
                fill: props.theme.colors.textPrimary,
                verticalAlign: "middle"
              }} />
          </IconContainer> : null}
        <TextContainer  {...props}>
          <span id={item.value} aria-disabled={(item.disabled) ? "true" : ""} >{item.label}</span>
        </TextContainer>
      </DropSelectElement>
    )}
  />
}

DropDown.propTypes = {
  /**
   * Component ID
   */
  id: PropTypes.string,
  /**
   * Component size
   */
  size: PropTypes.oneOf(Object.values(DROP_SIZE)).isRequired,
  /**
   * Component style
   */
  dropStyle: PropTypes.oneOf(Object.values(DROP_STYLE)).isRequired,
  /**
   * Selected value
   */
  value: PropTypes.string,
  /**
   * Condition value list
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      icon: PropTypes.oneOf(ICON_KEY),
    })
  ).isRequired,
  readOnly: PropTypes.bool.isRequired,
  /**
   * Show/hide error border
   */
  error: PropTypes.bool.isRequired,
  /**
   * Component brand theme
   */
  theme: PropTypes.object.isRequired,
  /**
   * Value change event
   */
  onChange: PropTypes.func,
}

DropDown.defaultProps = {
  id: undefined,
  size: DROP_SIZE.NORMAL,
  dropStyle: DROP_STYLE.DEFAULT,
  value: undefined,
  options: [],
  readOnly: false,
  error: false,
  theme: {...appTheme},
  onChange: undefined,
}