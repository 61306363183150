import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button, { BUTTON_STYLE, BUTTON_SIZE } from 'components/Product/Atoms/Button/Button'
import DropDown, { DROP_STYLE } from 'components/Product/Atoms/DropDown/DropDown'
import Icon from 'components/Product/Atoms/Icon'
import TextAnchor from 'components/Product/Atoms/TextAnchor'

import { appTheme } from "config/theme"
import { Games, Languages, RESTRICTIONS } from 'config/enums'

const TopMenuContainer = styled.div`
  align-items: center;
  backdrop-filter: blur(15px);
  background-color: ${props => props.theme.colors.topMenuBackground};
  display: flex;
  height: ${props => props.theme.style.topMenuHeight}px;
  justify-content: space-between;
  padding: 0px 30px;
  border-bottom: 1px solid ${props => props.theme.colors.menuBorder};
`

const LeftSideContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 30px;
  width: fit-content;
`

/*
const ProModeContainer = styled.span`
  cursor: pointer;
  @media (max-width:665px){
    & span:first-child {
      display: none;
    }
  }
`
*/

const RightSideContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 30px;
  width: fit-content;
`
const IconContainer = styled.span`
  cursor: pointer;
  display: flex;
`

const SizedDropDown = styled(DropDown)`
  .react-dropdown-select-dropdown {
    width: ${props => props.optionWidth}px;
  }
`

const HideSmall = styled.span`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width:600px){
    display:none;
  }
`
const HideLarge = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  @media (min-width:601px){
    display:none;
  }
`

const Logo = styled.img`
  content: url(${props => props.logo});
  max-height: ${props => props.theme.style.topMenuHeight-24}px;
`

export default function TopMenu({
  id, labels, restrictions,
  games, selectedGame, avatarImageUrl, languages, selectedLanguage, showHelp, logo, isMobileMenuOpen,
  onGameChanged, onAvatarClicked, onLanguageChanged, onSignOutClicked, onHelpClicked, onMobileMenuClicked,
  showSubscription, onSubscriptionButtonClicked,
  ...props
}) {
  return <TopMenuContainer
    id={id}
    {...props}
  >
    <HideSmall>
      <LeftSideContainer>
        {games &&
          <SizedDropDown id={id + "_games"} theme={props.theme} required={true} searchable={false}
            options={games} value={selectedGame} onChange={onGameChanged} optionWidth={175} />
        }
        {/* switch off ProMode functionality
          showProMode &&
          <ProModeContainer onClick={onProModeClicked}>
            <Text theme={props.theme} textStyle={TEXT_STYLES.TEXT_NORMAL} margin={[0, 8, 0, 0]}>{labels.proMode}</Text>
            <Switch id={id + "_pro_mode"} value={proMode} theme={props.theme} />
          </ProModeContainer>
        */}
      </LeftSideContainer>
      {!restrictions.includes(RESTRICTIONS.USER_MENU) ? <RightSideContainer>
        {showSubscription &&
          <Button id={id + "_subscription"} theme={props.theme}
            buttonStyle={BUTTON_STYLE.SECONDARY} size={BUTTON_SIZE.SMALL}
            onClick={() => onSubscriptionButtonClicked()} label={labels.subscription}></Button>
        }
        {showHelp &&
          <IconContainer>
            <Icon style={{ height: 18, width: 18 }} fill={props.theme.colors.iconActiveColor} iconKey="QuestionCircle" id={id + "_help"} onClick={onHelpClicked} />
          </IconContainer>
        }
        <IconContainer>
          <Icon fill={props.theme.colors.iconActiveColor} iconKey="EmptyAvatar" id={id + "_profile"}
            onClick={onAvatarClicked} />
        </IconContainer>
        {languages &&
          <SizedDropDown id={id + "_language"} theme={props.theme} searchable={false}
            dropStyle={DROP_STYLE.LANGUAGE} options={languages} required={true}
            value={selectedLanguage} onChange={onLanguageChanged}
            width={"65px"} optionWidth={65} />
        }
        <IconContainer>
          <Icon fill={props.theme.colors.iconActiveColor} iconKey="SignOut" id={id + "_logout"}
            onClick={onSignOutClicked} />
        </IconContainer>
      </RightSideContainer> : null}
    </HideSmall>
    <HideLarge>
        <LeftSideContainer>
          <TextAnchor theme={props.theme} style={{lineHeight: 0}} href={window.location.origin} target="_self">
            <Logo logo={logo} alt="logo" theme={props.theme} />
          </TextAnchor>
        </LeftSideContainer>

        <RightSideContainer>
          <IconContainer>
            <Icon id={id + "_mobile"} style={{ height: 18, width: 18 }} fill={props.theme.colors.iconActiveColor}
              iconKey={isMobileMenuOpen ? "Times" : "Bars"} onClick={onMobileMenuClicked} />
          </IconContainer>
        </RightSideContainer>
    </HideLarge>
  </TopMenuContainer>
}

TopMenu.propTypes = {
  /**
   * Component ID
   */
  id: PropTypes.string,
  restrictions: PropTypes.array.isRequired,
  /**
   * Square aspect ratio logo (1:1) to display on mobile menu
   */
  logo: PropTypes.string,
  /**
   * Top menu labels
   */
  labels: PropTypes.shape({
    // proMode: PropTypes.string,
  }).isRequired,
  /**
   * Games to choose from
   */
  games: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOf(Object.values(Games)).isRequired,
    label: PropTypes.string.isRequired,
  })),
  /**
   * Currently selected game by default
   */
  selectedGame: PropTypes.string.isRequired,
  /**
   * Game change event
   */
  onGameChanged: PropTypes.func,
  /**
   * Pro mode show
   */
  // showProMode: PropTypes.bool.isRequired,
  /**
   * Pro mode switch state
   */
  // proMode: PropTypes.bool.isRequired,
  /**
   * Pro mode switch state
   */
  // onProModeClicked: PropTypes.func,
  /**
   * Avatar click handler
   */
  onAvatarClicked: PropTypes.func,
  /**
   * Languages to choose from
   */
  languages: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOf(Object.values(Languages)),
    label: PropTypes.string,
  })),
  /**
   * Component brand theme
   */
  theme: PropTypes.object.isRequired,
  /**
   * Selected language value
   */
  selectedLanguage: PropTypes.string.isRequired,
  /**
   * Language change handler
   */
  onLanguageChanged: PropTypes.func,
  /**
   * Show help icon?
   */
  showHelp: PropTypes.bool,
  /**
   * True when the mobile menu is open
   */
  isMobileMenuOpen: PropTypes.bool,
  /**
   * Help click handler
   */
  onHelpClicked: PropTypes.func,
  /**
   * Sign out click handler
   */
  onSignOutClicked: PropTypes.func,
  /**
   * Mobile menu click handler
   */
  onMobileMenuClicked: PropTypes.func,
  /**
   * Show subscription button?
   */
  showSubscription: PropTypes.bool,
  /**
   * Subscription menu click handler
   */
  onSubscriptionButtonClicked: PropTypes.func,
}

TopMenu.defaultProps = {
  id: undefined,
  restrictions: [],
  logo: undefined,
  labels: {
    pro_mode: "",
    subscription: ""
  },
  games: undefined,
  theme: {...appTheme},
  selectedGame: undefined,
  // showProMode: true,
  // proMode: false,
  avatarImageUrl: undefined,
  languages: undefined,
  selectedLanguage: undefined,
  showHelp: true,
  isMobileMenuOpen: false,
  onGameChanged: undefined,
  // onProModeClicked: undefined,
  onAvatarClicked: undefined,
  onLanguageChanged: undefined,
  onHelpClicked: undefined,
  onSignOutClicked: undefined,
  onMobileMenuClicked: undefined,
  showSubscription: false,
  onSubscriptionButtonClicked: undefined,
}
