export const capitalize = (str) => {
    return str.replace(/^\w/, (c) => c.toUpperCase());
}

export const capitalizeAll = (str) => {
    return str.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));;
}

export const validateEmail = (val) => {
	if (/^\w+([+.-]?\w+)*@\w+([+.-]?\w+)*(\.\w{2,3})+$/.test(val)) {
		return true
	}
	return false;
}