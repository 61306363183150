import React from 'react';
import styled from 'styled-components'

import Icon from 'components/Product/Atoms/Icon'

const Wrapper = styled.div`
  &:after {
    content:"";
    display:table;
    clear:both
  };
  &:before {
    content:"";
    display:table;
    clear:both
  };
` 

const MiddleCell = styled.div`
  display:table-cell;
  vertical-align:middle;
  padding: ${props => props.padding};
`

const Message = styled.span`
  font-weight: bold;
  font-size: 13px!important;
`

const MessageBox = styled.div`
`

const MessageHeader = styled.div`
  font-size: 11px!important;
  font-family: ${props => props.theme.fontFamily};
  background: ${props => props.theme.secondary};
  color: ${props => props.theme.text};
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: bold;
  padding: 8px;
`

const MessageBody = styled.div`
  font-size: 13px;
  max-height: 250px!important;
  overflow-y: auto;
  padding: 8px;
  text-align: justify;
  ul {
    margin: 8px 0px 8px;
    padding-left: 16px;
  }
  ol {
    margin: 8px 0px 8px;
    padding-left: 16px;
  }
  li {
    padding-left: 0px;
  }
`

export const Toast = (props) => {
  const getIcon = () => {
    if (props.icon === "exclamation"){
      return <Icon iconKey="ExclamationTriangle" 
        style={{ width: 24, height: 27, verticalAlign:"middle" }} />
    }
    if (props.icon === "success"){
      return <Icon iconKey="CheckCircle" 
        style={{ width: 24, height: 24, verticalAlign:"middle" }} />
    }
    if (props.icon === "info"){
      return <Icon iconKey="InfoCircle" 
        style={{ width: 24, height: 24, verticalAlign:"middle" }} />
    }
    return <Icon iconKey="InfoCircle" 
      style={{ width: 24, height: 24, verticalAlign:"middle" }} />
  }
  if(props.type === "message"){
    return (
      <MessageBox id={props.id} data-id={props.dataId}>
        {(props.title)?<MessageHeader {...props} >{props.title}</MessageHeader>:null}
        <MessageBody dangerouslySetInnerHTML={{ __html: props.message }} />
      </MessageBox>
    )
  }
  return(
    <Wrapper id={props.id} data-id={props.dataId}>
      <MiddleCell padding="2px 12px 2px 2px" >
        {getIcon()}
      </MiddleCell>
      <MiddleCell padding="2px" >
        <Message>{props.message}</Message>
      </MiddleCell>
    </Wrapper>)
};
