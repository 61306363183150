import { ReactComponent as AbilityUsage } from './static/AbilityUsage.svg';
import { ReactComponent as Aiming } from './static/Aiming.svg';
import { ReactComponent as AngleLeft } from './static/AngleLeft.svg';
import { ReactComponent as AngleRight } from './static/AngleRight.svg';
import { ReactComponent as Apex } from './static/Apex.svg';
import { ReactComponent as Csgo } from './static/Csgo.svg';
import { ReactComponent as ArrowDown } from './static/ArrowDown.svg';
import { ReactComponent as ArrowLeft } from './static/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from './static/ArrowRight.svg';
import { ReactComponent as Assist } from './static/Assist.svg';
import { ReactComponent as Bars } from './static/Bars.svg';
import { ReactComponent as BenefitIcon1 } from './static/BenefitIcon1.svg';
import { ReactComponent as BenefitIcon2 } from './static/BenefitIcon2.svg';
import { ReactComponent as BenefitIcon3 } from './static/BenefitIcon3.svg';
import { ReactComponent as Calendar } from './static/Calendar.svg';
import { ReactComponent as CharacterKnowledge } from './static/CharacterKnowledge.svg';
import { ReactComponent as CharacterSynergies } from './static/CharacterSynergies.svg';
import { ReactComponent as Check } from './static/Check.svg';
import { ReactComponent as CheckCircle } from './static/CheckCircle.svg';
import { ReactComponent as CheckSquare } from './static/CheckSquare.svg';
import { ReactComponent as Circle } from './static/Circle.svg';
import { ReactComponent as CircleFull } from './static/CircleFull.svg';
import { ReactComponent as CloudUpload } from './static/CloudUpload.svg';
import { ReactComponent as Copy } from './static/Copy.svg';
import { ReactComponent as Cut } from './static/Cut.svg';
import { ReactComponent as Document } from './static/Document.svg';
import { ReactComponent as DotCircle } from './static/DotCircle.svg';
import { ReactComponent as Down } from './static/Down.svg';
import { ReactComponent as Download } from './static/Download.svg';
import { ReactComponent as Edit } from './static/Edit.svg';
import { ReactComponent as ExclamationCircle } from './static/ExclamationCircle.svg';
import { ReactComponent as ExclamationTriangle } from './static/ExclamationTriangle.svg';
import { ReactComponent as Exercises } from './static/Exercises.svg';
import { ReactComponent as ExitDoor } from './static/ExitDoor.svg';
import { ReactComponent as ExpandIcon } from './static/ExpandIcon.svg';
import { ReactComponent as Eye } from './static/Eye.svg';
import { ReactComponent as Filter } from './static/Filter.svg';
import { ReactComponent as Filter2 } from './static/Filter2.svg';
import { ReactComponent as GamePad } from './static/GamePad.svg';
import { ReactComponent as GameSense } from './static/GameSense.svg';
import { ReactComponent as Graph } from './static/Graph.svg';
import { ReactComponent as Home } from './static/Home.svg';
import { ReactComponent as Info } from './static/Info.svg';
import { ReactComponent as InfoCircle } from './static/InfoCircle.svg';
import { ReactComponent as Joystick } from './static/Joystick.svg';
import { ReactComponent as Language } from './static/Language.svg';
import { ReactComponent as LeftArrow } from './static/LeftArrow.svg';
import { ReactComponent as LeftArrowDouble } from './static/LeftArrowDouble.svg';
import { ReactComponent as Lock } from './static/Lock.svg';
import { ReactComponent as LockOpen } from './static/LockOpen.svg';
import { ReactComponent as Lol } from './static/Lol.svg';
import { ReactComponent as MapKnowledge } from './static/MapKnowledge.svg';
import { ReactComponent as MechanicalSkills } from './static/MechanicalSkills.svg';
import { ReactComponent as Microsoft } from './static/Microsoft.svg';
import { ReactComponent as Phone } from './static/Phone.svg';
import { ReactComponent as Play } from './static/Play.svg';
import { ReactComponent as PlayedCharacter } from './static/PlayedCharacter.svg';
import { ReactComponent as Playtime } from './static/Playtime.svg';
import { ReactComponent as Plus } from './static/Plus.svg';
import { ReactComponent as PlusSquare } from './static/PlusSquare.svg';
import { ReactComponent as Position } from './static/Plus.svg';
import { ReactComponent as Profile } from './static/Profile.svg';
import { ReactComponent as Progress } from './static/Progress.svg';
import { ReactComponent as Quest } from './static/Quest.svg';
import { ReactComponent as QuestCompleted } from './static/QuestCompleted.svg';
import { ReactComponent as QuestLaurel } from './static/QuestLaurel.svg';
import { ReactComponent as QuestProgress } from './static/QuestProgress.svg';
import { ReactComponent as QuestScrollStabbed } from './static/QuestScrollStabbed.svg';
import { ReactComponent as QuestionCircle } from './static/QuestionCircle.svg';
import { ReactComponent as Quests } from './static/Quests.svg';
import { ReactComponent as Reset } from './static/Reset.svg';
import { ReactComponent as Reward } from './static/Reward.svg';
import { ReactComponent as Save } from './static/Save.svg';
import { ReactComponent as Search } from './static/Search.svg';
import { ReactComponent as SortNumericDown } from './static/SortNumericDown.svg';
import { ReactComponent as SortNumericUp } from './static/SortNumericUp.svg';
import { ReactComponent as Square } from './static/Square.svg';
import { ReactComponent as SquareEmpty } from './static/SquareEmpty.svg';
import { ReactComponent as Star } from './static/Star.svg';
import { ReactComponent as Survive } from './static/Survive.svg';
import { ReactComponent as TargetFocus } from './static/TargetFocus.svg';
import { ReactComponent as TeamWork } from './static/TeamWork.svg';
import { ReactComponent as Th } from './static/Th.svg';
import { ReactComponent as Times } from './static/Times.svg';
import { ReactComponent as ToggleOff } from './static/ToggleOff.svg';
import { ReactComponent as ToggleOn } from './static/ToggleOn.svg';
import { ReactComponent as Tournament } from './static/Tournament.svg';
import { ReactComponent as TreasureChest } from './static/TreasureChest.svg';
import { ReactComponent as Trigger } from './static/Trigger.svg';
import { ReactComponent as Twitter } from './static/Twitter.svg';
import { ReactComponent as UltimateUsage } from './static/UltimateUsage.svg';
import { ReactComponent as UnderstandingStats } from './static/UnderstandingStats.svg';
import { ReactComponent as Up } from './static/Up.svg';
import { ReactComponent as Upload } from './static/Upload.svg';
import { ReactComponent as User } from './static/User.svg';
import { ReactComponent as UserLock } from './static/UserLock.svg';
import { ReactComponent as Users } from './static/Users.svg';
import { ReactComponent as Valorant } from './static/Valorant.svg';
import { ReactComponent as WeaponKnowledge } from './static/WeaponKnowledge.svg';
import { ReactComponent as WindowMaximize } from './static/WindowMaximize.svg';
import { ReactComponent as WindowRestore } from './static/WindowRestore.svg';
import { ReactComponent as Windows } from './static/Windows.svg';
import { ReactComponent as HideIcon} from'./static/HideIcon.svg';
import { ReactComponent as VideoUpload} from'./static/VideoUpload.svg';
import { ReactComponent as GraphBars} from'./static/GraphBars.svg';
import { ReactComponent as AbilityIcon} from'./static/AbilityIcon.svg';
import { ReactComponent as AssistIcon} from'./static/AssistIcon.svg';
import { ReactComponent as BuildingIcon} from'./static/BuildingIcon.svg';
import { ReactComponent as DamageIcon} from'./static/DamageIcon.svg';
import { ReactComponent as DeathIcon} from'./static/DeathIcon.svg';
import { ReactComponent as HealthIcon} from'./static/HealthIcon.svg';
import { ReactComponent as KillIcon} from'./static/KillIcon.svg';
import { ReactComponent as MoneyIcon} from'./static/MoneyIcon.svg';
import { ReactComponent as MonsterIcon} from'./static/MonsterIcon.svg';
import { ReactComponent as OtherIcon} from'./static/OtherIcon.svg';
import { ReactComponent as ScoreIcon} from'./static/ScoreIcon.svg';
import { ReactComponent as TimeIcon} from'./static/TimeIcon.svg';
import { ReactComponent as WardIcon} from'./static/WardIcon.svg';
import { ReactComponent as Unlisted} from'./static/Unlisted.svg';
import { ReactComponent as facebook_colored} from'./static/facebook_colored.svg';
import { ReactComponent as google_colored} from'./static/google_colored.svg';
import { ReactComponent as microsoft_colored} from'./static/microsoft_colored.svg';
import { ReactComponent as twitter_colored} from'./static/twitter_colored.svg';
import { ReactComponent as JumpPrev } from './static/JumpPrev.svg'
import { ReactComponent as JumpNext } from './static/JumpNext.svg'
import { ReactComponent as TwitterShare } from './static/TwitterShare.svg';
import { ReactComponent as FacebookShare } from './static/FacebookShare.svg';
import { ReactComponent as EmailShare } from './static/EmailShare.svg';
import { ReactComponent as CopyShare } from './static/CopyShare.svg';
import { ReactComponent as Youtube } from "./static/Youtube.svg";

export const StaticIconMap = {
  GraphBars: GraphBars,
  VideoUpload: VideoUpload,
  HideIcon: HideIcon,
  AbilityUsage: AbilityUsage,
  Aiming: Aiming,
  AngleLeft: AngleLeft,
  AngleRight: AngleRight,
  Csgo: Csgo,
  Apex: Apex,
  ArrowDown: ArrowDown,
  ArrowLeft: ArrowLeft,
  ArrowRight: ArrowRight,
  Assist: Assist,
  Bars: Bars,
  BenefitIcon1: BenefitIcon1,
  BenefitIcon2: BenefitIcon2,
  BenefitIcon3: BenefitIcon3,
  Calendar: Calendar,
  CharacterKnowledge: CharacterKnowledge,
  CharacterSynergies: CharacterSynergies,
  Check: Check,
  CheckCircle: CheckCircle,
  CheckSquare: CheckSquare,
  Circle: Circle,
  CircleFull: CircleFull,
  CloudUpload: CloudUpload,
  Copy: Copy,
  Cut: Cut,
  Document: Document,
  DotCircle: DotCircle,
  Down: Down,
  Download: Download,
  Edit: Edit,
  ExclamationCircle: ExclamationCircle,
  ExclamationTriangle: ExclamationTriangle,
  Exercises: Exercises,
  ExitDoor: ExitDoor,
  ExpandIcon: ExpandIcon,
  Eye: Eye,
  Filter: Filter,
  Filter2: Filter2,
  GamePad: GamePad,
  GameSense: GameSense,
  Graph: Graph,
  Home: Home,
  Unlisted: Unlisted,
  Info: Info,
  InfoCircle: InfoCircle,
  Joystick: Joystick,
  Language: Language,
  LeftArrow: LeftArrow,
  LeftArrowDouble: LeftArrowDouble,
  Lock: Lock,
  LockOpen: LockOpen,
  Lol: Lol,
  MapKnowledge: MapKnowledge,
  MechanicalSkills: MechanicalSkills,
  Microsoft: Microsoft,
  Phone: Phone,
  Play: Play,
  PlayedCharacter: PlayedCharacter,
  Playtime: Playtime,
  Plus: Plus,
  PlusSquare: PlusSquare,
  Position: Position,
  Profile: Profile,
  Progress: Progress,
  Quest: Quest,
  QuestCompleted: QuestCompleted,
  QuestLaurel: QuestLaurel,
  QuestProgress: QuestProgress,
  QuestScrollStabbed: QuestScrollStabbed,
  QuestionCircle: QuestionCircle,
  Quests: Quests,
  Reset: Reset,
  Reward: Reward,
  Save: Save,
  Search: Search,
  SortNumericDown: SortNumericDown,
  SortNumericUp: SortNumericUp,
  Square: Square,
  SquareEmpty: SquareEmpty,
  Star: Star,
  Survive: Survive,
  TargetFocus: TargetFocus,
  TeamWork: TeamWork,
  Th: Th,
  Times: Times,
  ToggleOff: ToggleOff,
  ToggleOn: ToggleOn,
  Tournament: Tournament,
  TreasureChest: TreasureChest,
  Trigger: Trigger,
  Twitter: Twitter,
  UltimateUsage: UltimateUsage,
  UnderstandingStats: UnderstandingStats,
  Up: Up,
  Upload: Upload,
  User: User,
  UserLock: UserLock,
  Users: Users,
  Valorant: Valorant,
  WeaponKnowledge: WeaponKnowledge,
  WindowMaximize: WindowMaximize,
  WindowRestore: WindowRestore,
  Windows: Windows,
  KILL: KillIcon,
  DEATH: DeathIcon,
  ASSIST: AssistIcon,
  DAMAGE: DamageIcon,
  HEALTH: HealthIcon,
  MONEY: MoneyIcon,
  MONSTER: MonsterIcon,
  WARD: WardIcon,
  BUILDING: BuildingIcon,
  ABILITY: AbilityIcon,
  TIME: TimeIcon,
  SCORE: ScoreIcon,
  OTHER: OtherIcon,
  facebookColored: facebook_colored,
  googleColored: google_colored,
  microsoftColored: microsoft_colored,
  twitterColored: twitter_colored,
  JumpPrev: JumpPrev,
  JumpNext: JumpNext,
  EmailShare: EmailShare,
  FacebookShare: FacebookShare,
  TwitterShare: TwitterShare,
  CopyShare: CopyShare,
  Youtube: Youtube,
  VALORANT: Valorant,
  LOL: Lol,
  APEX: Apex,
  CSGO: Csgo
}

export const DynamicIconMap = {
  agreement: import('./dynamic/agreement.svg'),
  arena: import('./dynamic/arena.svg'),
  basketball_game: import('./dynamic/basketball_game.svg'),
  battle1: import('./dynamic/battle1.svg'),
  battle2: import('./dynamic/battle2.svg'),
  battle3: import('./dynamic/battle3.svg'),
  betting: import('./dynamic/betting.svg'),
  buttons: import('./dynamic/buttons.svg'),
  caster: import('./dynamic/caster.svg'),
  championship_award: import('./dynamic/championship_award.svg'),
  chat: import('./dynamic/chat.svg'),
  coin: import('./dynamic/coin.svg'),
  competition1: import('./dynamic/competition1.svg'),
  competition2: import('./dynamic/competition2.svg'),
  competition4: import('./dynamic/competition4.svg'),
  competition5: import('./dynamic/competition5.svg'),
  computer: import('./dynamic/computer.svg'),
  computer_game1: import('./dynamic/computer_game1.svg'),
  computer_game2: import('./dynamic/computer_game2.svg'),
  computer_mouse: import('./dynamic/computer_mouse.svg'),
  contract: import('./dynamic/contract.svg'),
  crossing_swords: import('./dynamic/crossing_swords.svg'),
  famous: import('./dynamic/famous.svg'),
  fighting: import('./dynamic/fighting.svg'),
  fighting_game: import('./dynamic/fighting_game.svg'),
  first_prize2: import('./dynamic/first_prize.svg'),
  football_game1: import('./dynamic/football_game1.svg'),
  football_game2: import('./dynamic/football_game2.svg'),
  frames_per_second: import('./dynamic/frames_per_second.svg'),
  free2play: import('./dynamic/free2play.svg'),
  game_console1: import('./dynamic/game_console1.svg'),
  game_console2: import('./dynamic/game_console2.svg'),
  game_console3: import('./dynamic/game_console3.svg'),
  game_console4: import('./dynamic/game_console4.svg'),
  game_controller: import('./dynamic/game_controller.svg'),
  game_map: import('./dynamic/game_map.svg'),
  game_over: import('./dynamic/game_over.svg'),
  gamepad1: import('./dynamic/gamepad1.svg'),
  gamepad2: import('./dynamic/gamepad2.svg'),
  gamepad3: import('./dynamic/gamepad3.svg'),
  gamer1: import('./dynamic/gamer1.svg'),
  gamer2: import('./dynamic/gamer2.svg'),
  gaming: import('./dynamic/gaming.svg'),
  gaming_chair: import('./dynamic/gaming_chair.svg'),
  google: import('./dynamic/google.svg'),
  guitar: import('./dynamic/guitar.svg'),
  headphones: import('./dynamic/headphones.svg'),
  joystick: import('./dynamic/joystick.svg'),
  keyboard: import('./dynamic/keyboard.svg'),
  launch: import('./dynamic/launch.svg'),
  live_streaming: import('./dynamic/live_streaming.svg'),
  lives: import('./dynamic/lives.svg'),
  match: import('./dynamic/match.svg'),
  mobile_game1: import('./dynamic/mobile_game1.svg'),
  mobile_game2: import('./dynamic/mobile_game2.svg'),
  multiplayer: import('./dynamic/multiplayer.svg'),
  online_gaming1: import('./dynamic/online_gaming1.svg'),
  online_gaming2: import('./dynamic/online_gaming2.svg'),
  pvp: import('./dynamic/pvp.svg'),
  quiz: import('./dynamic/quiz.svg'),
  racing: import('./dynamic/racing.svg'),
  rpg_game1: import('./dynamic/rpg_game1.svg'),
  rpg_game2: import('./dynamic/rpg_game2.svg'),
  shooting_game: import('./dynamic/shooting_game.svg'),
  star: import('./dynamic/star.svg'),
  strategy_game1: import('./dynamic/strategy_game1.svg'),
  strategy_game2: import('./dynamic/strategy_game2.svg'),
  supporter: import('./dynamic/supporter.svg'),
  targeting: import('./dynamic/targeting.svg'),
  team: import('./dynamic/team.svg'),
  tournament1: import('./dynamic/tournament1.svg'),
  tournament2: import('./dynamic/tournament2.svg'),
  tournament3: import('./dynamic/tournament3.svg'),
  trophy1: import('./dynamic/trophy1.svg'),
  trophy2: import('./dynamic/trophy2.svg'),
  trophy3: import('./dynamic/trophy3.svg'),
  virtual_reality: import('./dynamic/virtual_reality.svg'),
  weapon: import('./dynamic/weapon.svg'),
  winner: import('./dynamic/winner.svg'),
}