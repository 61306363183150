import React from 'react';
import styled, { keyframes } from 'styled-components'

const Wrapper = styled.div`
  margin: 0 auto;
  position: relative;
  width: 64px;
  height: 64px;
`

const ldsRoller = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Indicator = styled.div`
  animation: ${ldsRoller} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
  animation-delay: ${props => props.delay};
  &:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: ${props => props.theme.primary || "#fff"};
    margin: -3px 0 0 -3px;
    top: ${props => props.top};
    left: ${props => props.left};
  }`

const values = [
  { delay:"-0.036s", top:"50px", left:"50px" },
  { delay:"-0.072s", top:"54px", left:"45px" },
  { delay:"-0.108s", top:"57px", left:"39px" },
  { delay:"-0.144s", top:"58px", left:"32px" },
  { delay:"-0.18s", top:"57px", left:"25px" },
  { delay:"-0.216s", top:"54px", left:"19px" },
  { delay:"-0.252s", top:"50px", left:"14px" },
  { delay:"-0.288s", top:"45px", left:"10px" }
]

export const LoadingIndicator = (params) => (
  <Wrapper >
    {values.map((element, index) => 
      <Indicator key={index} delay={element.delay} top={element.top} left={element.left} id={params.id ? params.id : "loading_indicator"}/>)}
  </Wrapper>
);
