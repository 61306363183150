import { createElement } from 'react';
import PropTypes from 'prop-types';

import { DynamicIconMap, StaticIconMap } from './IconMap'
import * as CustomIcon from './CustomIcon'
import { useDynamicSVGImport } from './DynamicImport'

export const QUEST_ICON = Object.keys(DynamicIconMap)

export const ICON_KEY = Object.keys(StaticIconMap).concat(
  Object.keys(DynamicIconMap)).concat(Object.keys(CustomIcon)).sort()

export default function Icon({
  iconKey,
  ...props
}){
  const { loading, SvgIcon } = useDynamicSVGImport(iconKey);

  if(StaticIconMap[iconKey]){
    return createElement(StaticIconMap[iconKey], props)
  }

  if(CustomIcon[iconKey]){
    return createElement(CustomIcon[iconKey], props)
  }

  if (loading) {
    return null;
  }

  if (SvgIcon) {
    return <SvgIcon {...props} />;
  }
  return null;
}

Icon.propTypes = {
  iconKey: PropTypes.oneOf(ICON_KEY).isRequired,
}

Icon.defaultProps = {
  iconKey: "ExclamationTriangle"
}