import { useState, useEffect, useRef } from 'react';
import { DynamicIconMap } from './IconMap'

export const useDynamicSVGImport = (name) => {
  const ImportedIconRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if(!DynamicIconMap[name]){
      return { error: null, loading: false, SvgIcon: null }
    }
    setLoading(true);
    const importIcon = async () => {
      try {
        ImportedIconRef.current = (
          await DynamicIconMap[name]
        ).ReactComponent;
      } catch (err) {
        /* istanbul ignore next */
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [name]);

  return { error, loading, SvgIcon: ImportedIconRef.current };
}