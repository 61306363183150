import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'

import Button from 'components/Product/Atoms/Button'

import { appTheme } from "config/theme"

export const SlideType = PropTypes.shape({
  lang: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  button: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  })
})

const Row = styled.div`
  display: table;
  ${props => props.sectionSmall ? `padding-top: ${props.theme.style.paddingSmall[1]}px!important; padding-bottom: ${props.theme.style.paddingSmall[1]}px!important;` : ``}
  ${props => props.sectionSmallTop ? `padding-top: ${props.theme.style.paddingSmall[1]}px!important;` : ``}
  &:before {
    content: "";
    display: table;
    clear: both;
  };
  &:after {
    content: "";
    display: table;
    clear: both;
  };
`

const Cell = styled.div`
  display: table-cell;
  vertical-align: middle;
`

const StyledContainer = styled.div`
  background-color: ${props => props.theme.colors.borderColor};
  border-radius: ${props => props.theme.style.borderRadius[0]}px;
  padding: ${props => props.theme.style.paddingNormal[1]}px;
  display: block;
  margin: 0 10px;
`;

const Text = styled.span`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fonts.normal}px;
`

const TitleText = styled.span`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${props => props.theme.fonts.large}px;
  font-weight: ${props => props.theme.fonts.boldWeight};
`

export default function NotificationSlide({
  id, slide, language,
  ...props
}) {
  const renderSingleSlide = (slide) => {
    return <>
      {slide.title ?
      <Row theme={props.theme}>
        <Cell>
          <TitleText id={`${id}_title`} bold theme={props.theme} >{slide.title}</TitleText>
        </Cell>
      </Row> : null}
      <Row sectionSmall={slide.title ? true : false} theme={props.theme}>
        <Cell>
          <Text id={`${id}_text`} theme={props.theme}>{slide.text}</Text>
        </Cell>
      </Row>
      {slide.button ?
        <Row sectionSmallTop theme={props.theme}>
          <Cell>
            <a href={slide.button.url} target="_blank" style={{textDecoration: "none"}} rel="noreferrer">
              <Button id={`${id}_button`} label={slide.button.text} {...props}  />
            </a>
          </Cell>
        </Row> : null}
    </>
  }
  const currentSlider = slide.filter((slideConfig => slideConfig.lang === language))[0]
  return <StyledContainer
    id={id}
    {...props}
  >
    {renderSingleSlide(currentSlider)}
  </StyledContainer>
}

NotificationSlide.propTypes = {
  /**
   * Component ID
   */
  id: PropTypes.string,
  /**
   * Language code needed to display the proper slider
   */
  language: PropTypes.string,
  /**
   * Component brand theme
   */
  theme: PropTypes.object.isRequired,
  /**
   * Slider definitions
   */
  slide: PropTypes.arrayOf(SlideType),
}

NotificationSlide.defaultProps = {
  id: undefined,
  language: undefined,
  theme: {...appTheme},
  slide: undefined,
}
