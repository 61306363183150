import styled from 'styled-components'
import {WELCOME_STEP_ALIGNMENT} from "./WelcomeStep";

export const WelcomeStepContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.align === WELCOME_STEP_ALIGNMENT.LEFT ? "row" : "row-reverse"};
  justify-content: space-between;
  position: relative;
  max-width: 1000px;
  min-height: 314px;
  height: auto;
  align-items: center;
  @media (max-width:600px){
    display: table; 
    width: 100%;
  }
  transition: 0.6s;

	animation: fadeinout 0.8s;
	@keyframes fadeinout {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`

export const WelcomeStepPosition = styled.div`
  position: absolute;
  height: 115px;
  ${props => props.align === WELCOME_STEP_ALIGNMENT.LEFT ? "left: 0px;" : "left: 50%;"}
  top: 0px;
  color: rgba(139, 148, 186, 0.2);
  text-transform: uppercase;
  font-family: 'Supria Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 96px;
  line-height: 120%;
  @media (max-width:600px){
    position: relative;
    display:block; 
    width: 100%;
    left: 0;
  }
`

export const WelcomeStepHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  @media (max-width:600px){
    display:block; 
    width: 100%;
  }
`

export const WelcomeStepDescription = styled.div`
  padding: 5px 0 30px 0;
`

export const WelcomeStepImageContainer = styled.div`
  width: 485px;
  height: 314px;
  background: url(${props => props.image});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width:600px){
    display: block; 
    width: 100%;
  }
`