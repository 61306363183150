import styled, { keyframes } from 'styled-components'
import DropDown from 'components/Product/Atoms/DropDown/DropDown'

export const Modal = styled.div`
  z-index: 50;
  position: fixed;
  left: 0;
  top: 0;
  width:100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.6);
  padding: 1px 0;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`

export const Middle = styled.div`
  @media only screen and (min-width: 601px){
    margin: 0px;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    -ms-transform:translate(-50%,-50%)
  }
`

export const Dialog = styled.div`
  border-radius: 4px;
  border: 2px solid ${props => props.theme.colors.borderColor};
  box-shadow:0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19);
  animation: 0.8s ${keyframes`from{transform:scale(0);} to{transform:scale(1);}`} ease-out;
  margin: 0 auto;
  width: 600px;
  min-height: 150px;
  @media (max-width:600px){
    width: 99%;
  };
  background-color: ${props => props.theme.colors.midPanelBackground}; 
  padding: 0px;
`

export const DialogContent = styled.div`
  background: ${props => props.theme.colors.darkestPanelBackground};
`

export const Row = styled.div`
  display: table;
  ${props => (props.centered) ? `margin: 0px auto;` : ``}
  width: ${props => props.width};
  ${props => (props.height) ? `height: ${props.height};` : ``}
  ${props => (props.padding) ? `padding: ${props.padding};` : ``}
  ${props => (props.background) ? `background: ${props.background};` : ``}
  ${props => (props.radius) ? `border-radius: ${props.radius};` : ``}
`

export const Cell = styled.div`
  display: table-cell;
  line-height: 12px;
  vertical-align: middle;
  ${props => (props.align) ? `text-align: ${props.align};` : ``}
  ${props => (props.width) ? `width: ${props.width};` : ``}
  ${props => (props.padding) ? `padding: ${props.padding};` : ``}
  @media (max-width:600px){
    ${props => (!props.grid) ? `display:block; width: 100%;` : ``}
  }
`

export const SizedDropDown = styled(DropDown)`
  .react-dropdown-select-dropdown {
    width: ${props => props.optionWidth}px;
  }
`

export const InputContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
  
  & svg {
    position: absolute;
    right: 8px;
  }
`