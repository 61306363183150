import React from 'react'
import PropTypes from 'prop-types'
import { WelcomeStepContainer, WelcomeStepHeaderContainer, WelcomeStepPosition, WelcomeStepDescription, WelcomeStepImageContainer } from "./WelcomeStep.styled";
import Text, { TEXT_STYLES } from "components/Product/Atoms/Text/Text";
import Button, { BUTTON_SIZE } from "components/Product/Atoms/Button/Button";

import { appTheme } from "config/theme"

export const WELCOME_STEP_ALIGNMENT = {
  LEFT: "left",
  RIGHT: "right"
}

export default function WelcomeStep({ align, labels, button_style, image, onClick, ...props
}) {
  return <WelcomeStepContainer {...props} align={align}>
    <WelcomeStepPosition align={align}>{labels.steps}</WelcomeStepPosition>
    <WelcomeStepHeaderContainer>
      <Text theme={props.theme} textStyle={TEXT_STYLES.TITLE_H2}>{labels.title}</Text>
      <WelcomeStepDescription>
        <Text 
          theme={props.theme}
          textStyle={TEXT_STYLES.TEXT_NORMAL}
          color={props.theme.colors.textSecondary}
        >{labels.description}</Text>
      </WelcomeStepDescription>
      <Button
        id="video_recorder_download"
        theme={props.theme}
        label={labels.button_title}
        buttonStyle={button_style}
        size={BUTTON_SIZE.NORMAL}
        style={{ zIndex: 1 }}
        onClick={onClick}
      />
    </WelcomeStepHeaderContainer>
    <WelcomeStepImageContainer image={image}></WelcomeStepImageContainer>
  </WelcomeStepContainer>
}

WelcomeStep.propTypes = {
  /**
   * Component alignment
   */
  align: PropTypes.oneOf(Object.values(WELCOME_STEP_ALIGNMENT)),
  /**
   * Component labels
   */
  labels: PropTypes.shape({
    steps: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    button_title: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Component button style
   */
  button_style: PropTypes.string,
  /**
   * Component image
   */
  image: PropTypes.string,
  /**
   * Click event on Component
   */
  onClick: PropTypes.func,
  /**
   * Component brand theme
   */
  theme: PropTypes.object.isRequired,
}

WelcomeStep.defaultProps = {
  align: undefined,
  labels: {
    steps: "",
    title: "",
    description: "",
    button_title: "",
  },
  button_style: undefined,
  image: undefined,
  onClick: undefined,
  theme: {...appTheme},
}