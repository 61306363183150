import React from 'react';
import styled from 'styled-components'
import PropTypes from 'prop-types';
import Text from 'components/Product/Atoms/Text';
import { appTheme } from "config/theme"

const StyledAnchor = styled.a`
  text-decoration: none;
  &.active span {
    color: ${props => props.activeColor ? props.activeColor : props.theme.colors.textPrimary};
  }
  ${props => props.interactable ? `
  cursor:pointer;
  :hover span {
    color: `+props.theme.colors.brandColorLinkHover+`;
  }
  ` : ``}
  
`

export default function TextAnchor({
  href, onClick, active,
  ...props
}) {
  const interactable = href !== undefined || onClick !== undefined;
  return <StyledAnchor {...props}
    className={active ? "active" : undefined}
    interactable={interactable} href={href} onClick={onClick}
    >
      <Text {...props} id={undefined} />
    </StyledAnchor>
}

TextAnchor.propTypes = {
  ...Text.propTypes,
  /**
   * Component brand theme
   */
  theme: PropTypes.object.isRequired,
  active: PropTypes.bool,
}

TextAnchor.defaultProps = {
  ...Text.defaultProps,
  theme: {...appTheme},
  active: false,
}
