import PropTypes from 'prop-types'

import { Modal, Middle, Dialog, DialogContent, Row, Cell, SizedDropDown, InputContainer } from './UserProfile.styled'
import Icon from 'components/Product/Atoms/Icon/Icon'
import Text, { TEXT_STYLES } from 'components/Product/Atoms/Text/Text'
import TextInput from 'components/Product/Atoms/TextInput/TextInput'
import HorizontalTabbedMenu from 'components/Product/Atoms/HorizontalTabbedMenu'
import Button, { BUTTON_STYLE } from 'components/Product/Atoms/Button/Button'

import { appTheme } from "config/theme"

export const PROFILE_TYPE = {
  GENERAL: "general",
  ADDRESS: "address",
  GAME: "game"
}

export const USER_ROLE = {
  USER: "USER",
  SUPERUSER: "SUPERUSER",
  ADMIN: "ADMIN"
}

const inputItem = ({
  inputType, id, value, required,
  event, required_label, options, theme,
  onPageEvent,
  ...props
}) => {
  if(inputType === "select"){
    return <SizedDropDown 
      id={id} theme={theme}
      value={value}
      options={options}
      optionWidth={105} required={required} searchable={false}
      placeholder={(required) ? required_label : ""}
      error={(required && (value === ""))}
      onChange={
        /* istanbul ignore next */
        (value) => onPageEvent({ ...event, value })
      }
    />
  }
  return <TextInput 
    id={id} theme={theme}
    value={value}
    placeholder={(required) ? required_label : ""}
    error={(required && (value === ""))}
    onChange={(value) => onPageEvent({ ...event, value })}
    {...props} />
}

const addressData = ({
  address, labels, theme,
  onPageEvent
}) => {
  return <Row centered width="100%" padding="16px 0">
    <Row centered width="95%" padding="8px 0">
      <Cell>
        <Row padding="4px 0 0" width="95%" centered>
          <Text theme={theme} textStyle={TEXT_STYLES.TEXT_NORMAL} >{labels.address_country}</Text>
        </Row>
        <Row padding="4px 0 0" width="95%" centered>
          {inputItem({
            theme,
            inputType: "text", id: "address_country", 
            value: address.country, required: true,
            event: { key: "address", fieldname: "country" },
            required_label: labels.required_value, options: [],
            onPageEvent
          })}
        </Row>
      </Cell>
      <Cell>
        <Row padding="4px 0 0" width="95%" centered>
          <Text theme={theme} textStyle={TEXT_STYLES.TEXT_NORMAL} >{labels.address_city}</Text>
        </Row>
        <Row padding="4px 0 0" width="95%" centered>
          {inputItem({
            theme,
            inputType: "text", id: "address_city", 
            value: address.city, required: true,
            event: { key: "address", fieldname: "city" },
            required_label: labels.required_value, options: [],
            onPageEvent
          })}
        </Row>
      </Cell>
      <Cell>
        <Row padding="4px 0 0" width="95%" centered>
          <Text theme={theme} textStyle={TEXT_STYLES.TEXT_NORMAL} >{labels.address_city}</Text>
        </Row>
        <Row padding="4px 0 0" width="95%" centered>
          {inputItem({
            theme,
            inputType: "text", id: "address_post_code", 
            value: address.post_code, required: true,
            event: { key: "address", fieldname: "post_code" },
            required_label: labels.required_value, options: [],
            onPageEvent
          })}
        </Row>
      </Cell>
    </Row>
    <Row centered width="95%" padding="8px 0">
      <Cell>
        <Row padding="4px 0 0" width="95%" centered>
          <Text theme={theme} textStyle={TEXT_STYLES.TEXT_NORMAL} >{labels.address_address}</Text>
        </Row>
        <Row padding="4px 0 0" width="95%" centered>
          {inputItem({
            theme,
            inputType: "text", id: "address_address", 
            value: address.address, required: true,
            event: { key: "address", fieldname: "address" },
            required_label: labels.required_value, options: [],
            onPageEvent
          })}
        </Row>
      </Cell>
      <Cell>
        <Row padding="4px 0 0" width="95%" centered>
          <Text theme={theme} textStyle={TEXT_STYLES.TEXT_NORMAL} >{labels.address_name}</Text>
        </Row>
        <Row padding="4px 0 0" width="95%" centered>
          {inputItem({
            theme,
            inputType: "text", id: "address_name", 
            value: address.name, required: true,
            event: { key: "address", fieldname: "name" },
            required_label: labels.required_value, options: [],
            onPageEvent
          })}
        </Row>
      </Cell>
    </Row>
  </Row>
}

const gameSetting = ({
  settings, labels, theme,
	onPageEvent
}) =>{
  return <Row centered width="100%" padding="16px 0">
    {settings.rows.map((row, rowIndex) => <Row
      key={`row_${rowIndex}`} centered width="95%" padding="8px 0">
      {row.cells.map((cell, cellIndex) => <Cell
        key={`row_${rowIndex}_${cellIndex}`} 
        width={(cell.width) ? cell.width : ``} 
         >
          <Row padding="4px 0 0" width="95%" centered>
            <Text theme={theme} textStyle={TEXT_STYLES.TEXT_NORMAL} >{cell.label}</Text>
          </Row>
          <Row padding="4px 0 0" width="95%" centered>
            {inputItem({
              theme,
              inputType: cell.input, id: cell.id, 
              value: settings.values[cell.id]||"", 
              required: settings.required.includes(cell.id),
              event: { key: "settings", fieldname: cell.id }, 
              required_label: labels.required_value, 
              options: cell.options,
              onPageEvent
            })}
          </Row>
      </Cell>)}
    </Row>)}
  </Row>
}

const userData = ({
  data, labels, languages, theme,
  onPageEvent
}) => {
  return <Row centered width="100%" padding="16px 0">
    <Row centered width="95%" padding="8px 0">
      <Cell>
        <Row padding="4px 0 0" width="95%" centered>
          <Text theme={theme} textStyle={TEXT_STYLES.TEXT_NORMAL} >{labels.user_name}</Text>
        </Row>
        <Row padding="4px 0 0" width="95%" centered>
          {inputItem({
            theme,
            inputType: "text", id: "user_name", 
            value: data.name, required: true,
            event: { key: "user", fieldname: "name" },
            required_label: labels.required_value, options: [],
            onPageEvent
          })}
        </Row>
      </Cell>
      {(data.showRole) ? <Cell>
        <Row padding="4px 0 0" width="95%" centered>
          <Text theme={theme} textStyle={TEXT_STYLES.TEXT_NORMAL} >{labels.user_role}</Text>
        </Row>
        <Row padding="4px 0 0" width="95%" centered>
          {inputItem({
            theme,
            inputType: "select", id: "user_role", 
            value: data.role, required: true,
            event: { key: "user", fieldname: "role" },
            required_label: labels.required_value,
            options: Object.values(USER_ROLE).map(role => {
              return { value: role, label: String(role).toUpperCase() }
            }),
            onPageEvent
          })}
        </Row>
      </Cell> : null}
      <Cell>
        <Row padding="4px 0 0" width="95%" centered>
          <Text theme={theme} textStyle={TEXT_STYLES.TEXT_NORMAL} >{labels.user_language}</Text>
        </Row>
        <Row padding="4px 0 0" width="95%" centered>
          {inputItem({
            theme,
            inputType: "select", id: "user_language", 
            value: data.language, required: true,
            event: { key: "user", fieldname: "language" },
            required_label: labels.required_value,
            options: languages,
            onPageEvent
          })}
        </Row>
      </Cell>
    </Row>
    {(data.email) ? <Row centered width="95%" padding="8px 0">
      <Cell>
        <Row padding="4px 0 0" width="95%" centered>
          <Text theme={theme} textStyle={TEXT_STYLES.TEXT_NORMAL} >{labels.user_email}</Text>
        </Row>
        <Row padding="4px 0 0" width="95%" centered>
          {inputItem({
            theme,
            inputType: "text", id: "user_email", 
            value: data.email, required: true,
            event: { key: "user", fieldname: "email" },
            required_label: labels.required_value, options: [],
            onPageEvent
          })}
        </Row>
      </Cell>
    </Row> : null}
    <Row centered width="95%" padding="8px 0">
      <Cell>
        <Row padding="4px 0 0" width="95%" centered>
          <Text textStyle={TEXT_STYLES.TEXT_NORMAL} >{labels.user_reference_id}</Text>
        </Row>
        <Row padding="4px 0 0" width="95%" centered>
          <InputContainer>
            {inputItem({
              theme,
              inputType: "text", id: "user_reference_id",
              disabled: true,
              value: data.reference_id,
              options: [],
            })}
            <Icon id={`user_ref_id_copy`} iconKey="PasteIcon"
              style={{
                height: 18, width: 18,
                color: theme.colors.iconActiveColor, cursor: "pointer"
              }}
              onClick={
                (event) => {
                  event.stopPropagation();
                  navigator.clipboard.writeText(data.reference_id)
                }}
            />
          </InputContainer>
        </Row>
      </Cell>
    </Row>
  </Row>
}

export const UserProfile = ({
  data, address, settings,
  labels, theme, profileTypes, selectedType, languages,
  onPageEvent,
  ...props
}) => {
  return <Modal id="user_profile">
    <Middle>
      <Dialog theme={theme}>
        <DialogContent theme={theme}>
          <Row width="100%" height="50px" 
            background={theme.colors.darkPanelBackground} radius="4px 4px 0px 0px" >
              <Cell grid width="46px" align="center">
                <Icon iconKey="EmptyAvatar" style={{ 
                  height: 18, width: 18, fill: theme.colors.iconActiveColor, verticalAlign: "middle"
                }} />
              </Cell>
              <Cell grid>
                <Text theme={theme} textStyle={TEXT_STYLES.TEXT_NORMAL} uppercase >{labels.title}</Text>
              </Cell>
              <Cell id="frm_cancel"
                grid width="36px" onClick={()=>onPageEvent({ key: "cancel" })}>
                <Icon iconKey="Times2" style={{ 
                  height: 14, width: 14, fill: theme.colors.iconActiveColor, 
                  verticalAlign: "middle", cursor: "pointer" 
                }} />
              </Cell>
          </Row>

          {(profileTypes.length > 1) ?<Row centered width="95%" padding="16px 0">
            <HorizontalTabbedMenu id="profile_type" theme={theme}
              menuElements={profileTypes}
              selected={selectedType}
              onClick={(value) => onPageEvent({ key: "profile_type", value: value })} />
          </Row> : null}

          {((selectedType === PROFILE_TYPE.GENERAL))
            ? userData({data, labels, languages, theme, onPageEvent}) : null}

          {((selectedType === PROFILE_TYPE.ADDRESS) && address)
            ? addressData({address, labels, theme, onPageEvent}) : null}

          {((selectedType === PROFILE_TYPE.GAME) && settings)
            ? gameSetting({settings, labels, theme, onPageEvent}) : null}
          
          <Row centered width="95%" padding="16px 0">
            <Cell width="49.9%" padding="0" grid>
              <Button id="btn_cancel" buttonStyle={BUTTON_STYLE.FOURTH}
                label={labels.cancel} theme={theme} onClick={() => onPageEvent({ key: "cancel" })} />
            </Cell>
            <Cell width="49.9%" padding="0" grid align="right">
              <Button id="btn_save" buttonStyle={BUTTON_STYLE.PRIMARY}
                label={labels.save} theme={theme} onClick={() => onPageEvent({ key: "save" })} />
            </Cell>
          </Row>
        </DialogContent>
      </Dialog>
    </Middle>
  </Modal>
}

UserProfile.propTypes = {
  /**
   * Authorized profile types
   */
  profileTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOf(Object.values(PROFILE_TYPE)).isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired
  })).isRequired,
  /**
   * Profile type
   */
  selectedType: PropTypes.oneOf(Object.values(PROFILE_TYPE)).isRequired,
  /**
   * User languages
   */
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  /**
   * General user data
   */
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    role: PropTypes.oneOf(Object.values(USER_ROLE)),
    language: PropTypes.string,
    email: PropTypes.string,
    showRole: PropTypes.bool
  }),
  /**
   * User address data
   */
  address: PropTypes.shape({
    name: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    post_code: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  }),
  /**
   * Upload custom (game) settings
   */
  settings: PropTypes.shape({
    values: PropTypes.object.isRequired,
    required: PropTypes.arrayOf(PropTypes.string).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape({
      separator: PropTypes.string,
      cells: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        input: PropTypes.oneOf(["text", "select"]).isRequired,
        label: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
          })
        ),
        width: PropTypes.string,
      }))
    }))
  }),
  /**
   * Component labels
   */
  labels: PropTypes.shape({
    title: PropTypes.string,
    cancel: PropTypes.string,
    save: PropTypes.string,
    required_value: PropTypes.string,
    address_country: PropTypes.string,
    address_city: PropTypes.string,
    address_post_code: PropTypes.string,
    address_address: PropTypes.string,
    address_name: PropTypes.string,
    user_name: PropTypes.string,
    user_reference_id: PropTypes.string,
    user_role: PropTypes.string,
    user_language: PropTypes.string,
    user_email: PropTypes.string,
  }),
  /**
   * Component brand theme
   */
  theme: PropTypes.object.isRequired,
	/**
	 * Profile page click event handler
	 */
	onPageEvent: PropTypes.func,
}

UserProfile.defaultProps = {
  profileTypes: [],
  selectedType: PROFILE_TYPE.GAME,
  languages: [],
  data: {
    name: "",
    role: undefined,
    language: undefined,
    email: undefined,
    showRole: false
  },
  address: undefined,
  settings: undefined,
  labels: {
    title: "",
    cancel: "",
    save: "",
    required_value: "",
    address_country: "",
    address_city: "",
    address_post_code: "",
    address_address: "",
    address_name: "",
    user_name: "",
    user_reference_id: "",
    user_role: "",
    user_language: "",
    user_email: "",
  },
	theme: {...appTheme},
	onPageEvent: undefined
}