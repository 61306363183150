import { AuthType } from "components/Product/Molecules/AuthForm/AuthForm"
import { MENU_STATE } from "components/Product/Organisms/SideMenu/SideMenu"
import { BUTTON_STYLE } from "components/Product/Atoms/Button/Button";
import { WELCOME_STEP_ALIGNMENT } from "components/Product/Organisms/WelcomeStep/WelcomeStep";
import { Games, UploadType, VIDEO_SHARE_TYPE } from "./enums"

export const current = {
  home: "home",
  module: "home",
  protect: ["rewards", "quests", "users", "exercises"],
  fallback: "fallback",
  params: [],
  showAdmin: false,
  sideIconView: (localStorage.getItem("icon_view") === "true") ? true : false,
  menuState: (localStorage.getItem("icon_view") === "true") ? MENU_STATE.COLLAPSED : MENU_STATE.EXPANDED,
  proMode: false,
  //indicatorEvent: { type: "progress", value: 20, label: "Match analyzing 20%" }
  //indicatorEvent: { type: "success", value: 0, label: "Analysis is complete" }
  //indicatorEvent: { type: "error", value: 0, label: "Match analysis failed" }
}

export const login = {
  email: localStorage.getItem("signin_email") || "",
  authType: AuthType.LOGIN,
  labels: {
    login: "login_login",
    sign_up: "login_signup", 
    email: "login_email",
    email_placeholder: "login_email_placeholder",
    password: "login_password",
    password_again: "login_password_again",
    eula: "login_eula",
    dont_have_an_account: "login_dont_have_account",
    already_have_an_account: "login_already_have_account",
    forgot_password: "login_forgot_password",
    reset_password: "login_reset_password",
    terms_and_conditions: "login_terms_and_conditions",
    privacy_policy: "login_privacy_policy",
    with: "login_with",
    input_proper_email: "login_input_proper_email",
    password_help: "login_password_help",
  }
}

export const home = {
  cards: {
    [Games.LOL]: [
        {
          card_image: "/config/brands/welcome/lol-welcome.png",
          step_image: "/config/brands/welcome/lol-step.png",
          align: WELCOME_STEP_ALIGNMENT.LEFT,
          button_style: BUTTON_STYLE.FOURTH,
          module: "recorder"
        },
        {
          card_image: "/config/brands/welcome/welcome-card-bg-2.png",
          step_image: "/config/brands/welcome/upload.png",
          align: WELCOME_STEP_ALIGNMENT.RIGHT,
          button_style: BUTTON_STYLE.FOURTH,
          module: "upload"
        },
        {
          card_image: "/config/brands/welcome/welcome-card-bg-3.png",
          step_image: "/config/brands/welcome/analysis.png",
          align: WELCOME_STEP_ALIGNMENT.LEFT,
          button_style: BUTTON_STYLE.FOURTH,
          module: "gallery"
        },
        {
          card_image: "/config/brands/welcome/welcome-card-bg-4.png",
          step_image: "/config/brands/welcome/quests.png",
          align: WELCOME_STEP_ALIGNMENT.RIGHT,
          button_style: BUTTON_STYLE.FOURTH,
          module: "cards"
        }
    ],
    [Games.APEX]: [
      {
        card_image: "/config/brands/welcome/apex-welcome.png",
        step_image: "/config/brands/welcome/apex-step.png",
        align: WELCOME_STEP_ALIGNMENT.LEFT,
        button_style: BUTTON_STYLE.FOURTH,
        module: "recorder"
      },
      {
        card_image: "/config/brands/welcome/welcome-card-bg-2.png",
        step_image: "/config/brands/welcome/upload.png",
        align: WELCOME_STEP_ALIGNMENT.RIGHT,
        button_style: BUTTON_STYLE.FOURTH,
        module: "upload"
      },
      {
        card_image: "/config/brands/welcome/welcome-card-bg-3.png",
        step_image: "/config/brands/welcome/analysis.png",
        align: WELCOME_STEP_ALIGNMENT.LEFT,
        button_style: BUTTON_STYLE.FOURTH,
        module: "gallery"
      },
      {
        card_image: "/config/brands/welcome/welcome-card-bg-4.png",
        step_image: "/config/brands/welcome/quests.png",
        align: WELCOME_STEP_ALIGNMENT.RIGHT,
        button_style: BUTTON_STYLE.FOURTH,
        module: "cards"
      }
    ],
    [Games.VALORANT]: [
      {
        card_image: "/config/brands/welcome/valorant-welcome.png",
        step_image: "/config/brands/welcome/valorant-step.png",
        align: WELCOME_STEP_ALIGNMENT.LEFT,
        button_style: BUTTON_STYLE.FOURTH,
        module: "recorder"
      },
      {
        card_image: "/config/brands/welcome/welcome-card-bg-2.png",
        step_image: "/config/brands/welcome/upload.png",
        align: WELCOME_STEP_ALIGNMENT.RIGHT,
        button_style: BUTTON_STYLE.FOURTH,
        module: "upload"
      },
      {
        card_image: "/config/brands/welcome/welcome-card-bg-3.png",
        step_image: "/config/brands/welcome/analysis.png",
        align: WELCOME_STEP_ALIGNMENT.LEFT,
        button_style: BUTTON_STYLE.FOURTH,
        module: "gallery"
      },
      {
        card_image: "/config/brands/welcome/welcome-card-bg-4.png",
        step_image: "/config/brands/welcome/quests.png",
        align: WELCOME_STEP_ALIGNMENT.RIGHT,
        button_style: BUTTON_STYLE.FOURTH,
        module: "cards"
      }
    ],
    [Games.CSGO]: [
      {
        card_image: "/config/brands/welcome/csgo-welcome.png",
        step_image: "/config/brands/welcome/csgo-step.png",
        align: WELCOME_STEP_ALIGNMENT.LEFT,
        button_style: BUTTON_STYLE.FOURTH,
        module: "recorder"
      },
      {
        card_image: "/config/brands/welcome/welcome-card-bg-2.png",
        step_image: "/config/brands/welcome/upload.png",
        align: WELCOME_STEP_ALIGNMENT.RIGHT,
        button_style: BUTTON_STYLE.FOURTH,
        module: "upload"
      },
      {
        card_image: "/config/brands/welcome/welcome-card-bg-3.png",
        step_image: "/config/brands/welcome/analysis.png",
        align: WELCOME_STEP_ALIGNMENT.LEFT,
        button_style: BUTTON_STYLE.FOURTH,
        module: "gallery"
      },
      {
        card_image: "/config/brands/welcome/welcome-card-bg-4.png",
        step_image: "/config/brands/welcome/quests.png",
        align: WELCOME_STEP_ALIGNMENT.RIGHT,
        button_style: BUTTON_STYLE.FOURTH,
        module: "cards"
      }
    ]
  },
  labels: {
    welcome_text: "home_welcome_text",
    get_started_text: "home_get_started_text",
    gameplay_question: "home_gameplay_question",
    button_upload: "home_button_upload",
    button_record: "home_button_record",
    card_1_title: "home_card_1_title",
    card_1_description: "home_card_1_description",
    step_1_steps: "home_step_1_steps",
    step_1_title: "home_step_1_title",
    step_1_description: "home_step_1_description",
    step_1_button_title: "home_step_1_button_title",
    card_2_title: "home_card_2_title", 
    card_2_description: "home_card_2_description",
    step_2_steps: "home_step_2_steps",
    step_2_title: "home_step_2_title",
    step_2_description: "home_step_2_description",
    step_2_button_title: "home_step_2_button_title",
    card_3_title: "home_card_3_title", 
    card_3_description: "home_card_3_description",
    step_3_steps: "home_step_3_steps",
    step_3_title: "home_step_3_title",
    step_3_description: "home_step_3_description",
    step_3_button_title: "home_step_3_button_title",
    card_4_title: "home_card_4_title", 
    card_4_description: "home_card_4_description",
    step_4_steps: "home_step_4_steps",
    step_4_title: "home_step_4_title",
    step_4_description: "home_step_4_description",
    step_4_button_title: "home_step_4_button_title",
  },
  backgrounds: {
    ALL: "/config/brands/default/home-background.png"
  }
}
export const signup = {
  labels: {
    sign_up: "signup_title",
    description: "signup_description",
  }
}

export const menu = {
  user: [
    { menuKey: "home", iconKey: "Home" },
    { menuKey: "sharing", iconKey: "Videos" },
    { menuKey: "cards", iconKey: "Quest" },
    { menuKey: "upload", iconKey: "VideoUpload" },
    { menuKey: "gallery", iconKey: "GraphBars" },
  ],
  admin: [
    { menuKey: "rewards", iconKey: "GiftIcon" },
    { menuKey: "quests", iconKey: "Quest" },
    { menuKey: "community", iconKey: "Assist" },
    { menuKey: "users", iconKey: "Users" },
    { menuKey: "exercises", iconKey: "Exercises" },
  ],
  labels: {
    uploadVideo: "menu_upload_video",
    collapse: "menu_collapse",
    adminMenuHeader: "menu_admin_header",
    analyzing: "upload_progress_analyzing",
    uploading: "upload_progress_uploading",
    done: "upload_progress_done",
    error: "upload_progress_error",
    uploadingDescription: "upload_progress_uploadingDescription",
    analyzingDescription: "upload_progress_analyzingDescription",
    doneDescription: "upload_progress_doneDescription",
    errorDescription: "upload_progress_errorDescription",
    checkResultButton: "upload_progress_checkResultButton",
    proMode: "pro_mode",
    subscription: "menu_subscription",
  }
}

export const cards = {
  // //all, active, completed 
  // state: "all",
  // difficulty: {
  //   easy: true, medium: true, hard: true, insane: true
  // },
  // questData: null,
  labels: {
    title_start: "cards_title_start",
    title_end: "cards_title_end",
    subtitle: "cards_subtitle",
    daily_quests: "cards_daily_quests_section_title",
    daily_quests_description: "cards_daily_quests_section_desc",
    community_quests: "cards_community_quest_section_title",
    community_quests_description: "cards_community_quest_section_desc",
    personal_quests: "cards_quest_section_title",
    personal_quests_description: "cards_quest_section_desc",
    no_quests: "cards_no_quests",
    progressbar_label: "cards_quest_completeness",
    sort_options_expiry_date: "cards_sort_expiry_date",
    sort_options_completion_state: "cards_sort_completion_state",
    sort_options_difficulty: "cards_sort_difficulty",
    filters_all: "cards_filters_all",
    filters_state_active: "cards_filters_state_active",
    filters_state_completed: "cards_filters_state_completed",
    filters_difficulty_easy: "cards_filters_difficulty_easy",
    filters_difficulty_medium: "cards_filters_difficulty_medium",
    filters_difficulty_hard: "cards_filters_difficulty_hard",
    filters_difficulty_insane: "cards_filters_difficulty_insane",
  },
  backgrounds: {
    ALL: "/config/brands/default/quests-background.png"
  },
  intro : {
		steps: [
			{
				id: "cards_intro_greeting",
				title: "cards_intro_greeting_title",
				intro: "cards_intro_greeting_intro"
			},
			{
				id: "cards_intro_cards_filters",
				title: "cards_intro_cards_filters_title",
				element: "#cards_filters",
				intro: "cards_intro_cards_filters_intro"
			},
			{
				id: "cards_intro_cards_share_progress",
				title: "cards_intro_cards_share_progress_title",
				element: "#cards_share_progress",
				intro: "cards_intro_cards_share_progress_intro"
			},
			{
				id: "cards_intro_personal_quests",
				title: "cards_intro_personal_quests_title",
				element: "#personal_quests",
				intro: "cards_intro_personal_quests_intro"
			},
		],
		filters: {
      cards_start: [
				"cards_intro_greeting", "cards_intro_cards_filters", "cards_intro_cards_share_progress", "cards_intro_personal_quests"
			]
    }
	}
}

export const video = {
  labels: {
    page_back: "video_page_back",
    share_match: "video_share_match",
    share_message: "video_share_message",
    character_title: "video_character_title",
    exercise_separator: "video_exercise_separator",
    guide_separator: "video_guide_separator",
    community_separator: "video_community_separator",
    quest_separator: "video_quest_separator",
    highlight_separator: "video_highlight_separator",
    timeline_separator: "video_timeline_separator",
    highlight_info_open: "video_highlight_info_open",
    highlight_info_created: "video_highlight_info_created",
    highlight_max_error: "video_match_highlight_max_error",
    highlight_clip: "video_highlight_clip",
    highlight_time: "video_highlight_time",
    value_label: "video_value",
    highlight_separate: "video_highlight_separate",
    highlight_merged: "video_highlight_merged",
    highlight_download: "video_highlight_download",
    highlight_creating: "video_highlight_creating",
    download_error: "video_match_highlight_download_error",
    player_hotkeys_tip: "video_player_hotkeys_tip",
    highlight_created: "video_match_highlight_created",
    event_type: "video_event_type",
    player_hotkeys: "video_player_hotkeys",
    filter_placeholder: "video_filter_placeholder",
    previous_event: "video_previous_event",
    next_event: "video_next_event",
    data_load: "video_data_load",
    card_title: "video_card_title",
    card_solo: "video_card_solo",
    card_team: "video_card_team",
    chart_title: "video_chart_title",
    chart_individual: "video_chart_individual",
    chart_team: "video_chart_team",
    details: "video_details",
    brief: "video_brief",
    category: "video_category",
    length: "video_length",
    frequency: "video_frequency",
    rule: "video_rule",
    view_overview: "video_view_overview",
    view_quests: "video_view_quests",
    view_statistics: "video_view_statistics",
    view_exercise: "video_view_exercise",
    event_group_main: "video_properties_main_events",
    event_group_other: "video_properties_other_events",
    event_group_all: "video_properties_all_events",
    title_public: "video_sharing_title_public",
    title_private: "video_sharing_title_private",
    title_unlisted: "video_sharing_title_unlisted",
    separator_details: "video_sharing_separator_details",
    separator_users: "video_sharing_separator_users",
    separator_private: "video_sharing_separator_private",
    separator_public: "video_sharing_separator_public",
    video_title: "video_sharing_video_title",
    video_title_placeholder: "video_sharing_video_title_placeholder",
    link_public: "video_sharing_link_public",
    link_private: "video_sharing_link_private",
    user_input: "video_sharing_user_input",
    user_input_placeholder: "video_sharing_user_input_placeholder",
    btn_cancel: "video_sharing_btn_cancel",
    btn_save: "video_sharing_btn_save",
    copied: "video_sharing_copied",
    sharing_info_public: "video_sharing_info_public",
    sharing_info_unlisted: "video_sharing_info_unlisted",
    sharing_info_private: "video_sharing_info_private",
    anonymus_user: "video_anonymus_user",
    public_login: "video_public_login",
    share_public: "video_share_public",
    share_unlisted: "video_share_unlisted",
    share_private: "video_share_private",
  },
  backgrounds: {
    [Games.LOL]: [
      '/config/brands/default/backgrounds/lol-bg-1.png',
      '/config/brands/default/backgrounds/lol-bg-2.png',
      '/config/brands/default/backgrounds/lol-bg-3.png',
    ],
    [Games.APEX]: [
      '/config/brands/default/backgrounds/apex-bg-1.png',
      '/config/brands/default/backgrounds/apex-bg-2.png',
      '/config/brands/default/backgrounds/apex-bg-3.png',
    ],
    [Games.VALORANT]: [
      '/config/brands/default/backgrounds/valorant-bg-1.png',
      '/config/brands/default/backgrounds/valorant-bg-2.png',
      '/config/brands/default/backgrounds/valorant-bg-3.png',
    ],
    [Games.CSGO]: [
      '/config/brands/default/backgrounds/csgo-bg-1.png',
      '/config/brands/default/backgrounds/csgo-bg-2.png',
      '/config/brands/default/backgrounds/csgo-bg-3.png',
    ]
  },
  jumpBack: 3,
  chartBarWidth: 3,
  chartSpaceWidth: 2,
  hideEvents: [
    "EVENT_APEX_GAME_STARTED",
    "EVENT_APEX_PLAYER_DAMAGE_DONE",
    "EVENT_APEX_SQUAD_KILL",
    "EVENT_CHARACTER_SWITCH",
    "EVENT_STATISTIC",
    "EVENT_VIDEO_END",
    "EVENT_VALORANT_PLAYER_SPECTATOR_STATUS",
    "EVENT_AMMO_STATUS",
    "EVENT_HP_STATUS",
    "EVENT_VALORANT_KILLFEED",
    "EVENT_VALORANT_ALLY_SCORE",
    "EVENT_VALORANT_ENEMY_SCORE",
    "EVENT_VALORANT_PLAYER_GUN_SWITCH",
    "EVENT_LOL_PARTICIPANT_FRAME",
    "EVENT_VALORANT_WINNING_ALLY",
    "EVENT_VALORANT_ROUND_START",
    "EVENT_LOL_PLAYER_GOLD",
    "EVENT_APEX_PLAYER_GUN_MODE_SWITCH",
    "EVENT_APEX_PLAYER_GUN_SLOT",
    "EVENT_APEX_PLAYER_SPECTATE",
    "EVENT_APEX_PLAYER_GUN_SUPPLY",
    "EVENT_CSGO_GAME_START",
    "EVENT_CSGO_GAME_END",
    "EVENT_CSGO_ROUND_TIME",
    "EVENT_CSGO_PLAYER_IS_SPECTATES",
  ],
  characterEventsOrder: {
    OVERWATCH: [],
    LOL: [
      'EVENT_LOL_CHAMPION_KILL',
      'EVENT_LOL_CREEP_SCORE',
      'EVENT_LOL_PLAYER_ABILITY_R_USED',
      'EVENT_LOL_PLAYER_ABILITY_Q_USED',
      'EVENT_LOL_PLAYER_ABILITY_W_USED',
      'EVENT_LOL_PLAYER_ABILITY_E_USED',
      'EVENT_LOL_PLAYER_SUMMONER_D_USED',
      'EVENT_LOL_PLAYER_SUMMONER_F_USED',
    ],
    CSGO: [
      "EVENT_CSGO_PLAYER_DEATH",
      "EVENT_CSGO_PLAYER_KILL",
      "EVENT_CSGO_PLAYER_MONEY",
    ],
    VALORANT: [
      'EVENT_VALORANT_PLAYER_KILL',
      'EVENT_VALORANT_PLAYER_DEATH',
      'EVENT_VALORANT_PLAYER_ASSIST',
    ],
    APEX: [
      'EVENT_APEX_PLAYER_COMBAT_STARTED',
      'EVENT_APEX_PLAYER_KILL',
      'EVENT_APEX_PLAYER_ASSIST',
      'EVENT_APEX_PLAYER_KNOCKED_DOWN',
      'EVENT_APEX_PLAYER_DEATH',
      'EVENT_APEX_PLAYER_DAMAGE_DONE',
      'EVENT_APEX_PLAYER_DAMAGE_TAKEN',
      'EVENT_APEX_PLAYER_HEAL',
      'EVENT_APEX_PLAYER_SHIELD_CHARGE',
    ]
  },
  resourceURL: {
    YT: "https://www.youtube.com/watch?v="
  },
  intro : {
		steps: [
			{
				id: "video_intro_greeting",
				title: "video_intro_greeting_title",
				intro: "video_intro_greeting_intro"
			},
			{
				id: "video_intro_video_info",
				title: "video_intro_video_info_title",
				element: "#video_info",
				intro: "video_intro_video_info_intro"
			},
      {
				id: "video_intro_video_share",
				title: "video_intro_video_share_title",
				element: "#video_share",
				intro: "video_intro_video_share_intro"
			},
      {
				id: "video_intro_video_settings",
				title: "video_intro_video_settings_title",
				element: "#video_settings",
				intro: "video_intro_video_settings_intro"
			},
      {
				id: "video_intro_video_view",
				title: "video_intro_video_view_title",
				element: "#video_view",
				intro: "video_intro_video_view_intro"
			},
      {
				id: "video_intro_video_event",
				title: "video_intro_video_event_title",
				element: "#video_event",
				intro: "video_intro_video_event_intro"
			},
      {
				id: "video_intro_video_events_table",
				title: "video_intro_video_events_table_title",
				element: "#video_events_table",
				intro: "video_intro_video_events_table_intro"
			},
      {
				id: "video_intro_video_highligh",
				title: "video_intro_video_highligh_title",
				element: "#video_highligh",
				intro: "video_intro_video_highligh_intro"
			},
      {
				id: "video_intro_video_view_quests",
				title: "video_intro_video_view_quests_title",
				element: "#video_view_quests",
				intro: "video_intro_video_view_quests_intro"
			},
      {
				id: "video_intro_video_view_statistics",
				title: "video_intro_video_view_statistics_title",
				element: "#video_view_statistics",
				intro: "video_intro_video_view_statistics_intro"
			},
      {
				id: "video_intro_video_view_exercise",
				title: "video_intro_video_view_exercise_title",
				element: "#video_view_exercise",
				intro: "video_intro_video_view_exercise_intro"
			},
		],
		filters: {
      video_start: [
        "video_intro_greeting", "video_intro_video_info", "video_intro_video_share", "video_intro_video_settings",
        "video_intro_video_view", "video_intro_video_event", "video_intro_video_events_table", "video_intro_video_highligh",
        "video_intro_video_view_quests", "video_intro_video_view_statistics", "video_intro_video_view_exercise"
      ]
    },
	}
}

export const gallery = {
  labels: {
      title: "gallery_title",
      subtitle: "gallery_subtitle",
      separator: "gallery_separator_title",
      match_error_description: "gallery_match_error_description",
      match_error_more_info: "gallery_match_error_more_info",
      match_processing: "gallery_match_processing",
      match_uploading: "gallery_match_uploading",
      match_analyzing: "gallery_match_analyzing",
      off_state: "gallery_pro_mode_off",
      filters_all: "gallery_filters_all",
      filters_day: "gallery_filters_date_day",
      filters_week: "gallery_filters_date_week",
      filters_month: "gallery_filters_date_month",
      filters_done: "gallery_filters_state_done",
      filters_in_progress: "gallery_filters_state_in_progress",
      filters_error: "gallery_filters_state_error",
      filters_victory: "gallery_filters_result_victory",
      filters_draw: "gallery_filters_result_draw",
      filters_defeat: "gallery_filters_result_defeat",
      switch: "gallery_completed_quests_switch",
      search_placeholder: "gallery_search_placeholder",
  },
  backgrounds: {
    ALL: "/config/brands/default/video-background.png",
    [Games.LOL]: [
      '/config/brands/default/backgrounds/lol-bg-1.png',
      '/config/brands/default/backgrounds/lol-bg-2.png',
      '/config/brands/default/backgrounds/lol-bg-3.png',
    ],
    [Games.APEX]: [
      '/config/brands/default/backgrounds/apex-bg-1.png',
      '/config/brands/default/backgrounds/apex-bg-2.png',
      '/config/brands/default/backgrounds/apex-bg-3.png',
    ],
    [Games.VALORANT]: [
      '/config/brands/default/backgrounds/valorant-bg-1.png',
      '/config/brands/default/backgrounds/valorant-bg-2.png',
      '/config/brands/default/backgrounds/valorant-bg-3.png',
    ],
    [Games.CSGO]: [
      '/config/brands/default/backgrounds/csgo-bg-1.png',
      '/config/brands/default/backgrounds/csgo-bg-2.png',
      '/config/brands/default/backgrounds/csgo-bg-3.png',
    ]
  },
  defaultMatchCardImage: {
    [Games.LOL]: "/config/brands/default/lol_cover.jpg",
    [Games.VALORANT]: "/config/brands/default/valorant_cover.jpg",
    [Games.APEX]: "/config/brands/default/apex_cover.jpg",
    [Games.CSGO]: "/config/brands/default/csgo_cover.jpg",
  },
  intro : {
		steps: [
			{
				id: "gallery_intro_greeting",
				title: "gallery_intro_greeting_title",
				intro: "gallery_intro_greeting_intro"
			},
			{
				id: "gallery_intro_filters",
        element: "#filters",
				title: "gallery_intro_filters_title",
				intro: "gallery_intro_filters_intro"
			},
			{
				id: "gallery_intro_videos_table",
        element: "#videos_table_0",
				title: "gallery_intro_videos_table_title",
				intro: "gallery_intro_videos_table_intro"
			},
		],
		filters: {
      gallery_start: [
				"gallery_intro_greeting", "gallery_intro_filters", "gallery_intro_videos_table"
			]
    },
	},
}

export const upload = {
  init: false,
  can_upload: true,
  check_requirements: true,
  content_type: "video/mp4",
  vod_type: "PLAYER_VOD",
  maxFileSize: 5,
  apiSearchSubtractDays: 6,
  summoner_region: "jp1",
  match_region: "ap",
  share_type: VIDEO_SHARE_TYPE.PUBLIC,
  checker: {
    validWidth: [
      1920,
      1280
    ],
    validHeights: [
      1080,
      720
    ],
    minFps: 25,
    maxFps: 60,
    minBitrate: 3072000,
    maxBitrate: 8192000,
    minLength: 300,
    maxLength: 7200,
    minSize: 0,
    maxSize: 3221225472
  },
  upload_type: undefined,
  upload_types: [
    UploadType.FILE, UploadType.YOUTUBE
  ],
  labels: {
    title_start: "upload_title_start",
    download_button: "upload_download_button",
    requirements_title: "upload_requirements_title",
    requirements_text: "upload_requirements_text",
    filebox_text: "upload_filebox_text",
    browse_file: "upload_browse_file",
    youtube_link: "upload_youtube_link",
    youtube_placeholder: "upload_youtube_placeholder",
    youtube_button: "upload_youtube_button",
    remaining_uploads: "upload_remaining_uploads",
    infinite: "upload_infinite",
    expiration_date: "upload_expiration_date",
    upgrade: "upload_upgrade",
    no_limit: "upload_no_limit",
    no_active_subscription: "upload_no_active_subscription",
    youtube_id: "upload_youtube_id",
    file_name: "upload_file_name",
    upload_button: "upload_upload_button",
    show_match_details: "upload_show_match_details",
    load_match_data: "upload_load_match_data",
    uploads_disabled: "upload_uploads_disabled",

    recorder: "upload_recorder",
		recorder_title: "upload_recorder_title",
		recorder_description: "upload_recorder_description",
		advanced: "upload_advanced",

    guide_title: "upload_guide_title",
    guide_description: "upload_guide_description",
    guide_start_upload: "upload_guide_start_upload",
    guide_previous: "upload_guide_previous",
    guide_next: "upload_guide_next",

    analyzing: "upload_progress_analyzing",
    uploading: "upload_progress_uploading",
    done: "upload_progress_done",
    error: "upload_progress_error",
    abort: "upload_progress_abort",
    uploadingDescription: "upload_progress_uploadingDescription",
    analyzingDescription: "upload_progress_analyzingDescription",
    doneDescription: "upload_progress_doneDescription",
    errorDescription: "upload_progress_errorDescription",
    checkResultButton: "upload_progress_checkResultButton",

    last_upload_result: "upload_last_upload_result",
    last_upload_more_info: "upload_last_upload_more_info",
    last_upload_failed: "upload_last_upload_failed",
    last_upload_errors: "upload_last_upload_errors",
    be_aware: "upload_be_aware",
    upload_stop: "upload_stop",
    no_navigate: "upload_no_navigate",
    no_close: "upload_no_close",
    no_launch: "upload_no_launch",
    understand: "upload_understand"
  },
  requirements: [
		{ guide_index: 0,
      error_keys: ["RESOLUTION_PROBLEM"],
      labels: {
        main: "upload_requirements_0_main", 
        secondary: "upload_requirements_0_secondary", 
        tooltip:  "upload_requirements_0_tooltip"
      } 
    },
		{ guide_index: 1,
      error_keys: ["BITRATE_PROBLEM", "FPS_PROBLEM"],
      labels: {
        main: "upload_requirements_1_main", 
        secondary: "upload_requirements_1_secondary",
        tooltip: "upload_requirements_1_tooltip",
      }
    },
		{ guide_index: 1,
      error_keys: ["BITRATE_PROBLEM", "FPS_PROBLEM"],
      labels: {
        main: "upload_requirements_2_main", 
        secondary: "upload_requirements_2_secondary",
        tooltip: "upload_requirements_2_tooltip",
      }
    },
		{ guide_index: 2,
      error_keys: ["INVALID_VIDEO"],
      labels: {
        main: "upload_requirements_3_main",
        tooltip: "upload_requirements_3_tooltip",
      }
    },
		{ guide_index: 2,
      error_keys: ["SIZE_PROBLEM"],
      labels: {
        main: "upload_requirements_4_main",
        tooltip: "upload_requirements_4_tooltip",
      } 
    },
		{ guide_index: 3,
      error_keys: [],
      labels: {
        main: "upload_requirements_5_main",
        tooltip: "upload_requirements_5_tooltip",
      }
    },
		{ guide_index: 4,
      error_keys: ["CHARACTER_DIFFERENT", "NO_CHARACTER", "NO_FRAMES", "NO_RIOT_SYNC_TIMESTAMPS", "RIOT_DATA_NOT_AVAILABLE", "TIME_SYNC"], 
      labels: {
        main: "upload_requirements_6_main",
        tooltip: "upload_requirements_6_tooltip",
      } 
    },
		{ guide_index: 5,
      error_keys: ["NO_VA_SYNC_TIMESTAMPS"], 
      labels: {
        main: "upload_requirements_7_main",
        tooltip: "upload_requirements_7_tooltip",
      }
    },
		{ guide_index: 6,
      error_keys: ["FIRST_ROUND_MISSING", "INTERNAL_ERROR", "LENGTH_PROBLEM", "VIDEO_NOT_FOUND"],
      labels: {
        main: "upload_requirements_8_main",
        tooltip: "upload_requirements_8_tooltip",
      }
    },
	],
  intro: {
    steps: [
			{
				id: "upload_start_intro_greeting",
				title: "upload_start_intro_greeting_title",
				intro: "upload_start_intro_greeting_intro"
			},
			{
				id: "upload_start_intro_file_upload",
				title: "upload_start_intro_file_upload_title",
				element: "#file_input_view",
				intro: "upload_start_intro_file_upload_intro"
			},
			{
				id: "upload_start_intro_requirement_card",
				title: "upload_start_intro_requirement_card_title",
				element: "#requirement_card_3",
				intro: "upload_start_intro_requirement_card_intro"
			},
			{
				id: "upload_start_intro_recorder",
				title: "upload_start_intro_recorder_title",
				element: "#recorder_button",
				intro: "upload_start_intro_recorder_intro"
			},
			{
				id: "upload_setting_intro_greeting",
				title: "upload_setting_intro_greeting_title",
				intro: "upload_setting_intro_greeting_intro"
			},
			{
				id: "upload_setting_intro_file_id",
				title: "upload_setting_intro_file_id_title",
				element: "#file_id",
				intro: "upload_setting_intro_file_id_intro"
			},
			{
				id: "upload_setting_intro_file_reset",
				title: "upload_setting_intro_file_reset_title",
				element: "#file_reset",
				intro: "upload_setting_intro_file_reset_intro"
			},
			{
				id: "upload_setting_intro_title",
				title: "upload_setting_intro_title_title",
				element: "#video_title",
				intro: "upload_setting_intro_title_intro"
			},
			{
				id: "upload_setting_intro_share",
				title: "upload_setting_intro_share_title",
				element: "#share_type",
				intro: "upload_setting_intro_share_intro"
			},
			{
				id: "upload_setting_intro_upload",
				title: "upload_setting_intro_upload_title",
				element: "#upload_btn",
				intro: "upload_setting_intro_upload_intro"
			},
		],
		filters: {
      upload_start: [
        "upload_start_intro_greeting", "upload_start_intro_file_upload", "upload_start_intro_requirement_card", "upload_start_intro_recorder"
      ],
      upload_setting: [
        "upload_setting_intro_greeting", "upload_setting_intro_file_id", "upload_setting_intro_file_reset",
			  "upload_setting_intro_title", "upload_setting_intro_share", "upload_setting_intro_upload"
      ]
    }
  },
  guide: {
    LOL: [
      {
        image: "/config/brands/upload/upload_lol_resolution.png",
        labels: {
          description: "upload_lol_guide_0_description",
        },
      },
      {
        image: "/config/brands/upload/upload_lol_bitrate.png",
        labels: {
          description: "upload_lol_guide_1_description",
        },
      },
      {
        image: "/config/brands/upload/upload_lol_file_type.png",
        labels: {
          description: "upload_lol_guide_2_description",
        },
      },
      {
        image: "/config/brands/upload/upload_lol_aspect_ratio.png",
        labels: {
          title: "upload_lol_guide_3_title",
          leftLabel: "upload_lol_guide_3_leftLabel",
          rightLabel: "upload_lol_guide_3_rightLabel",
          description: "upload_lol_guide_3_description",
        },
      },
      {
        image: "/config/brands/upload/upload_lol_game_type.png",
        labels: {
          title: "upload_lol_guide_4_title",
          leftLabel: "upload_lol_guide_4_leftLabel",
          rightLabel: "upload_lol_guide_4_rightLabel",
          description: "upload_lol_guide_4_description",
        },
      },
      {
        image: "/config/brands/upload/upload_lol_clean_ui.png",
        labels: {
          title: "upload_lol_guide_5_title",
          leftLabel: "upload_lol_guide_5_leftLabel",
          rightLabel: "upload_lol_guide_5_rightLabel",
          description: "upload_lol_guide_5_description",
        },
      },
      {
        image: "/config/brands/upload/upload_lol_full_match.png",
        labels: {
          title: "upload_lol_guide_6_title",
          leftLabel: "upload_lol_guide_6_leftLabel",
          rightLabel: "upload_lol_guide_6_rightLabel",
          description: "upload_lol_guide_6_description",
        },
      },
    ],
    VALORANT: [
      {
        image: "/config/brands/upload/upload_valorant_resolution.png",
        labels: {
          description: "upload_valorant_guide_0_description",
        },
      },
      {
        image: "/config/brands/upload/upload_valorant_bitrate.png",
        labels: {
          description: "upload_valorant_guide_1_description",
        },
      },
      {
        image: "/config/brands/upload/upload_valorant_file_type.png",
        labels: {
          description: "upload_valorant_guide_2_description",
        },
      },
      {
        image: "/config/brands/upload/upload_valorant_aspect_ratio.png",
        labels: {
          title: "upload_valorant_guide_3_title",
          leftLabel: "upload_valorant_guide_3_leftLabel",
          rightLabel: "upload_valorant_guide_3_rightLabel",
          description: "upload_valorant_guide_3_description",
        },
      },
      {
        image: "/config/brands/upload/upload_valorant_game_type.png",
        labels: {
          title: "upload_valorant_guide_4_title",
          leftLabel: "upload_valorant_guide_4_leftLabel",
          rightLabel: "upload_valorant_guide_4_rightLabel",
          description: "upload_valorant_guide_4_description",
        },
      },
      {
        image: "/config/brands/upload/upload_valorant_clean_ui.png",
        labels: {
          title: "upload_valorant_guide_5_title",
          leftLabel: "upload_valorant_guide_5_leftLabel",
          rightLabel: "upload_valorant_guide_5_rightLabel",
          description: "upload_valorant_guide_5_description",
        },
      },
      {
        image: "/config/brands/upload/upload_valorant_full_match.png",
        labels: {
          title: "upload_valorant_guide_6_title",
          leftLabel: "upload_valorant_guide_6_leftLabel",
          rightLabel: "upload_valorant_guide_6_rightLabel",
          description: "upload_valorant_guide_6_description",
        },
      },
    ],
    APEX: [
      {
        image: "/config/brands/upload/upload_apex_resolution.png",
        labels: {
          description: "upload_apex_guide_0_description",
        },
      },
      {
        image: "/config/brands/upload/upload_apex_bitrate.png",
        labels: {
          description: "upload_apex_guide_1_description",
        },
      },
      {
        image: "/config/brands/upload/upload_apex_file_type.png",
        labels: {
          description: "upload_apex_guide_2_description",
        },
      },
      {
        image: "/config/brands/upload/upload_apex_aspect_ratio.png",
        labels: {
          title: "upload_apex_guide_3_title",
          leftLabel: "upload_apex_guide_3_leftLabel",
          rightLabel: "upload_apex_guide_3_rightLabel",
          description: "upload_apex_guide_3_description",
        },
      },
      {
        image: "/config/brands/upload/upload_apex_game_type.png",
        labels: {
          title: "upload_apex_guide_4_title",
          leftLabel: "upload_apex_guide_4_leftLabel",
          rightLabel: "upload_apex_guide_4_rightLabel",
          description: "upload_apex_guide_4_description",
        },
      },
      {
        image: "/config/brands/upload/upload_apex_clean_ui.png",
        labels: {
          title: "upload_apex_guide_5_title",
          leftLabel: "upload_apex_guide_5_leftLabel",
          rightLabel: "upload_apex_guide_5_rightLabel",
          description: "upload_apex_guide_5_description",
        },
      },
      {
        image: "/config/brands/upload/upload_apex_full_match.png",
        labels: {
          title: "upload_apex_guide_6_title",
          leftLabel: "upload_apex_guide_6_leftLabel",
          rightLabel: "upload_apex_guide_6_rightLabel",
          description: "upload_apex_guide_6_description",
        },
      },
    ],
    CSGO: [
      {
        image: "/config/brands/upload/upload_csgo_resolution.png",
        labels: {
          description: "upload_csgo_guide_0_description",
        },
      },
      {
        image: "/config/brands/upload/upload_csgo_bitrate.png",
        labels: {
          description: "upload_csgo_guide_1_description",
        },
      },
      {
        image: "/config/brands/upload/upload_csgo_file_type.png",
        labels: {
          description: "upload_csgo_guide_2_description",
        },
      },
      {
        image: "/config/brands/upload/upload_csgo_aspect_ratio.png",
        labels: {
          title: "upload_csgo_guide_3_title",
          leftLabel: "upload_csgo_guide_3_leftLabel",
          rightLabel: "upload_csgo_guide_3_rightLabel",
          description: "upload_csgo_guide_3_description",
        },
      },
      {
        image: "/config/brands/upload/upload_csgo_game_type.png",
        labels: {
          title: "upload_csgo_guide_4_title",
          leftLabel: "upload_csgo_guide_4_leftLabel",
          rightLabel: "upload_csgo_guide_4_rightLabel",
          description: "upload_csgo_guide_4_description",
        },
      },
      {
        image: "/config/brands/upload/upload_csgo_clean_ui.png",
        labels: {
          title: "upload_csgo_guide_5_title",
          leftLabel: "upload_csgo_guide_5_leftLabel",
          rightLabel: "upload_csgo_guide_5_rightLabel",
          description: "upload_csgo_guide_5_description",
        },
      },
      {
        image: "/config/brands/upload/upload_csgo_full_match.png",
        labels: {
          title: "upload_csgo_guide_6_title",
          leftLabel: "upload_csgo_guide_6_leftLabel",
          rightLabel: "upload_csgo_guide_6_rightLabel",
          description: "upload_csgo_guide_6_description",
        },
      },
    ]
  },
  settings: {
    ALL: {
      values: {},
      required: [
        "video_title", "share_type"
      ],
      rows: [
        {
          cells: [
            { id: "video_title", input: "text" },
            { id: "share_type", input: "select", source: "share_types", width: "140px" }
          ]
        }
      ]
    },
    LOL: {
      required: [
        "summoner_name", "summoner_region"
      ],
      rows: [
        {
          cells: [
            { id: "summoner_name", input: "text" },
            { id: "summoner_region", input: "select", source: "match_regions", width: "110px" }
          ]
        }
      ]
    },
    VALORANT: {
      required: [
        "account_name", "account_tag", "match_region"
      ],
      rows: [
        {
          details: true,
          cells: [
            { id: "account_name", input: "text" },
            { id: "account_tag", input: "text" },
            { id: "match_region", input: "select", source: "match_regions", width: "110px" }
          ]
        },
      ]
    }
  },
  details: {
    LOL: {
      fields: [
        { id: "date_played", fieldtype: 'datetime', verticalAlign: 'middle' },
        { id: "champion", fieldtype: 'string', verticalAlign: 'middle' },
        { id: "lane", fieldtype: 'string', verticalAlign: 'middle' }
      ],
      source: "lolmatchlist"
    },
    VALORANT: {
      rows: [
        {
          cells: [
            { id: "begin_date", input: "text" }
          ]
        }
      ],
      fields: [
        { id: "date_played", fieldtype: 'datetime', verticalAlign: 'middle' },
        { id: "agent", fieldtype: 'string', verticalAlign: 'middle' },
        { id: "kda", fieldtype: 'string', verticalAlign: 'middle' },
        { id: "result", fieldtype: 'string', verticalAlign: 'middle' }
      ],
      source: "valorantmatchlist"
    }
  },
  backgrounds: {
    ALL: "/config/brands/default/upload-background.png"
  }
}

export const quest = {
}

export const quests = {
  advanced_search: true,
  search: { date_filter: "created" },
  items: []
}

export const community = {
  advanced_search: true,
  search: { date_filter: "created" },
  items: []
}

export const rewards = {
  page: 0,
  items: [],
  labels: {
    title: "rewards_title",
    search_title: "rewards_search_title",
    new_title: "rewards_new_title",
    advanced_search: "rewards_advanced_search",
    search: "rewards_search",
    name_placeholder: "rewards_name",
    page_back: "rewards_back_main",
    name: "rewards_name",
    reward_type: "rewards_type",
    description: "rewards_description",
    created: "rewards_created",
    edit_title: "rewards_edit_title",
    type: "rewards_type",
    type_coupon: "rewards_type_coupon",
    type_other: "rewards_type_other",
    type_api: "rewards_type_api",
    status: "rewards_status",
    status_active: "rewards_status_active",
    status_inactive: "rewards_status_inactive",
    required_placeholder: "app_required_field",
    coupon_code: "rewards_coupon_code",
    coupon_link: "rewards_coupon_link",
    link: "rewards_link",
    preview_link: "rewards_preview_link",
    payload: "rewards_payload",
    update: "rewards_update",
    delete_filters: "rewards_delete_filters",
    exists_reward: "rewards_exists_reward",
  }
}

export const exercises = {
  advanced_search: true,
  search: {},
  items: []
}

export const users = {
  advanced_search: true,
  search: {},
  items: []
}

export const trigger = {
  DYNAMIC: {
    LolCharacter: "LolCharacter",
    ValorantCharacter: "ValorantCharacter",
    ApexCharacter: "ApexCharacter",
  },
  GLOBAL: {
    GEN: [],
    OVERWATCH: [
      "FRIEND_COLOR",
      "FOE_COLOR",
      "VOD",
      "SR"
    ],
    LOL: [
      "LolCharacter"
    ],
    CSGO: [],
    VALORANT: [
      "ValorantCharacter"
    ],
    APEX: [
      "ApexCharacter"
    ]
  },
  EVENT: {
    GEN: [],
    OVERWATCH: [
      "EVENT_KILLFEED",
      "EVENT_KILLCAM_START",
      "EVENT_KILLCAM_END",
      "EVENT_RESPAWN"
    ],
    LOL: [
      "EVENT_LOL_BUILDING_KILL",
      "EVENT_LOL_CHAMPION_KILL",
      "EVENT_LOL_ITEM",
      "EVENT_LOL_MONSTER_KILL",
      "EVENT_LOL_PARTICIPANT_FRAME",
      "EVENT_LOL_SKILL_LEVEL_UP",
      "EVENT_LOL_WARD",
      "EVENT_LOL_PLAYER_LEVEL",
      "EVENT_LOL_PLAYER_GOLD",
      "EVENT_LOL_CREEP_SCORE",
      // "EVENT_LOL_PLAYER_RECALL",
      "EVENT_LOL_PLAYER_ABILITY_Q_USED",
      "EVENT_LOL_PLAYER_ABILITY_W_USED",
      "EVENT_LOL_PLAYER_ABILITY_E_USED",
      "EVENT_LOL_PLAYER_ABILITY_R_USED",
      "EVENT_LOL_PLAYER_SUMMONER_D_USED",
      "EVENT_LOL_PLAYER_SUMMONER_F_USED",
      // "EVENT_LOL_PLAYER_PASSIVE_ACTIVATED",
    ],
    CSGO: [
      "EVENT_CSGO_ALLY_TEAM_SCORE",
      "EVENT_CSGO_BOMB_STATE",
      "EVENT_CSGO_ENEMY_TEAM_SCORE",
      "EVENT_CSGO_PLAYER_ARMOR",
      "EVENT_CSGO_PLAYER_ARMOR_TYPE",
      "EVENT_CSGO_PLAYER_BOMB_PLANTED",
      "EVENT_CSGO_PLAYER_GAME_STATE",
      "EVENT_CSGO_PLAYER_HAS_THE_BOMB",
      "EVENT_CSGO_PLAYER_KILL",
      "EVENT_CSGO_PLAYER_MONEY",
      "EVENT_CSGO_PLAYER_TEAM",
      "EVENT_CSGO_ROUND_TIME",
    ],
    VALORANT: [
      "EVENT_VALORANT_KILLFEED",
      "HP_STATUS",
      "AMMO_STATUS",
      "EVENT_VALORANT_ALLY_ABILITY_KILL",
      "EVENT_VALORANT_ALLY_DEATH",
      "EVENT_VALORANT_ALLY_HEADSHOT_KILL",
      "EVENT_VALORANT_ALLY_KILL",
      "EVENT_VALORANT_ALLY_RESURRECTION",
      "EVENT_VALORANT_ALLY_WALLBANG_KILL",
      "EVENT_VALORANT_ENEMY_ABILITY_KILL",
      "EVENT_VALORANT_ENEMY_DEATH",
      "EVENT_VALORANT_ENEMY_HEADSHOT_KILL",
      "EVENT_VALORANT_ENEMY_KILL",
      "EVENT_VALORANT_ENEMY_RESURRECTION",
      "EVENT_VALORANT_ENEMY_WALLBANG_KILL",
      "EVENT_VALORANT_PLAYER_ABILITY_KILL",
      "EVENT_VALORANT_PLAYER_DEATH",
      "EVENT_VALORANT_PLAYER_HEADSHOT_KILL",
      "EVENT_VALORANT_PLAYER_KILL",
      "EVENT_VALORANT_PLAYER_RESURRECTION",
      "EVENT_VALORANT_PLAYER_SPECTATOR_STATUS",
      "EVENT_VALORANT_PLAYER_WALLBANG_KILL",
      "EVENT_VALORANT_SPIKE_PLANT",
      "EVENT_VALORANT_SPIKE_DEFUSE"
    ],
    APEX: [
      "EVENT_APEX_PLAYER_ASSIST",
      "EVENT_APEX_PLAYER_CRAFT_POINTS",
      "EVENT_APEX_PLAYER_DAMAGE_DONE",
      "EVENT_APEX_PLAYER_DAMAGE_TAKEN",
      "EVENT_APEX_PLAYER_DEATH",
      "EVENT_APEX_PLAYER_HELMET_COLOR",
      "EVENT_APEX_PLAYER_KILL",
      "EVENT_APEX_PLAYER_KNOCKED_DOWN",
      "EVENT_APEX_PLAYER_RANK_POINTS",
      "EVENT_APEX_PLAYER_RESPAWNED",
      "EVENT_APEX_PLAYER_REVIVED",
      "EVENT_APEX_PLAYER_SHIELD_COLOR",
      "EVENT_APEX_PLAYER_SHIELD_VALUE",
      "EVENT_APEX_PLAYER_SPECTATE",
      "EVENT_APEX_RING_SIZE",
      // "EVENT_APEX_COMBAT_ENDED",
      // "EVENT_APEX_COMBAT_STARTED",
    ]
  }
}

export const subscription = {
  labels: {
		titleStart: "usersubscription_title_start",
		titleEnd: "usersubscription_title_end",
		subtitle: "usersubscription_subtitle",
		frequentlyAskedQuestions: "usersubscription_frequently_asked_questions",
		additionalQuestions: "usersubscription_additional_questions_title",
		contactUsLinkText: "usersubscription_contact_us",
		contactUsAdditionalText: "usersubscription_contact_us_additional",
		oneTimePurchases: "usersubscription_one_time_purchases",
		oneTimePurchasesDescription: "usersubscription_one_time_purchases_description",
		upgradeNowCta: "usersubscription_upgrade_now_cta",
		downgradeCta: "usersubscription_downgrade_cta",
		buyNowCta: "usersubscription_buy_now_cta",
		included: "usersubscription_card_included",
		recommended: "usersubscription_card_recommended",
		manageSubscription: "usersubscription_manage",
		howToCancel: "usersubscription_how_to_cancel",
  },
	faqContent: [
		{
			title: "usersubscription_faq_what_forms_of_payment_title",
			content: "usersubscription_faq_what_forms_of_payment_text"
		},
		{
			title: "usersubscription_faq_how_to_manage_subscription_title",
			content: "usersubscription_faq_how_to_manage_subscription_text"
		},
		{
			title: "usersubscription_faq_how_to_downgrade_title",
			content: "usersubscription_faq_how_to_downgrade_text"
		},
		{
			title: "usersubscription_faq_what_happens_when_upgrade_title",
			content: "usersubscription_faq_what_happens_when_upgrade_text"
		},
		{
			title: "usersubscription_faq_can_i_get_a_refund_title",
			content: "usersubscription_faq_can_i_get_a_refund_text"
		},
		{
			title: "usersubscription_faq_is_my_data_secure_title",
			content: "usersubscription_faq_is_my_data_secure_text"
		},
		{
			title: "usersubscription_faq_how_do_i_get_started_title",
			content: "usersubscription_faq_how_do_i_get_started_text"
		},
	],
}

export const sharing = {
  resultLimit: 100,
  labels: {
    title: "sharing_title",
    title_info: "sharing_title_info",
    data_load: "sharing_data_load",
    members_separator: "sharing_members_separator",
    matches_separator: "sharing_matches_separator",
    filter_video_placeholder: "sharing_filter_video_placeholder",
    filter_user_placeholder: "sharing_filter_user_placeholder",
    filter_character_placeholder: "sharing_filter_character_placeholder",
    search_button: "sharing_search_button",
    filter_button: "sharing_filter_button",
    filter_all: "sharing_filter_all",
    filter_date_month: "sharing_filter_date_month",
    filter_date_half: "sharing_filter_date_half",
    filter_date_year: "sharing_filter_date_year",
    filter_result_victory: "sharing_filter_result_victory",
    filter_result_draw: "sharing_filter_result_draw",
    filter_result_defeat: "sharing_filter_result_defeat",
    orderby_title: "sharing_orderby_title",
    anonymus_user: "sharing_anonymus_user"
  },
  backgrounds: {
    [Games.LOL]: [
      '/config/brands/default/backgrounds/lol-bg-1.png',
      '/config/brands/default/backgrounds/lol-bg-2.png',
      '/config/brands/default/backgrounds/lol-bg-3.png',
    ],
    [Games.APEX]: [
      '/config/brands/default/backgrounds/apex-bg-1.png',
      '/config/brands/default/backgrounds/apex-bg-2.png',
      '/config/brands/default/backgrounds/apex-bg-3.png',
    ],
    [Games.VALORANT]: [
      '/config/brands/default/backgrounds/valorant-bg-1.png',
      '/config/brands/default/backgrounds/valorant-bg-2.png',
      '/config/brands/default/backgrounds/valorant-bg-3.png',
    ],
    [Games.CSGO]: [
      '/config/brands/default/backgrounds/csgo-bg-1.png',
      '/config/brands/default/backgrounds/csgo-bg-2.png',
      '/config/brands/default/backgrounds/csgo-bg-3.png',
    ]
  },
  intro : {
		steps: [
			{
				id: "sharing_intro_greeting",
				title: "sharing_intro_greeting_title",
				intro: "sharing_intro_greeting_intro"
			},
			{
				id: "sharing_intro_video_filter",
				title: "sharing_intro_video_filter_title",
				element: "#video_filter",
				intro: "sharing_intro_video_filter_intro"
			},
      {
				id: "sharing_intro_filter_row_visible",
				title: "sharing_intro_filter_row_visible_title",
				element: "#filter_row_visible",
				intro: "sharing_intro_filter_row_visible_intro"
			},
      {
				id: "sharing_intro_match_cards",
				title: "sharing_intro_match_cards_title",
				element: "#match_cards",
				intro: "sharing_intro_match_cards_intro"
			},
		],
		filters: {
      sharing_start: ["sharing_intro_greeting","sharing_intro_video_filter","sharing_intro_filter_row_visible","sharing_intro_match_cards"]
    },
	},
}