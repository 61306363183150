import React, { createElement, Component, Suspense, Fragment } from 'react'
import { ThemeProvider } from 'styled-components';
import { Helmet } from "react-helmet";
import { ToastContainer } from 'react-toastify';

// Import CSS reset and Global Styles
import 'sanitize.css/sanitize.css';
import 'styles/global.css';

import { AppProvider } from './context'

import Spinner from 'components/Spinner'
import Fallback from 'components/Fallback'
import Menu from 'containers/Menu'
import modules from './modules'
import { GlobalStyle } from 'shared/global';
import { RESTRICTIONS } from 'config/enums';

export class App extends Component {

  componentDidMount() {
    /* istanbul ignore next */
    if(process.env.NODE_ENV !== 'production'){
      import('remotedev-react-state').then(({ default: connectToDevTools }) => {
        if(typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__){
          connectToDevTools(this, {name: "App"})
        }
      })  
    }
    window.addEventListener("scroll", this.onScroll.bind(this), {passive: true});
    window.addEventListener('resize', this.onResize.bind(this), {passive: true})
    const [ current, params ] = this.getPath()
    if(current === "hash" && params.access_token){
      this.setHashToken(params)
    } else if(current === "search" && params.code){
      this.setCodeToken(params)
    } else if(current === "public"){
      this.setPublic(params)
    } else {
      this.loadConfig()
      this.onResize()
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll.bind(this));
    window.removeEventListener("resize", this.onScroll.bind(this));
  }

  render() {
    const Routers = (cModule) => {
      return (
        <Fragment>
          {(!this.state.session.restrictions.includes(RESTRICTIONS.MENU))?<Menu />:null}
          {createElement(modules[cModule], { name: cModule })}
          {(this.state.current.modal)?this.state.current.modal:null}
        </Fragment>
      )
    }
    const getAuth = (cModule) => {
      // Handle non-graceful errors
      if(this.state.session.error) {
        return <Fallback />
      }

      // IF No auth data from backend OR
      //    When the user is logged in but still waiting for auth data to populate to state
      // THEN just show the loading indicator
      /*
      if(!this.state.session.provider.auth
        || ((localStorage.getItem("isLoggedIn") === "true") && !this.state.session.user && ((!this.state.session.data || (this.state.session.data && !this.state.session.data.user))) )
      ) {
        return <Spinner />
      }
      */

      // IF the user is logged in and has a valid state
      // THEN show the requested view
      if(this.state.session.data && this.state.session.data.user) {
        if(this.state.current.protect.includes(cModule) && (this.actions.userRole() === "USER")) {
          return Routers(this.state.current.home)
        }
        return Routers(cModule)
      }

      // IF the user is logged in, has a valid state,
      if(this.state.session.user && this.state.session.user.emailVerified) {
        return createElement(modules["signup"])
      }

      // In all other cases, just show the login page
      return createElement(modules["login"])
    }
    const protector = () => {
      let cModule = this.state.current.module
      if(cModule === "fallback"){
        return <Fallback />
      }
      return getAuth(cModule)
    }
    return (
      <ThemeProvider theme={{
        ...this.state.session.provider.themes[this.state.session.provider.theme],
        ...this.state.session.provider.brandTheme
        }}>
        <GlobalStyle />
        <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:url" content={window.location} />
          <meta property="og:type" content="article" />
        </Helmet>
          {(this.state.session.provider.head && this.state.session.provider.head.title)?
          <Helmet>
          <title>{this.state.session.provider.head.title}</title>
          <meta property="og:title" content={this.state.session.provider.head.title} />
          <meta property="og:site_name" content={this.state.session.provider.head.title} />
          </Helmet>
        :null}
          {(this.state.session.provider.head && this.state.session.provider.head.icon)?
          <Helmet>
          <link rel="shortcut icon" href={this.state.session.provider.head.icon} />
          </Helmet>
        :null}
        {(this.state.session.provider.private && this.state.session.provider.private.game)?
          <Helmet>
          <meta property="og:image" content={this.actions.getOGShareImage(this.state.session.provider.private.game)} />
          </Helmet>
        :null}
          {(this.state.session.provider.head && this.state.session.provider.head.description)?
        <Helmet>
          <meta name="description" content={this.state.session.provider.head.description} /> 
          <meta property="og:description" content={this.state.session.provider.head.description} />
        </Helmet>
        : null}
        <Helmet>
          {this.state.tagManager && this.state.tagManager.injectHeadCode()}
        </Helmet>
        </>
        <AppProvider value={{ data: this.state, actions: this.actions }}>
          {this.state.tagManager && this.state.tagManager.injectBodyCode()}      
          <Suspense fallback={<Spinner />}>
            {(!this.state.session.data && this.state.request) ? null : protector()}
          </Suspense>
          {(this.state.request)?<Spinner />:null}
          <ToastContainer />
        </AppProvider>
      </ThemeProvider>
    );
  }
}

