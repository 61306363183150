/* istanbul ignore file */

import { css } from 'styled-components';
import { getRGBA } from 'shared/utils'

// https://github.com/usablica/intro.js/blob/master/src/styles/introjs.scss

export const IntroTheme = css`
  .introjs-disableInteraction {
    background-color: ${props => props.theme.colors.midPanelBackground}!important;
  }
  .introjs-tooltipReferenceLayer {
    font-family: ${props => props.theme.fonts.primary}!important;
    * {
      font-family: ${props => props.theme.fonts.primary}!important;
    }
  }
  .introjs-helperNumberLayer {
    font-family: ${props => props.theme.fonts.primary}!important;
    color: ${props => props.theme.colors.textSecondary}!important;
  }
  .introjs-arrow.left {
    border-right-color: ${props => props.theme.colors.midPanelBackground}!important;
  }
  .introjs-arrow.left-bottom {
    border-right-color: ${props => props.theme.colors.midPanelBackground}!important;
  }
  .introjs-arrow.top {
    border-bottom-color: ${props => props.theme.colors.midPanelBackground}!important;
  }
  .introjs-arrow.top-right {
    border-bottom-color: ${props => props.theme.colors.midPanelBackground}!important;
  }
  .introjs-arrow.top-middle {
    border-bottom-color: ${props => props.theme.colors.midPanelBackground}!important;
  }
  .introjs-arrow.right {
    border-left-color: ${props => props.theme.colors.midPanelBackground}!important;
  }
  .introjs-arrow.right-bottom {
    border-left-color: ${props => props.theme.colors.midPanelBackground}!important;
  }
  .introjs-arrow.bottom {
    border-top-color: ${props => props.theme.colors.midPanelBackground}!important;
  }
  .introjs-arrow.bottom-right {
    border-top-color: ${props => props.theme.colors.midPanelBackground}!important;
  }
  .introjs-arrow.bottom-middle {
    border-top-color: ${props => props.theme.colors.midPanelBackground}!important;
  }
  .introjs-tooltip {
    background-color: ${props => props.theme.colors.midPanelBackground}!important;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)!important;
    min-width: 300px!important;
  }
  .introjs-dontShowAgain label {
    font-size: ${props => props.theme.fonts.small}px!important;
    vertical-align: middle!important;
    background-color: ${props => props.theme.colors.midPanelBackground}!important;
    color: ${props => props.theme.colors.textSecondary}!important;
  }
  .introjs-dontShowAgain input {
    vertical-align: middle!important;
    width: unset!important;
    height: unset!important;
    margin-bottom: unset!important;
  }
  .introjs-tooltipbuttons {
    border-top: 1px solid ${props => props.theme.colors.borderColor}!important;
  }
  .introjs-button {
    font-size: ${props => props.theme.fonts.small}px!important;
    font-weight: bold!important;
    text-transform: uppercase!important;
    text-shadow: none!important;
    border: none;
    border-radius: ${props => props.theme.style.borderRadius.join("px ")}px!important;
    color: ${props => props.theme.colors.primaryButtonText}!important;
    background: linear-gradient(90deg, ${props => props.theme.colors.brandColor} 0%, ${props => props.theme.colors.brandColorHighlight} 50%, ${props => props.theme.colors.brandColor} 100%)!important;
    &:hover {
      border-color: none!important;
      background-color: none!important;
      color: ${props => props.theme.colors.textHighlight}!important;
    }
    &:focus {
      background-color: none!important;
      box-shadow: none!important;
      border: 0px solid transparent!important;
      color: ${props => props.theme.colors.primaryButtonText}!important;
    }
    &:active {
      background-color: none!important;
      border-color: none!important;
      color: ${props => props.theme.colors.primaryButtonText}!important;
    }
  }
  .introjs-skipbutton {
    color: ${props => props.theme.colors.borderColorBright}!important;
    &:hover,
    &:focus {
      color: ${props => props.theme.colors.textHighlight}!important;
    }
  }
  .introjs-disabled {
    display: none!important;
    color: ${props => getRGBA(props.theme.colors.textPrimary, 0.5)}!important;
    border-color: ${props => props.theme.colors.borderColor}!important;
    background-color: none!important;
    &:hover,
    &:focus {
      color: ${props => props.theme.colors.textSecondary}!important;
      border-color: ${props => props.theme.colors.borderColor}!important;
      background-color: none!important;
    }
  }
  .introjs-progress {
    background-color: ${props => props.theme.colors.textSecondary}!important;
  }
  .introjs-progressbar {
    background-color: ${props => props.theme.colors.darkPanelBackground}!important;
  }
  .introjs-hint-pulse {
    background-color: ${props => props.theme.colors.darkPanelBackground}!important;
  }
`