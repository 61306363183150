import update from 'immutability-helper';
import React, { useContext, useState, useEffect, Fragment } from 'react';
import { useLocation } from "wouter";

import AppStore from 'containers/App/context'
import Modules from 'containers/App/modules';
import SideMenu from 'components/Product/Organisms/SideMenu'
import { UserMeta } from 'config/enums';
import { capitalize } from 'shared/stringutils';
import { MenuBar, TopMenuInApp } from './Menu.styled';
import { MENU_STATE } from 'components/Product/Organisms/SideMenu/SideMenu';
import { Profile } from './UserProfile.js'
// import { SubscriptionModal } from './UserSubscription';
import TagEventTypes from 'config/tagEvents';

const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

const Menu = ({ lang }) => {
  const { data, actions } = useContext(AppStore);
  const [, setLocation] = useLocation();
  const [width, setWidth] = useState(getWidth());
  const showProfile = Profile()
  // const showSubscription = SubscriptionModal()

  useEffect(() => {
    if(getWidth() <= 600) {
      actions.setData("current", { menuState: MENU_STATE.HIDDEN })
    }
  }, [actions])

  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const currentWidth = getWidth()
        if (currentWidth <= 600 &&  width > 600.001) {
          actions.setData("current", { menuState: MENU_STATE.HIDDEN })
        }
        if (currentWidth >= 600.001 && width <= 600) {
          actions.setData("current", { menuState: MENU_STATE.EXPANDED })
        }
        setWidth(currentWidth)
      }, 150);
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [actions, width])

  const generateLogoUrl = (logoKey) => {
    let logoUrl = (data.session.provider.brands[data.session.provider.brand][logoKey]) ? data.session.provider.brands[data.session.provider.brand][logoKey] : null
    if (logoUrl) {
      let r = new RegExp('^(?:[a-z]+:)?//', 'i')
      logoUrl = r.test(logoUrl) ? logoUrl : window.location.origin + logoUrl
    }
    return logoUrl
  }

  const getMenuLabels = (lang) => {
    let labels = {}
    Object.keys(data.menu.labels).forEach(key => {
      labels[key] = actions.getText(data.menu.labels[key], undefined, lang)
    });
    return labels
  }

  const getLanguages = () => {
    return Object.values(data.session.data.enums.user_language).map(element => {
      return { value: element, label: element.split("_")[0].toUpperCase() }
    })
  }

  const getGames = (providerGames) => {
    return providerGames.map(element => {
      const gameTarget = element.gameTarget.toLowerCase()
      return {
        value: element.gameTarget,
        label: element.gameTarget, // actions.getText(`menu_provider_game_${gameTarget}`),
        icon: capitalize(gameTarget)
      }
    })
  }

  const userLanguage = data.session.data.user.meta[UserMeta.user_language] || data.session.provider.language
  const [state] = useState({
    mdKey: "current",
    logo: generateLogoUrl("logo"),
    logoWide: generateLogoUrl("logo_wide"),
    logoBackground: generateLogoUrl("logo_background"),
    menuItems: data.menu.user
      .filter(item => (!data.session.restrictions.includes(String(item.menuKey).toUpperCase())))
      .map(item => {
        return { ...item, 
      label: actions.getText("menu_" + item.menuKey, undefined, userLanguage) } }),
    adminMenuItems: data.menu.admin
      .filter(item => (!data.session.restrictions.includes(String(item.menuKey).toUpperCase())))
      .map(item => { 
        return { ...item, 
          label: actions.getText("menu_" + item.menuKey, undefined, userLanguage) } 
      }),
    isAdmin: (actions.userRole() !== "USER"),
    labels: getMenuLabels(userLanguage),
    brandTheme: data.session.provider.brandTheme,
    fixedWidth: data.session.provider.brandTheme.style.sideMenuWidth,
    fixedCollapsedWidth: data.session.provider.brandTheme.style.sideMenuCollapsedWidth,
    proMode: false,
    languages: getLanguages(),
    userLanguage: userLanguage,
    games: getGames(data.session.provider.pageSettings.menu.providerGames),
    game: data.session.provider.private.game,
    brand: data.session.provider.brands[data.session.provider.brand],
    helpUrl: data.session.provider.brands[data.session.provider.brand].help,
    showSubscription: data.session.data.info.shop_item_count > 0,
    tagManager: data.tagManager,
    restrictions: data.session.restrictions
  })

  state.pageSettings = data.session.provider.pageSettings.menu
  state.data = data[state.mdKey]
  state.notification = {
    sliderDisplayTimeInSec: state.pageSettings.notificationConfig.sliderDisplayTimeInSec,
    language: state.userLanguage,
    slides: state.pageSettings.notificationConfig.slides
  }
  state.customLinks = state.pageSettings.notificationConfig.links

  state.onMenuItemClicked = (key) => {
    state.tagManager.sendEvent(TagEventTypes.OMNICOACH_MENU_ITEM_CLICK, {menuKey: key})
    switch (key) {
      case "collapse":
        const sideIconView = !state.data.sideIconView
        actions.setData(state.mdKey, { sideIconView: sideIconView, menuState: sideIconView ? MENU_STATE.COLLAPSED : MENU_STATE.EXPANDED })
        localStorage.setItem("icon_view", sideIconView)
        break;

      case "showAdmin":
        actions.setData(state.mdKey, { showAdmin: !state.data.showAdmin })
        break;

      case "help":
        state.onHelpClicked()
        break;
      
      case "logout":
        state.onSignOutClicked()
        break;

      case "profile":
        state.onAvatarClicked()
        break;

      default:
        const moduleKey = (key === "home") ? state.data.home : key
        const moduleList = Object.keys(Modules)
        if (moduleList.includes(moduleKey)) {
          setLocation("/" + moduleKey)
          actions.setData(state.mdKey, { module: moduleKey, dropmenu: "", menu: "", params: [],  
            menuState: (state.data.menuState === MENU_STATE.MOBILE) ? MENU_STATE.HIDDEN : state.data.menuState })
        }
        break;
    }
  }

  /*
  state.onProModeClicked = () => {
    actions.setData(state.mdKey, { proMode: !state.data.proMode })
    state.tagManager.sendEvent(TagEventTypes.OMNICOACH_MENU_PRO_MODE_CLICK, {proMode: !state.data.proMode})
  }
  */
  state.onGameChanged = (value) => {
    const current = data.session.provider.pageSettings["menu"].providerGames.filter((element) => element.gameTarget === value)[0];
    state.tagManager.sendEvent(TagEventTypes.OMNICOACH_MENU_GAME_SWITCH_CLICK, {game: current})
    actions.openUrlWithAuth(current.url, "_self")
  }

  state.onAvatarClicked = () => {
    actions.setData(state.mdKey, { menu: "", dropmenu: "", 
      menuState: (state.data.menuState === MENU_STATE.MOBILE) ? MENU_STATE.HIDDEN : state.data.menuState }, () => {
      showProfile({
        languages: state.languages,
        language: state.userLanguage,
        onChange: (form) => {
          actions.setData("current", { modal: form })
        }, 
        updateUser: async (user, form) => {
          const meta = update(user.meta, {$unset: [UserMeta.user_subscription]})
          const options = {
            method: "PUT",
            core: true,
            data: update(meta, {$unset: ["match_riot_puuid"]})
          }
          const result = await actions.requestData("/user/me", options)
          if(result && result.error){
            return actions.setError(result.error)
          }
  
          const session_data = {
            ...data.session.data,
            user: {
              ...data.session.data.user,
              meta: user.meta
            }
          }
          /*
          const session_data = update(data.session.data, {user: {$merge: {
            meta: user.meta
          }}})
          */
          const updateLang = (user.meta.user_language 
            && (user.meta.user_language !== data.session.data.user.meta.user_language))
          actions.setData("session", { data: session_data }, ()=>{
            if(updateLang){
              window.location.reload()
            }
            actions.setData("current", { modal: form })
          })
        }
      })
    })
  }
  state.onSubscriptionButtonClicked = () => {
    state.onMenuItemClicked('subscription')
  }

  state.onLanguageChanged = (language) => {
    state.tagManager.sendEvent(TagEventTypes.OMNICOACH_MENU_CHANGE_LANGUAGE, {language})
    actions.changeUserLanguage(language)
  }
  state.onHelpClicked = () => {
    actions.help()
    if(state.data.menuState === MENU_STATE.MOBILE){
      actions.setData(state.mdKey, { menuState: MENU_STATE.HIDDEN })
    }
  }

  state.onSignOutClicked = () => {
    actions.signOut()
    setLocation("/")
  }
  state.onMobileMenuClicked = () => {
    actions.setData(state.mdKey, { showSideMenu: !state.data.showSideMenu, sideIconView: false,
      menuState: state.data.menuState !== MENU_STATE.MOBILE ? MENU_STATE.MOBILE : MENU_STATE.HIDDEN })
  }

  return (
    <Fragment>
      <MenuBar theme={state.brandTheme} menuState={state.data.menuState}>
        <TopMenuInApp id="topMenu"
          theme={state.brandTheme}
          restrictions={state.restrictions}
          menuState={state.data.menuState}
          logo={state.logoWide || state.logo}
          labels={state.labels}
          games={state.games}
          selectedGame={state.game}
          showProMode={state.pageSettings.show_pro_mode}
          proMode={state.data.proMode}
          languages={state.languages}
          selectedLanguage={state.userLanguage}
          showHelp={state.helpUrl !== undefined}
          onHelpClicked={state.onHelpClicked}
          onGameChanged={state.onGameChanged}
          onAvatarClicked={state.onAvatarClicked}
          onLanguageChanged={state.onLanguageChanged}
          onSignOutClicked={state.onSignOutClicked}
          onMobileMenuClicked={state.onMobileMenuClicked}
          isMobileMenuOpen={state.data.menuState === MENU_STATE.MOBILE}
          showSubscription={state.showSubscription}
          onSubscriptionButtonClicked={state.onSubscriptionButtonClicked}
        />
      </MenuBar>
      <SideMenu id="sideMenu"
        logoSmall={state.logo}
        logoWide={state.logoWide}
        logoBackground={state.logoBackground}
        menuState={state.data.menuState}
        menuItems={state.menuItems}
        adminMenuItems={state.adminMenuItems}
        activeMenuItem={state.data.module}
        restrictions={state.restrictions}
        showAdmin={state.data.showAdmin}
        showHelp={state.helpUrl !== undefined}
        labels={state.labels}
        theme={state.brandTheme}
        uploadProgress={(state.data.indicatorEvent) ? {
          theme: state.brandTheme,
          state: state.data.indicatorEvent.type,
          value: state.data.indicatorEvent.value,
          labels: {...state.labels},
          minutesLeft: state.data.indicatorEvent.secondsRemaining !== undefined ?
            Math.round(state.data.indicatorEvent.secondsRemaining / 60) :
            undefined
        } : null}
        notificationSlider={{
          ...state.notification,
          iconView: state.data.menuState === MENU_STATE.COLLAPSED,
          theme: state.brandTheme
        }}
        language={state.userLanguage}
        customLinks={state.customLinks}
        fixedWidth={state.fixedWidth}
        fixedCollapsedWidth={state.fixedCollapsedWidth}
        onMenuItemClicked={state.onMenuItemClicked}
      />
    </Fragment>
  )
}

export default Menu;