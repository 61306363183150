export const fonts_product = {
  primary: "Outfit",
  secondary: "Supria Sans",
  tiny: 10,
  tinyLineHeight: 1,
  small: 12,
  smallLineHeight: 1.5,
  normal: 14,
  normalLineHeight: 1.5,
  large: 16,
  largeLineHeight: 1.5,
  xlarge: 18,
  xlargeLineHeight: 1.2,
  xxlarge: 26,
  xxlargeLineHeight: 1.2,
  xxxlarge: 32,
  xxxlargeLineHeight: 1.2,
  jumbo: 36,
  jumboLineHeight: 1.2,
  boldWeight: 800,
  nearBoldWeight: 700,
}

export const baseStyle_product = {
  iconSize: 18,
  iconSizeSmall: 12,
  iconSizeLarge: 25,
  inputHeight: 38,
  inputHeightSmall: 26,
  borderRadius: [4],
  borderRadiusVertical: [4, 0, 0, 4],
  borderRadiusTopOnly: [4, 4, 0, 0],
  borderRadiusBottomOnly: [0, 0, 4, 4],
  paddingButtonSmallVertical: [4],
  paddingButtonNormalVertical: [10],
  paddingButtonIcon: [0, 8],
  border: "1px solid",
  paddingSmall: [0, 8],
  paddingNormal: [0, 16],
  paddingRow: [16, 8],
  paddingLarge: [0, 30],
  paddingAllSmall: [8],
  paddingTableHeader: [8, 16],
  paddingTableCell: [6, 8],
  paddingTableFilterCell: [8, 4],
  transitionTime: 0.2,
  sideMenuWidth: 220,
  sideMenuCollapsedWidth: 74,
  pageMaxWidth: 1100,
  topMenuHeight: 56,
}

export const colors_product = {
  brandColor: "#346AF2",
  brandColorHighlight: "#5787FF",
  brandColorText: "#FFFFFF",
  brandColorLinkHover: "#5686FF",
  textPrimary: "#FFFFFF",
  textSecondary: "#8B94BA",
  secondaryShine: "radial-gradient(50% 50% at 50% 50%, #FED076 0%, rgba(36, 44, 66, 0) 100%)",
  thirdShine: "radial-gradient(50% 50% at 50% 50%, #9858FF 0%, rgba(36, 44, 66, 0) 100%)",
  secondaryBackground: "linear-gradient(90deg, #FFD540 0%, #FBF059 50%, #FFD540 100%)",
  secondaryBackgroundShadow: "rgb(254 208 118)",
  thirdBackground: "linear-gradient(90deg, #7868E8 0%, #957CFF 50%, #7868E8 100%)",
  thirdBackgroundShadow: "#957CFF",
  fourthBackground: "#FF5876",
  menuBackground: "radial-gradient(68.54% 15.08% at 0% 0%, rgba(91, 137, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(100% 50% at 100% 50%, #2A344B 0%, #21293F 100%)",
  menuActiveShine: "radial-gradient(19.79% 52.78% at 0% 50%, rgba(91, 137, 255, 0.2) 0%, rgba(91, 137, 255, 0.2) 0.01%, rgba(0, 0, 0, 0) 100%)",
  pageBackground: "linear-gradient(180deg, rgba(91, 137, 255, 0.2) 0%, rgba(0, 0, 0, 0) 900px)",
  bodyBackground: "#000",
  secondaryButtonText: "#D07F33",
  borderColor: "#4C526F",
  borderColorBright: "#8B94BA",
  borderColorError: "#FF5876",
  placeholderColor: "#8B94BA",
  midPanelBackground: "#30364F",
  popupBackground: "#1E2436",
  background: "#1E2436",
  textHighlight: "#FED076",
  textHighlightShadow: "#EF7937",
  darkPanelBackground: "#21293F",
  labelEnemyText: "#FF7676",
  labelEnemyBorder: "#FF2B5E",
  labelEnemyShine: "drop-shadow(0px 5px 10px #FF2B5E)",
  labelAllyBorder: "#2B7FFF",
  labelAllyText: "#58AFFF",
  labelAllyShine: "drop-shadow(0px 5px 10px #2B7FFF)",
  labelPlayerBorder: "#FED076",
  labelPlayerText: "#FED076",
  labelPlayerShine: "drop-shadow(0px 5px 10px #FED076)",
  labelEasyBorder: "#2BFFBF",
  labelEasyText: "#58FFE1",
  labelEasyShine: "drop-shadow(0px 5px 10px #2BFFBF)",
  labelMediumBorder: "#2B7FFF",
  labelMediumText: "#58AFFF",
  labelMediumShine: "drop-shadow(0px 5px 10px #2B7FFF)",
  labelHardBorder: "#FF6A2B",
  labelHardText: "#FF9E58",
  labelHardShine: "drop-shadow(0px 5px 10px #FF6A2B)",
  labelInsaneBorder: "#FF2B5E",
  labelInsaneText: "#FF7676",
  labelInsaneShine: "drop-shadow(0px 5px 10px #FF2B5E)",
  labelIndividualBorder: "#FED076",
  labelIndividualText: "#FED076",
  labelIndividualShine: "drop-shadow(0px 4px 20px #FED076)",
  labelTeamBorder: "#2BD9FF",
  labelTeamText: "#2BD9FF",
  labelTeamShine: "drop-shadow(0px 4px 10px #2B7FFF)",
  labelTag1Border: "#96AAFF",
  labelTag1Text: "#96AAFF",
  labelTag1Background: "#3B498E",
  labelTag2Border: "#FED076",
  labelTag2Text: "#FED076",
  labelTag2Background: "#695327",
  labelTag3Border: "#FE7676",
  labelTag3Text: "#FE7676",
  labelTag3Background: "#713F3F",
  labelTag4Border: "#EB67EE",
  labelTag4Text: "#EB67EE",
  labelTag4Background: "#563758",
  labelTag5Border: "#6CEBD4",
  labelTag5Text: "#6CEBD4",
  labelTag5Background: "#376D6A",
  labelTag6Border: "#E890A5",
  labelTag6Text: "#E890A5",
  labelTag6Background: "#87343E",
  labelTag7Border: "#FFFFFF",
  labelTag7Text: "#FFFFFF",
  labelTag7Background: "#484C57",
  labelTag8Border: "#6CEBD4",
  labelTag8Text: "#6CEBD4",
  labelTag8Background: "#376D6A",
  labelTag9Border: "#B69DFF",
  labelTag9Text: "#B69DFF",
  labelTag9Background: "#593EA6",
  labelTag10Border: "#8B94BA",
  labelTag10Text: "#8B94BA",
  labelTag10Background: "#21293F",
  darkestPanelBackground: "#111729",
  progressBarBorder: "#FFF9C6",
  uploadingColor: "#A48FFF",
  analyzingColor: "#8BBAA9",
  errorColor: "#FF5876",
  victoryBorderColor: "#59F4AA",
  defeatBorderColor: "#E86988",
  drawBorderColor: "#695327",
  avatarBorder: "#FFD540",
  iconActiveColor: "#FFFFFF",
  statCardHighlight: "#FED849",
  toplistFirst: "#FED076",
  toplistSecond: "#CCD2EC",
  toplistThird: "#D4A18B",
  victoryRow: "linear-gradient(90deg, rgba(89, 244, 170, 0.2) 0%, rgba(36, 43, 62, 0) 80%);",
  defeatRow: "linear-gradient(90deg, rgba(202, 104, 128, 0.5) 0%, rgba(36, 43, 62, 0) 80%);",
  drawRow: "linear-gradient(90deg, #695327 0%, rgba(36, 43, 62, 0) 80%);",
  menuBorder: "#3C4157",
  topMenuBackground: "rgba(64, 77, 112, 0.2)",
  midDarkPanel: "#272F46",
  primaryButtonText: "#FFFFFF",
  thirdButtonText: "#FFFFFF",
  fourthButtonText: "#FFFFFF",
}

export const appTheme = {
  colors: colors_product,
  style: baseStyle_product,
  fonts: fonts_product
}

export const brandTheme = {
  brandColor: colors_product.brandColor,
  textColor: colors_product.brandColorText,
  textHighlight: colors_product.brandColorHighlight,
  linkHover: colors_product.brandColorLinkHover,
  menuBackground: colors_product.menuBackground,
  menuActiveShine: colors_product.menuActiveShine,
  pageBackground: colors_product.pageBackground,
}

export const fonts = {
  primary: "Rubik",
  secondary: "Fiba",
  tiny: 10,
  small: 12,
  normal: 15,
  large: 18,
  xlarge: 24,
  xxlarge: 32,
  xxxlarge: 48,
  jumbo: 64
}

export const baseStyle = {
  iconSize: 18,
  borderRadius: "4px",
  borderRadiusVertical: "4px 0px 0px 4px",
  paddingSmall: "padding: 4px 8px; @media (max-width:600px){ padding: 2px 4px; };",
  paddingSmallVertical: "padding: 4px; @media (max-width:600px){ padding: 2px; };",
  paddingNormal: "padding: 10px 30px; @media (max-width:600px){ padding: 5px 15px; };",
  paddingNormalVertical: "padding: 10px; @media (max-width:600px){ padding: 5px; };",
}

export const colors = {
  brand_color: "#346AF2",
  brand_linear_color: "#5787FF",
  //button_primary: "linear-gradient(90deg, #346AF2 -0.31%, #5787FF 51.26%, #346AF2 99.69%)",
  button_quest_linear: "linear-gradient(90deg, #FFD540 -0.31%, #FBF059 51.26%, #FFD540 99.69%)",
  button_quest_radial: "radial-gradient(50% 50% at 50% 50%, #FED076 0%, rgba(36, 44, 66, 0) 100%)",
  button_uploading_linear: "linear-gradient(90deg, #7868E8 -0.31%, #957CFF 51.26%, #7868E8 99.69%)",
  button_uploading_radial: "radial-gradient(50% 50% at 50% 50%, #9858FF 0%, rgba(36, 44, 66, 0) 100%)",
  difficulty_easy_stroke: "#2BFFBF",
  difficulty_easy_text: "#58FFE1",
  difficulty_hard_stroke: "#FF6A2B",
  difficulty_hard_text: "#FF9E58",
  difficulty_insane_stroke: "#FF2B5E",
  difficulty_insane_text: "#FF7676",
  difficulty_medium_stroke: "#2B7FFF",
  difficulty_medium_text: "#58AFFF",
  icon_primary: "#FFFFFF",
  icon_secondary: "#8B94BA",
  panel_ally_stroke: "#699CE8",
  panel_background: "#1E2436",
  panel_dark: "#21293F",
  panel_darkest: "#111729",
  panel_defeat: "linear-gradient(90deg, rgba(202, 104, 128, 0.5) 0%, rgba(36, 43, 62, 0) 100%)",
  panel_defeat_stroke: "#E86988",
  panel_header: "#404D7033",
  panel_highlight: "#FED076",
  panel_mid: "#30364F",
  panel_stroke_30: "#8B94BA4D",
  panel_stroke: "#8B94BA",
  panel_team_border: "#2BD9FF",
  panel_victory: "linear-gradient(90deg, rgba(89, 244, 170, 0.2) 0%, rgba(36, 43, 62, 0) 100%)",
  panel_victory_stroke: "#59F4AA",
  progress_border: "#FFF9C6",
  progress_linear: "linear-gradient(90deg, #FBF059 0%, #FFD540 100%)",
  sidebar_border: "#3C4157",
  tag_1_panel: "#3B498E",
  tag_1_text: "#96AAFF",
  tag_2_panel: "#695327",
  tag_2_text: "#FED076",
  tag_3_panel: "#713F3F",
  tag_3_text: "#FE7676",
  tag_4_panel: "#563758",
  tag_4_text: "#EB67EE",
  tag_5_panel: "#376D6A",
  tag_5_text: "#6CEBD4",
  tag_6_panel: "#87343E",
  tag_6_text: "#E890A5",
  tag_7_panel: "#484C57",
  tag_7_text: "#FFFFFF",
  tag_8_panel: "#376D6A",
  tag_8_text: "#6CEBD4",
  tag_9_panel: "#593EA6",
  tag_9_text: "#A282FF",
  text_alert: "#FF5876",
  text_alert_shadow: "rgba(255, 88, 118, 0.25)",
  text_good: "#2BFFBF",
  text_highlighted: "#FED076",
  text_highlighted_shadow: "#EF7937",
  text_poor: "#FF5876",
  textPrimary: "#FFFFFF",
  text_quest: "#D07F33",
  text_recording: "#FF5876",
  text_secondary: "#8B94BA",
  text_team: "#2BD9FF",
}
