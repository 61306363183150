import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button, { BUTTON_STYLE } from 'components/Product/Atoms/Button/Button'
import Checkbox from 'components/Product/Atoms/Checkbox'
import Text, { TEXT_STYLES } from 'components/Product/Atoms/Text/Text'
import TextInput from 'components/Product/Atoms/TextInput/TextInput'
import TextAnchor from 'components/Product/Atoms/TextAnchor'
import { capitalize, validateEmail } from 'shared/stringutils'
import { LoginProviders } from 'config/enums'

import { appTheme } from "config/theme"

import { AuthFormContainer, ButtonContainer, EulaContainer } from './AuthForm.styled'


export const AuthType = {
	LOGIN: "Login",
	REGISTER: "Register",
}

const validatePassword = (val) => {
	if (val.length > 5) {
		return true
	}
	return false;
}

export const AuthForm = ({
	id, labels, authType, currentEmail, loginProviders, allowSignUp,
	termsAndConditionsUrl, privacyPolicyUrl,
	onSubmit, onSubmitOAuth, onPasswordReset,
	...props
}) => {
	const [authState, setAuthState] = useState(authType)
	const [email, setEmail] = useState(currentEmail)
	const [isEmailValid, setIsEmailValid] = useState(false)
	const [password, setPassword] = useState("")
	const [isPasswordValid, setIsPasswordValid] = useState(false)
	const [passwordAgain, setPasswordAgain] = useState("")
	const [eula, setEula] = useState(false)

	useEffect(() => {
		setIsEmailValid(validateEmail(email))
	}, [email]);

	useEffect(() => {
		setAuthState(authType)
	}, [authType]);

	const onSubmitForm = () => {
		onSubmit(authState, email, password, passwordAgain, eula)
	}
	const onSubmitOAuthForm = (oAuthName) => {
		onSubmitOAuth(authState, oAuthName)
	}

	const hasUserPassLogin = loginProviders.indexOf(LoginProviders.PASSWORD) !== -1

	return (
		<AuthFormContainer id={id} value={authState} {...props}>
			<div>
				<TextAnchor id={id + "_login"} textStyle={TEXT_STYLES.TITLE_EXERCISE} theme={props.theme}
					active={authState === AuthType.LOGIN}
					onClick={authState !== AuthType.LOGIN ? () => setAuthState(AuthType.LOGIN) : undefined}
					color={props.theme.colors.textSecondary}>
					{labels.login}
				</TextAnchor>
				{allowSignUp && <>
					<Text theme={props.theme} textStyle={TEXT_STYLES.TITLE_EXERCISE} padding={[0, 8]}>/</Text>
					<TextAnchor id={id + "_register"} textStyle={TEXT_STYLES.TITLE_EXERCISE} theme={props.theme}
						active={authState === AuthType.REGISTER}
						onClick={authState !== AuthType.REGISTER ? () => setAuthState(AuthType.REGISTER) : undefined}
						color={props.theme.colors.textSecondary}>
						{labels.sign_up}
					</TextAnchor>
				</>}
			</div>
			<ButtonContainer>
				{loginProviders && loginProviders.filter(element => element !== LoginProviders.PASSWORD).map((element, index) => {
					const providerName = capitalize(element);
					return <Button id={`${id}_${element.toLowerCase()}_submit_oauth`} wide key={index} theme={props.theme}
						buttonStyle={BUTTON_STYLE.OAUTH} icon={`${element}Colored`} showIcon
						label={`${authState === AuthType.LOGIN ? labels.login : labels.sign_up} ${labels.with} ${providerName}`}
						onClick={() => onSubmitOAuthForm(element)}
						disabled={authState === AuthType.REGISTER ? !eula : undefined} />
				})}
			</ButtonContainer>
			{authState === AuthType.REGISTER &&
				<EulaContainer id={`${id}_eula`} authState={authState} onClick={() => setEula(!eula)}>
					<Checkbox theme={props.theme} checked={eula} readOnly required={authState === AuthType.REGISTER}
						error={!eula} />
					<Text theme={props.theme} textStyle={TEXT_STYLES.TEXT_SMALL} margin={[0, 0, 0, 8]}>
						{labels.eula}
						{" "}
						<TextAnchor id={`${id}_sign_up_terms_link`} theme={props.theme} textStyle={TEXT_STYLES.TEXT_SMALL} underline href={termsAndConditionsUrl} target="_blank" >
							{labels.terms_and_conditions}
						</TextAnchor>
						{" & "}
						<TextAnchor id={`${id}_sign_up_privacy_link`} theme={props.theme} textStyle={TEXT_STYLES.TEXT_SMALL} underline href={privacyPolicyUrl} target="_blank" >
							{labels.privacy_policy}
						</TextAnchor>
						{"."}
					</Text>
				</EulaContainer>}
			{hasUserPassLogin && (<>
				<Text theme={props.theme} textStyle={TEXT_STYLES.TEXT_FORM_LABEL} block htmlFor="email">
					{labels.email}
					<TextInput id={`${id}_email`} theme={props.theme} type="email" value={email} placeholder={labels.email_placeholder} required style={{ marginTop: "6px" }}
						onChange={(val) => setEmail(val)}
						error={!isEmailValid} />
				</Text>
				<Text theme={props.theme} textStyle={TEXT_STYLES.TEXT_FORM_LABEL} block htmlFor="password">
					{labels.password}
					<TextInput id={`${id}_password`} theme={props.theme} type="password" title={labels.password_help} value={password} placeholder={"**********"} required style={{ marginTop: "6px" }}
						onChange={(val) => setPassword(() => {
							setIsPasswordValid(validatePassword(val))
							return val
						})} onEnter={()=>{
							if((authState === AuthType.LOGIN) && isEmailValid && isPasswordValid){
								onSubmitForm()
							}
						}}
						error={!isPasswordValid} />
				</Text>
				{authState === AuthType.LOGIN &&
				<TextAnchor id={`${id}_forgot_password`} underline theme={props.theme}
					disabled={!isEmailValid} title={!isEmailValid ? labels.input_proper_email : undefined}
					onClick={isEmailValid ? () => onPasswordReset(email) : undefined}
					style={{ width: "100%", textAlign: "right" }}
				>
					{labels.forgot_password}
				</TextAnchor>
				}
				{authState === AuthType.REGISTER &&
					<Text theme={props.theme} textStyle={TEXT_STYLES.TEXT_FORM_LABEL} block animated htmlFor="password_again" authState={authState}>
						{labels.password_again}
						<TextInput id={`${id}_password_again`} theme={props.theme} type="password" value={passwordAgain} placeholder={"**********"} style={{ marginTop: "6px" }}
							required={authState === AuthType.REGISTER}
							onChange={(val) => setPasswordAgain(val)}
							error={!passwordAgain || passwordAgain !== password} />
					</Text>}
				<ButtonContainer>
					<Button id={`${id}_submit`} wide theme={props.theme}
						label={(authState === AuthType.LOGIN) ? labels.login : labels.sign_up}
						onClick={onSubmitForm}
						disabled={!isEmailValid || !isPasswordValid || (authState === AuthType.REGISTER && (!eula || passwordAgain !== password))} />
				</ButtonContainer>
			</>)}
			<div style={{ textAlign: "center" }}>
				{(authState === AuthType.LOGIN && allowSignUp) && <Text theme={props.theme} block margin={[6, 0]}>
					{labels.dont_have_an_account}
					<TextAnchor id={`${id}_register_bottom`} theme={props.theme} onClick={() => setAuthState(AuthType.REGISTER)} margin={[0, 0, 0, 6]} underline>
						{labels.sign_up}
					</TextAnchor>
				</Text>}
				{(authState === AuthType.REGISTER) && <Text theme={props.theme} block margin={[6, 0]}>
					{labels.already_have_an_account}
					<TextAnchor id={`${id}_login_bottom`} theme={props.theme} onClick={() => setAuthState(AuthType.LOGIN)} margin={[0, 0, 0, 6]} underline>
						{labels.login}
					</TextAnchor>
				</Text>}
			</div>
		</AuthFormContainer>
	)
}

AuthForm.propTypes = {
	id: PropTypes.string,
	currentEmail: PropTypes.string,
	labels: PropTypes.shape({
		login: PropTypes.string.isRequired,
		sign_up: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		email_placeholder: PropTypes.string.isRequired,
		password: PropTypes.string.isRequired,
		password_again: PropTypes.string.isRequired,
		eula: PropTypes.string.isRequired,
		dont_have_an_account: PropTypes.string.isRequired,
		already_have_an_account: PropTypes.string.isRequired,
		forgot_password: PropTypes.string.isRequired,
		reset_password: PropTypes.string.isRequired,
		terms_and_conditions: PropTypes.string.isRequired,
		privacy_policy: PropTypes.string.isRequired,
		with: PropTypes.string.isRequired,
		input_proper_email: PropTypes.string.isRequired,
		password_help: PropTypes.string.isRequired,
	}).isRequired,
	authType: PropTypes.oneOf(Object.values(AuthType)),
	termsAndConditionsUrl: PropTypes.string.isRequired,
	privacyPolicyUrl: PropTypes.string.isRequired,
	loginProviders: PropTypes.arrayOf(PropTypes.oneOf(Object.values(LoginProviders))).isRequired,
	allowSignUp: PropTypes.bool,
	/**
   * Component brand theme
   */
	theme: PropTypes.object.isRequired,
	onSubmit: PropTypes.func,
	onSubmitOAuth: PropTypes.func,
	onPasswordReset: PropTypes.func,
}

AuthForm.defaultProps = {
	id: "",
	labels: {
		login: "",
		sign_up: "",
		email: "",
		email_placeholder: "",
		password: "",
		password_again: "",
		eula: "",
		dont_have_an_account: "",
		already_have_an_account: "",
		forgot_password: "",
		reset_password: "",
		terms_and_conditions: "",
		privacy_policy: "",
		with: "",
		input_proper_email: "",
		password_help: "",
	},
	authType: AuthType.LOGIN,
	currentEmail: undefined,
	privacyPolicyUrl: undefined,
	termsAndConditionsUrl: undefined,
	loginProviders: [],
	allowSignUp: undefined,
	theme: {...appTheme},
	onSubmit: undefined,
	onSubmitOAuth: undefined,
	onPasswordReset: undefined,
}

export default AuthForm;