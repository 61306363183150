import PropTypes from 'prop-types';

import en_us  from './en_us.json';
import hu_hu  from './hu_hu.json';
import jp_jp  from './jp_jp.json';

export const defaultLocale = "en_us"

export const locales = {
  en_us: en_us,
  hu_hu: hu_hu,
  jp_jp: jp_jp
}

export const getText = ({locale, key, defaultValue}) => {
  let value = (defaultValue) ? defaultValue : key
  if(locales[locale] && locales[locale][key]){
    value = locales[locale][key]
  } else if((defaultLocale !== locale) && locales[defaultLocale][key]) {
    value = locales[defaultLocale][key]
  }
  return value
}

getText.propTypes = {
  locale: PropTypes.oneOf(Object.keys(locales)).isRequired,
  key: PropTypes.string.isRequired,
  defaultValue: PropTypes.string
}

getText.defaultProps = {
  locale: defaultLocale,
  key: "",
  defaultValue: undefined
}