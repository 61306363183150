
export const analyzeData = async (file) => {
  const getSize = () => file.size
  const readChunk = (chunkSize, offset) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        if (event.target.error) {
          reject(event.target.error)
        }
        resolve(new Uint8Array(event.target.result))
      }
      reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize))
    })
  try {
    const mediainfo = await window.MediaInfo({
      format: 'object',
      locateFile: (path, prefix) => prefix + path,
    })
    return await mediainfo.analyzeData(getSize, readChunk)
  } catch (error) {
    return null
  }      
}