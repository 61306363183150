import styled from 'styled-components'

export const StyledCheckbox = styled.input`
	appearance: none;
	background-color: ${props => props.theme.colors.darkPanelBackground};
	border-radius: ${props => props.theme.style.borderRadius}px;
	border: ${props => props.theme.style.border} ${props => props.theme.colors.borderColor};
	height: 18px;
	position: relative;
	min-width: 18px;
	transition: 0.4s;
	${props => props.error ? `border: ${props.theme.style.border} ${props.theme.colors.errorColor}` : null};

	:checked {
		background-color: rgba(254, 208, 118, 0.1);
		border: ${props => props.theme.style.border} ${props => props.theme.colors.textHighlight};
	}

	::after {
		content: " ";
		background: transparent;
		position: absolute;
		top: 0px;
		left: 0px;
		height: 12px;
		width: 12px;
		margin: 2px;
	}

	:checked::after {
		content: " ";
		background: url('data:image/svg+xml, <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 4L4.33333 7L11 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') center/contain no-repeat;
		position: absolute;
		top: 0px;
		left: 0px;
		height: 12px;
		width: 12px;
		margin: 2px;
	}
`