import { createGlobalStyle } from 'styled-components';
import { IntroTheme } from 'components/Product/Atoms/Intro/Intro.style'

export const GlobalStyle = createGlobalStyle`
 body {
  color: ${props => props.theme.colors.textPrimary};
  font-family: ${props => props.theme.fonts.secondary};
  font-size: ${props => props.theme.fonts.normal}px;
  background-color: ${props => props.theme.colors.bodyBackground};
  font-feature-settings: 'tnum' on, 'lnum' on;
 }
 ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: ${props => props.theme.colors.textSecondary};
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  background: ${props => props.theme.colors.darkestPanelBackground};
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.message-box {
  border: 1px solid ${props => props.theme.colors.primaryButtonText}};
  background: ${props => props.theme.colors.secondaryBackground};
  line-height: 1.3;
}
${IntroTheme}
`;
