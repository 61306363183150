import { ParallelHasher } from 'ts-md5';

export const guid = () => {
  function _p8(s) {
    let p = (Math.random().toString(16) + "000000000").substr(2, 8);
    return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
  }
  return _p8() + _p8(true) + _p8(true) + _p8();
}

export const hashFile = (file) => {
  try {
    const hasher = new ParallelHasher('/libs/md5_worker.js');
    return hasher.hash(file)
      .then((md5) => {
        return { data: md5 }
      })
      .catch((error) => {
        return { error: error }
      })
  } catch (error) {
    return { error: error }
  }
}

export const hexToRGB = (h) => {
  let r = 0, g = 0, b = 0;

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)];
}

export const RGBAToHexA = (rgba) => {
  let ex = /^rgba\((((((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]),\s?)){3})|(((([1-9]?\d(\.\d+)?)|100|(\.\d+))%,\s?){3}))|(((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5])\s){3})|(((([1-9]?\d(\.\d+)?)|100|(\.\d+))%\s){3}))\/\s)((0?\.\d+)|[01]|(([1-9]?\d(\.\d+)?)|100|(\.\d+))%)\)$/i;
  if (ex.test(rgba)) {
    let sep = rgba.indexOf(",") > -1 ? "," : " ";
    rgba = rgba.substr(5).split(")")[0].split(sep);

    // strip the slash if using space-separated syntax
    if (rgba.indexOf("/") > -1)
      rgba.splice(3, 1);

    for (let R in rgba) {
      let r = rgba[R];
      if (r.indexOf("%") > -1) {
        let p = r.substr(0, r.length - 1) / 100;

        if (R < 3) {
          rgba[R] = Math.round(p * 255);
        } else {
          rgba[R] = p;
        }
      }
    }

    let r = (+rgba[0]).toString(16),
      g = (+rgba[1]).toString(16),
      b = (+rgba[2]).toString(16),
      a = Math.round(+rgba[3] * 255).toString(16);

    if (r.length === 1)
      r = "0" + r;
    if (g.length === 1)
      g = "0" + g;
    if (b.length === 1)
      b = "0" + b;
    if (a.length === 1)
      a = "0" + a;

    return "#" + r + g + b + a;
  }
  return "#000000";
}

export const hexAToRGBA = (h, isPct) => {
  let ex = /^#([\da-f]{4}){1,2}$/i;
  if (ex.test(h)) {
    let r = 0, g = 0, b = 0, a = 1;
    isPct = isPct === true;

    if (h.length === 5) {
      r = "0x" + h[1] + h[1];
      g = "0x" + h[2] + h[2];
      b = "0x" + h[3] + h[3];
      a = "0x" + h[4] + h[4];

    } else if (h.length === 9) {
      r = "0x" + h[1] + h[2];
      g = "0x" + h[3] + h[4];
      b = "0x" + h[5] + h[6];
      a = "0x" + h[7] + h[8];
    }
    a = +(a / 255).toFixed(3);
    if (isPct) {
      r = +(r / 255 * 100).toFixed(1);
      g = +(g / 255 * 100).toFixed(1);
      b = +(b / 255 * 100).toFixed(1);
      a = +(a * 100).toFixed(1);
    }

    return "rgba(" + (isPct ? r + "%," + g + "%," + b + "%," + a : +r + "," + +g + "," + +b + "," + a) + ")";

  }
  return "rgba(0,0,0,1)";
}

export const getRGBA = (_color, _alpha) => {
  const color = _color || "#ffffff"
  const alpha = _alpha || 1
  if (String(color).includes("rgba")) {
    return color
  }
  if (color.length === 9) {
    return hexAToRGBA(color)
  } else if ((color.length === 4) || color.length === 7) {
    let rgb = hexToRGB(color); rgb.push(alpha)
    return `rgba(${Array(rgb).join(",")})`
  }
  return "rgba(0,0,0,1)";
}

export const convertLocalDateTimeObjectToUTCStringDate = (date_time) => {
  return new Date(date_time).toISOString().split('T')[0]
}

export const convertLocalDateTimeObjectToUTCString = (date_time) => {
  return new Date(date_time).toISOString().slice(0, -5)
}

export const convertUTCStringToLocalDateTime = (utc_string) => {
  const new_date = new Date(utc_string)
  new_date.setTime(new_date.valueOf() - 60000 * new_date.getTimezoneOffset())
  return new_date
}

export const convertUTCStringToLocalDateTimeString = (utc_string) => {
  return convertUTCStringToLocalDateTime(utc_string).toISOString()
}

export const convertUTCStringToLocalDateString = (utc_string) => {
  const new_date = new Date(utc_string)
  new_date.setTime(new_date.valueOf() - 60000 * new_date.getTimezoneOffset())
  return new_date.toISOString().split('T')[0]
}



export const dummyText = (len) => {
  return String(`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`).substr(0, len)
}

export function decimalToHex(d) {
  var hex = Number(d).toString(16);
  hex = "000000".substr(0, 6 - hex.length) + hex;
  return hex;
}

export const changeColorLightness = (color, changeAmount) => {
  var usePound = false;
  if (color[0] === "#") {
    color = color.slice(1);
    usePound = true;
  }

  var num = parseInt(color, 16);
  var r = (num >> 16) + changeAmount;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + changeAmount;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000FF) + changeAmount;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  var hexNum = decimalToHex(g | (b << 8) | (r << 16));
  return (usePound ? "#" : "") + hexNum;
}

/**
 * Open a link in a new tab, then call a callback if defined
 * @param {string} url - URL to navigate to
 */
export const openUrl = (url, callback) => {
  if (url) {
    const element = document.createElement("A")
    element.setAttribute("href", url)
    element.setAttribute("target", "_blank")
    document.body.appendChild(element)
    element.click()
    if (callback) callback(url)
  }
}

export const getTimeBetweenTwoDates = (date_now, date_future) => {
  if (date_future <= date_now) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  }
  // get total seconds between the times
  var delta = Math.abs(date_future - date_now) / 1000;

  // calculate (and subtract) whole days
  var days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  var seconds = parseInt(delta % 60);  // in theory the modulus is not required

  return {
    days,
    hours,
    minutes,
    seconds
  }
}

export const getTimeBetweenNowAndFuture = (date_future) => {
  return getTimeBetweenTwoDates(new Date(), date_future)
}

export const normalizeNumberBetween = (number, minNumber, maxNumber, minResult, maxResult) => {
  return ((number - minNumber) / (maxNumber - minNumber)) * (maxResult - minResult) + minResult
}

export const getLabels = (currentLabel, getText) => {
  if (typeof currentLabel === "string") {
    return getText(currentLabel)
  } else if (currentLabel.toString() === "[object Object]") {
    let ret = {}
    Object.keys(currentLabel).forEach(key => {
      ret[key] = getLabels(currentLabel[key], getText)
    });
    return ret
  } else if (Array.isArray(currentLabel)) {
    let ret = []
    currentLabel.forEach(value => {
      ret.push(getLabels(value, getText))
    });
    return ret
  }
}

export const getRandomFromArray = (list) => {
  return list[Math.floor((Math.random()*list.length))];
}

export const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!target[key]) Object.assign(target, {
            [key]: {}
          });
          mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, {
            [key]: source[key]
          });
        }
      }
    }

    return mergeDeep(target, ...sources);
}

export const toTimestamp = (ms) => {
  var date = new Date(null);
  date.setMilliseconds(ms);
  return date.toISOString().slice(11, 19);
}

export const getRelativeTime = (past_date) => {
  let currentLang = 'default';
  var today = new Date();
  var dateInPast = new Date(past_date);
  var msInDay = 24 * 60 * 60 * 1000;

  dateInPast.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0)

  var diff = (+today - +dateInPast) / msInDay;
  const rtf1 = new Intl.RelativeTimeFormat( currentLang, { numeric: "auto" });
  if (diff < 31) {
    return rtf1.format(parseInt(-diff), 'day')
  } else if (diff < 365) {
    return rtf1.format(parseInt(-diff / 30), 'month')
  } else {
    return rtf1.format(parseInt(-diff / 365), 'year')
  }
}
