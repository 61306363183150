import { RESTRICTIONS } from "config/enums";
import { initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from "firebase/auth";


export function initFirebase(config){
  try {
    this.state.session.app.firebaseApp = initializeApp(config);
    this.state.session.app.firebaseAuth = getAuth(this.state.session.app.firebaseApp);
    this.state.session.app.firebaseAuth.languageCode = this.state.session.app.lang;
    onAuthStateChanged(this.state.session.app.firebaseAuth, user => {
      if(!this.state.session.restrictions.includes(RESTRICTIONS.LOGIN_ALLOW_SIGNUP)){
        if(user 
          && (Math.abs(Number(user.metadata["createdAt"]) - Number(user.metadata["lastLoginAt"])) < 10) 
          && (user.providerData[0].providerId !== "password")){
          user.delete()
          this.showToast({ id: "sign_up_not_allowed", type: "error", autoClose: false, message: this.getText("login_sign_up_not_allowed") })
          return false
        }
      }
      this.setSessionData(user)
    });
    return true
  } catch (error) {
    return false
  }
}

export async function getToken(){
  let token = this.state.session.app.token || ""
  const firebaseAuth = this.state.session.app.firebaseAuth
  try {
    if((token === "") && firebaseAuth.currentUser){
      token = await firebaseAuth.currentUser.getIdToken()
    }
  } catch (error) {
    return ""
  }
  return token
}

export function signOut(){
  try {
    if(this.state.session.app.token){
      this.setData("session", { "error": "app_error_unauthorized" }, ()=>{
        localStorage.removeItem("omni_token")
        this.state.session.app.token = null
        if(this.state.session.provider.pageSettings.login.customAuthentication.providerTokenSignout && this.state.session.provider.pageSettings.login.customAuthentication.providerTokenSignout !== ""){
          return window.location.assign(this.state.session.provider.pageSettings.login.customAuthentication.providerTokenSignout)
        }
        window.location.reload()
      }) 
    } else {
      this.setData("session", { "user": null })
      this.state.session.app.firebaseAuth.signOut();
    }
  } catch (error) {}
}