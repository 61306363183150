import update from 'immutability-helper';
import { BlockBlobClient } from '@azure/storage-blob';
import { AbortController } from "@azure/abort-controller";
import { format, parseISO } from 'date-fns'
import { hotjar } from 'react-hotjar';

import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';

import Spinner from 'components/Spinner'

import { App as AppBase } from "./App";
import modules from './modules'
import request from './request';
import { store } from 'config/app'
import { initFirebase, getToken, signOut } from './firebase'
import { showToast } from './toast'
import { RGBAToHexA, convertUTCStringToLocalDateTimeString, guid, getRGBA, normalizeNumberBetween, mergeDeep } from 'shared/utils'
import { appUI } from 'config/app'
import { getText, locales } from 'locales'
import { RESTRICTIONS, UserMeta } from 'config/enums';
import { STATE as UploadProgressState } from 'components/Product/Molecules/UploadProgressIndicator/UploadProgressIndicator';
import TagEventTypes from 'config/tagEvents';
import TagManager from 'shared/TagManager';
import { analyzeData } from 'shared/video'
import { SCROLL_TARGET } from 'components/Product/Atoms/Intro/Intro'

const EventSource = EventSourcePolyfill || NativeEventSource;
export default class App extends AppBase {

  constructor(props) {
    super(props);

    this.initFirebase = initFirebase.bind(this)
    this.getToken = getToken.bind(this)
    this.signOut = signOut.bind(this)

    this.state = update(store, {$merge: props.data||{} })

    let [ current, params ] = this.getPath()
    if(!Object.keys(modules).includes(current)){
      current = this.state.current.home
    }
    this.state = update(this.state, {current: {$merge: {
      module: current, params: params
    }}})

    this.actions = {
      setData: this.setData.bind(this),
      requestData: this.requestData.bind(this),
      setError: this.setError.bind(this),
      getText: this.getText.bind(this),
      signOut: this.signOut.bind(this),
      showToast: this.showToast.bind(this),
      fileUpload: this.fileUpload.bind(this),
      fileUploadXHR: this.fileUploadXHR.bind(this),
      setSessionData: this.setSessionData.bind(this),
      getQuestProps: this.getQuestProps.bind(this),
      userRole: this.userRole.bind(this),
      getRGBA: this.getRGBA.bind(this),
      setLocale: this.setLocale.bind(this),
      help: this.help.bind(this),
      openUrlWithAuth: this.openUrlWithAuth.bind(this),
      parseJSON: this.parseJSON.bind(this),
      changeUserLanguage: this.changeUserLanguage.bind(this),
      updateUserMeta: this.updateUserMeta.bind(this),
      getOGShareImage: this.getOGShareImage.bind(this),
      getBackground: this.getBackground.bind(this),
      getLabels: this.getLabels.bind(this),
      analyzeData: analyzeData,
      updateUserQuests: this.updateUserQuests.bind(this),
      getIntroData: this.getIntroData.bind(this),
      introExit: this.introExit.bind(this)
    }
  }

  async updateUserMeta(key, value) {
    const userMeta = {
      ...this.state.session.data.user.meta,
      [key]: value
    }
    const options = {
      method: "PUT",
      core: true,
      data: userMeta,
    }
    const result = await this.actions.requestData("/user/me", options)
    if(result && result.error){
      return this.actions.setError(result.error)
    }

    const session_data = {
      ...this.state.session.data,
      user: {
        ...this.state.session.data.user,
        meta: userMeta
      }
    }
    this.actions.setData("session", { data: session_data })
  }

  getIntroData(iconfig) {
    if(this.state.session.restrictions.includes(RESTRICTIONS.ONBOARDING_PROMPTS)){
      return {
        options: {}, steps: [], filters: {}
      }
    }
    const disabledFilter = String(this.state.session.data.user.meta.user_onboarding_steps || "").split(",")
    let filters = {}
    Object.keys(iconfig.filters).forEach(filter => {
      if(!disabledFilter.includes(filter)){
        filters[filter] = iconfig.filters[filter]
      }
    });
    return {
      options: {
        nextLabel: this.getText("steps_intro_next"),
        prevLabel: this.getText("steps_intro_previous"),
        doneLabel: this.getText("steps_intro_done"),
        dontShowAgainLabel: this.getText("steps_intro_show_again"),
        exitOnOverlayClick: false,
        disableInteraction: true,
        scrollTo: SCROLL_TARGET.TOOLTIP,
      },
      steps: iconfig.steps.map(step => ({
        ...step,
        title: (step.title) ? this.getText(step.title) : null,
        intro: this.getText(step.intro)
      })),
      filters
    }
  }

  introExit = (mdKey, filter, showAgain) => {
    this.setData(mdKey, { 
      intro_data: {
        ...this.state[mdKey].intro_data,
        guide: null,
        filters: {
          ...this.state[mdKey].intro_data.filters,
          [filter]: null
        }
      } 
    })
    if(!showAgain){
      const disabledFilter = String(this.state.session.data.user.meta.user_onboarding_steps || "").split(",")
      disabledFilter.push(filter)
      this.updateUserMeta("user_onboarding_steps", disabledFilter.join(","))
    }
  }

  async changeUserLanguage(lang) {
    const user = this.state.session.data.user
    if(user.meta.user_language === lang)
      return

    const newMeta = update(update(user.meta, {$unset: [UserMeta.user_subscription, "match_riot_puuid"]}), {$merge: {[UserMeta.user_language]: lang}})
    const options = {
      method: "PUT",
      core: true,
      data: newMeta,
    }
    const result = await this.actions.requestData("/user/me", options)
    if(result && result.error){
      return this.actions.setError(result.error)
    }

    const session_data = update(this.state.session.data, {user: {$merge: {
      meta: user.meta
    }}})

    this.actions.setData("session", { data: session_data }, ()=>{
        window.location.reload()
    })
  }

  getOGShareImage(game) {
    return window.location.protocol + "//" + window.location.host + `/config/brands/default/${game.toLowerCase()}-share.png`
  }

  setData(key, data, callback) {
    if(key && this.state[key] && typeof data === "object" && data !== null){
      const value = update(this.state[key], {$merge: data})
      this.setState({ [key]: value },
        ()=>{ if(callback) {callback()} })
    } else if(key){
      this.setState({ [key]:  data }, ()=>{ if(callback) {callback()} })
    }
  }

  /* istanbul ignore next */
  getPath() {
    const getParams = (prmString) => {
      let params = {}
      prmString.split('&').forEach(prm => {
        params[prm.split("=")[0]] = prm.split("=")[1]
      });
      return params
    }
    if(String(window.location.pathname).startsWith("/public/")){
      return["public", String(window.location.pathname).split("/")[2]]
    }
    if(window.location.hash){
      return ["hash", getParams(window.location.hash.substring(1))]
    }
    if(window.location.search){
      return ["search", getParams(window.location.search.substring(1))]
    }
    const path = window.location.pathname.substring(1).split("/")
    return [path[0], path.slice(1)]
  }

  getText(key, defValue, _lang) {
    const lang = _lang || this.state.session.app.lang
    let text = getText({
      locale:lang, key:key, defaultValue:defValue
    })
    if (this.state.session.provider.locales && this.state.session.provider.locales[lang]
      && this.state.session.provider.locales[lang][key]) {
        text = this.state.session.provider.locales[lang][key];
    }
    this.consoleLog(key, text)
    return text
  }

  consoleLog(key, text){
    if (this.state.session.app.debugFlag){
      if(typeof key !== "string"){
        console.log(`%c ${JSON.stringify(key)}`, `color: red;`);
      }else if(!this.state.session.app.console[key]){
        this.state.session.app.console[key] = text
        console.log(`%c ${key}`, `color: brown;`);
        console.log(`%c ${text}`, `color: orange;`);
      }
    }
  }

  getRGBA(_color, _alpha) {
    return getRGBA(
      (this && this.state.session.provider.themes[this.state.session.provider.theme][_color]) || _color,
      _alpha || 1
    )
  }

  onResize() {
    if((this.state.current.clientHeight !== window.innerHeight) ||
      (this.state.current.clientWidth !== window.innerWidth)){
      this.setData("current", { clientHeight: window.innerHeight, clientWidth: window.innerWidth })
    }
  }

  onScroll() {
    const scrollTop = ((document.body.scrollTop > 100) || (document.documentElement.scrollTop > 100))
    if(this.state.current.scrollTop !== scrollTop){
      this.setData("current", { scrollTop: scrollTop })
    }
  }

  setError(error, msg_code, message, toast_id, autoClose) {
    let errors = this.state.errors||[]
    errors = update(errors, {$push: [error]})
    this.actions.setData("request", false)
    this.actions.setData("errors", errors)
    if(msg_code !== null){
      this.showToast({
        id: toast_id,
        type: "error",
        autoClose: (autoClose === false) ? false : true,
        message: (typeof msg_code === "undefined") ?
          error.message + ((error.detail && (typeof error.detail === "string"))?"\n"+error.detail:"") : this.getText(msg_code, message||"")
      })
    }
  }

  userRole() {
    if(this.state.session.data){
      switch (this.state.session.data.user.role) {
        case "ADMIN":
          return "ADMIN"

        default:
          return (this.state.session.data.user.token && (this.state.session.data.user.token.superuser === "true"))
            ? "SUPERUSER" : this.state.session.data.user.role
      }
    }
    return this.state.session.user.superuser === "true" ? "SUPERUSER" : "USER"
  }

  schemaValidation(data, schema) {
    const errMessage = "Invalid schema"
    let invalidFields = []
    const fieldCheck = (item, itemSchema, rowIndex) => {
      const fields = Object.values(itemSchema)
      Object.keys(item).forEach(fieldName => {
        if(!fields.includes(fieldName)){
          if((typeof(itemSchema[String(fieldName).toUpperCase()]) === "object") && typeof(item[fieldName]) === "object"){
            fieldCheck(item[fieldName], itemSchema[String(fieldName).toUpperCase()], rowIndex)
          } else {
            invalidFields.push(`Row: ${rowIndex} Fieldname:${fieldName}`)
          }
        }
      })
    }
    if(schema.type === "array"){
      if(!Array.isArray(data)){
        return { error: { message: errMessage, detail: "Schema type error" } }
      }
      data.forEach((row, index) => {
        fieldCheck(row, schema.fields, index)
      });
    } else {
      fieldCheck(data, schema.fields, 0)
    }
    return (invalidFields.length > 0) ? { error: { message: errMessage, detail: invalidFields } } : {}
  }

  async requestData(path, options, silent) {
    try {
      if (!silent)
        this.actions.setData("request", true)
      let basePath = this.state.session.app.frontendPath
      if (options.core) {
        basePath = this.state.session.app.corePath
      } else if (options.shop) {
        basePath = this.state.session.app.shopPath
      }

      let url = this.state.session.app.proxy+basePath+path
      const token = await this.getToken()
      if (!options.headers)
        options = update(options, {$merge: { headers: {} }})
      options = update(options, {
        headers: {$merge: {
          "Content-Type": "application/json",
          "x-fqdn": this.state.session.app.providerPath,
        }}
      })
      if(token !== ""){
        options = update(options, {
          headers: {$merge: { 
            "Authorization": `${(token === "GUEST") ? "" : "Bearer "}${token}` 
          }}
        })
      }
      if (options.data){
        options = update(options, {
          body: {$set: JSON.stringify(options.data)}
        })
      }
      if(options.query){
        let query = new URLSearchParams();
        for (const key in options.query) {
          query.append(key, options.query[key])
        }
        url += "?" + query.toString()
      }
      const result = await request(url, options)
      if (!silent) {
        this.actions.setData("request", false)
      }
      if (this.state.session.app.debugFlag && options.schema){
        const valid = this.schemaValidation(result, options.schema)
        if(valid.error){
          return valid
        }
      }
      return result
    } catch (err) {
      if(!silent)
        this.actions.setData("request", false)
      return { error: { message: err.message, ecode: err.ecode||-1,
        detail: (err.response) ? err.response.detail : {} } }
    }
  }

  checkConfig(config){
    let err_msg = null
    Object.keys(config).forEach(key => {
      switch (key) {

        default:
          break;
      }
    });
    if(err_msg){
      this.setError({message: err_msg},"",err_msg)
      return false
    }
    return true
  }

  setLocale(lang) {
    if ((lang !== this.state.session.app.lang) && locales[lang]){
      this.state.session.app.lang = lang
    }
  }

  async loadConfig(preLoad){
    let config = update({}, {$merge: this.state.session.provider})

    const result = await this.requestData("/config/"+this.state.session.app.providerPath, {
      schema: this.state.session.app.venus_api.frontend_config
    })
    if(result.error){
      return this.setError(result.error)
    }
    config = mergeDeep(config, result)
    if(preLoad){
      return config
    }
    
    this.state.tagManager = new TagManager(
      result.gTagId,
      result.ga4MeasurementID
    )

    this.setLocale(config.language||this.state.session.app.lang)
    if(this.checkConfig(config)){
      this.actions.setData("request", true)
      config = update(config, {$merge: { error: null }})
      if(config.pageSettings.login.customAuthentication.providerTokenLogin && config.pageSettings.login.customAuthentication.providerTokenLogin !== "" && !this.state.session.app.token){
        return window.location.assign(config.pageSettings.login.customAuthentication.providerTokenLogin+"&state="+guid())
      }

      //App. ui provider restrictions
      let restrictions = [
        ...config.restrictions
      ]
      if(!config.private.enabledRiotAPI){
        restrictions = [...restrictions, RESTRICTIONS.RIOT_API]
      }
      if(this.state.session.app.token){
        this.setData("session", {provider: config, restrictions}, ()=>{
          this.setSessionData(null)
        })
      } else if (!this.initFirebase(result.auth)){
        this.actions.setData("request", false)
        this.setError({ message: "app_error_firebase" }, "app_error_firebase")
      } else {
        this.setData("session", {provider: config, restrictions} )
      }
    }
  }

  async setSessionData(user){
    this.actions.setData("request", false)
    if(!user && !this.state.session.app.token){
      if(this.state.session.provider.pageSettings.login.customAuthentication.providerTokenLogin && this.state.session.provider.pageSettings.login.customAuthentication.providerTokenLogin !== ""){
        return window.location.assign(this.state.session.provider.pageSettings.login.customAuthentication.providerTokenLogin+"&state="+guid())
      }
      this.setData("session", { "data": null })
    } else if(user && this.getPath()[0].startsWith("signup")) {
      this.setData("session", { "user": user })
    } else {
      if(user && user.providerData && (user.providerData[0].providerId === "password") && (!user.emailVerified)){
        localStorage.setItem("signin_email", user.email);
      }
      const result = await this.requestData("/session", {
        schema: this.state.session.app.venus_api.frontend_session
      })
      if(result.error){
        if(this.state.session.app.token){
          if((result.error.ecode === 401) && (result.error.detail.claims && result.error.detail.claims.creation === "true")){
            this.setData("session", { "user": result.error.detail.claims || {} })
          } else {
            this.setData("session", { "error": "app_error_unauthorized" }, ()=>{
              this.setError(result.error)
              localStorage.removeItem("omni_token")
              if(this.state.session.provider.pageSettings.login.customAuthentication.providerTokenLogin && this.state.session.provider.pageSettings.login.customAuthentication.providerTokenLogin !== ""){
                window.location.assign(this.state.session.provider.pageSettings.login.customAuthentication.providerTokenLogin+"&state="+guid())
              }
            })
          }
        } else {
          switch (result.error.ecode) {
            case 400:
            case 402:
            case 403:
              this.setData("session", { "data": null })
              this.signOut()
              this.setError(result.error, "app_error_unauthorized")
              break;

            case 401:
            case 404:
              this.setData("session", { "user": user })
              break;

            case 409:
              this.setData("session", { "data": null })
              this.setError(result.error, "app_error_exists_user")
              break;

            default:
              this.setData("session", { "data": null })
              this.signOut()
              this.setError(result.error, "app_error_internal")
              break;
          }
        }
      } else {
        let cards = update(this.state.cards, {$merge: {
          communityQuests: result.quest.filter(quest => (quest.quest_type === "COMMUNITY")).map(quest => {
            let users = []
            users = result.community_users
              .sort((a, b) => b.value - a.value)
              .filter(user => (user.quest_id === quest.id))
              .map((user, index) => {
              return {
                userId: user.user_id, userName: user.nickname, contribution: user.value, placement: index + 1, isCurrentUser: user.user_id === result.user.id
              }
            });
            let userIndex = users.findIndex(user => {
              return user.userId === result.user.id
            } )
            let originalUsers = update([], {$merge: users})
            users.splice(4)
            if(userIndex > 3) {
              users[3] = {...originalUsers[userIndex], isCurrentUser: true}
            }
            return this.getQuestProps(
            quest, result.user, {
              current: result.community_current.filter(current => (current.quest_id === quest.id))[0],
              users: users,
            }
          )}),
          quests: result.quest
            .filter(quest => (quest.quest_type === "PERSONAL"))
            .map(quest => this.getQuestProps(quest, result.user, {})),
        }});
        this.setData("cards", cards)

        //App. ui user restrictions
        let restrictions = [
          ...this.state.session.restrictions
        ]
        if(!["SUPERUSER","ADMIN"].includes(result.user.role)){
          restrictions = [...restrictions, RESTRICTIONS.ADMIN]
        }
        if(result.user.role === "GUEST"){
          restrictions = [...restrictions, RESTRICTIONS.MENU]
        }

        this.setData("session", { "data": result, restrictions }, ()=>{
          if(result.user.meta.user_language
            && (result.user.meta.user_language !== this.state.session.app.lang)){
              this.setLocale(result.user.meta.user_language)
          }
          this.state.tagManager.initUserData({
            userId: result.user.id,
            userReferenceId: result.user.reference_id,
            userMeta: result.user.meta
          })
        })

        if(result.user.meta.user_debug_flag){
          this.state.session.app.debugFlag = true
        }
        const {hjid, hjsv} = this.state.session.provider.hotjar
        if(hjid && hjsv){
          hotjar.initialize(hjid, hjsv)
        }
        const {message, url} = this.state.session.provider.motd
        if(message && (message !== "")){
          this.showToast({
            id: "motd_msg",
            type: "motd",
            message: message,
            url: url,
            closeOnClick: true
          })
        }
        this.setSSE()
        this.state.tagManager.sendEvent(TagEventTypes.OMNICOACH_GENERAL_LOGIN)
      }
    }
  }

  setHashToken(params) {
    const path = (params.path)
      ? "/"+params.path
      : "/"
    localStorage.setItem("omni_token", params.access_token||null)
    window.location.assign(path)
  }

  async setCodeToken(params) {
    const config = await this.loadConfig(true)
    if(config && config.pageSettings.login.customAuthentication.providerTokenCallback){
      const options = {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({
          code: params.code,
          client_id: config.provider_client_id || "missing_config",
          client_secret: config.provider_client_secret || "missing_config"
        })
      }
      try {
        const result = await request(config.pageSettings.login.customAuthentication.providerTokenCallback, options)
        if(result.access_token){
          const path = (params.path)
            ? "/"+params.path
            : "/"
          localStorage.setItem("omni_token", result.access_token||null)
          window.location.assign(path)
        }
      } catch (err) {
        if(config.pageSettings.login.customAuthentication.providerTokenLogin && config.pageSettings.login.customAuthentication.providerTokenLogin !== ""){
          return window.location.assign(config.pageSettings.login.customAuthentication.providerTokenLogin+"&state="+guid())
        }
        this.setData("session", { "error": "app_error_unauthorized" }, ()=>{
          this.setError(err)
        })
      }
    } else {
      this.setData("session", { "error": "app_error_unauthorized" }, ()=>{
        this.showToast({
          id: "app_error_unauthorized",
          type: "error",
          message: this.getText("app_error_unauthorized", "Unauthorized user")
        })
      })
    }
  }

  showToast(params) {
    params = update(params, {$merge: {
      autoClose: (params.autoClose === false) ? false : appUI.toastTime,
      theme: this.state.session.provider.themes[this.state.session.provider.theme]
    }})
    showToast(params)
    if(typeof(params.dataId) !== "undefined")
    {
      this.consoleLog({
        event_type: "upload_success_message",
        dataId : params.dataId
      });
    }
  }

  async fileUpload(options) {
    const { upload_url } = options.context
    const client = new BlockBlobClient(upload_url);

    try {
      const controller = new AbortController();
      this.setData("current", { controller: controller })
      await client.uploadData(options.file, {
        abortSignal: controller.signal,
        blockSize: 5 * 1024 * 1024, // 5MB block size
        concurrency: 20, // 20 concurrency
        blobHTTPHeaders: {
          blobContentType: options.content_type || 'application/octet-stream'
        },
        onProgress: (ev) => {
          if(options.progress && !controller.signal.aborted){
            options.progress(Math.round((ev.loadedBytes / options.file.size) * 100), options.context)
          }
        }
      });
      if(options.success){
        return options.success(options.context)
      }
      return true
    } catch (error) {
      if(options.error){
        return options.error(error, options.context)
      }
      return { error: error }
    }
  }

  async fileUploadXHR(
    file,
    uploadUrl,
    onProgress,
    onError,
    onSuccess,
    callbackContext
  ) {
    var xhr = new XMLHttpRequest();
    this.setData("current", { controller: xhr })
    xhr.open('PUT', uploadUrl, true);
    xhr.setRequestHeader("Content-Type", callbackContext.content_type);
    // xhr.setRequestHeader("Origin", window.origin);

    const started_at = new Date();
    if(onSuccess) {
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          onSuccess(callbackContext)
        }
      };
    }

    if(onError) {
      xhr.onerror = function (response) {
        onError({message: getText("upload_error")}, callbackContext)
      };
    }

    if(onProgress) {
      xhr.upload.onprogress = function (evt) {
        if (evt.lengthComputable) {
          var percentComplete = parseInt((evt.loaded / evt.total) * 100);

          // Time Remaining
          const seconds_elapsed = (new Date().getTime() - started_at.getTime()) / 1000;
          const bytes_per_second = seconds_elapsed ? evt.loaded / seconds_elapsed : 0;
          const remaining_bytes = evt.total - evt.loaded;
          const seconds_remaining = seconds_elapsed ? remaining_bytes / bytes_per_second : undefined;
          onProgress(percentComplete, update(callbackContext, {$merge: {seconds_remaining}}))
        }
      }
    }
    xhr.send(file);
  }

  parseJSON = (jsonString, fbackDefault) => {
    try {
      const json = JSON.parse(jsonString)
      return json
    } catch (error) {
      return fbackDefault || {}
    }
  }

  getQuestProps(quest, user, community) {
    const qmeta = quest.meta || {}
    const rmeta = quest.reward_meta || {}
    //const theme = this.state.session.provider.themes[this.state.session.provider.theme]
    const theme = this.state.session.provider.brandTheme
    const userLang = (user && user.meta && user.meta.user_language)
      ? user.meta.user_language : this.state.session.provider.language

    quest = update(quest, {$merge: {
      theme: theme, getRGBA: this.getRGBA,
      description: qmeta.quest_description,
      short_description: qmeta.quest_short_description,
      difficulty: qmeta.quest_difficulty,
      headBackground: qmeta.quest_background||RGBAToHexA(this.getRGBA("separator",0.3)),
      // headColor: qmeta.quest_color||theme.text,
      // border: qmeta.quest_border||theme.separator,
      // icon: qmeta.quest_headkey,
      expiry: (quest.stop_at) ?
        format(parseISO(convertUTCStringToLocalDateTimeString(quest.stop_at)),
          appUI.dateFormat+" "+appUI.timeFormat)
        : "",
      labels: {
        progress: this.getText("quest_progress"),
        completed: this.getText("quest_completed"),
        questCompleted: this.getText("quest_completed"),
        expiry: this.getText("quest_expiry"),
        redeem: this.getText("quest_redeem_coupon"),
        coupon: this.getText("quest_coupon_code"),
        check: this.getText("quest_check_reward"),
        description: this.getText("quests_description"),
        reward: this.getText("quests_reward"),
        details: this.getText("quests_details"),
        countdown: this.getText("quests_countdown"),
        difficulty: this.getText("quests_difficulty"),
        recording: this.getText("quests_recording"),
        requirements: this.getText("quests_video_requirements"),
        upload: this.getText("quests_upload_match"),
        tasks: this.getText("quest_tasks"),
        entryLevel: this.getText("quest_entry_level"),
        claimButton: this.getText("quest_claim_button"),
        claimed: this.getText("quest_claimed"),
        couponCopied: this.getText("quest_coupon_copied"),
        topContributors: this.getText("quest_top_contributors"),
        noContribution: this.getText("quest_no_contribution"),
        days: this.getText("days"),
        hours: this.getText("hours"),
        minutes: this.getText("minutes"),
        seconds: this.getText("seconds"),
      },
      image: qmeta.quest_image ? `${this.state.session.provider.videoStorage}${qmeta.quest_image}` : '/config/brands/default/quest_missing.png',
      rewardName: quest.reward_name || "",
      rewardType: quest.reward_type || "COUPON",
      rewardLink: rmeta.reward_link || "",
      rewardCode: rmeta.reward_code || "",
      previewLink: rmeta.reward_preview || rmeta.reward_link || "",
      showPreview: this.state.session.provider.pageSettings.quests.questPreviewLink,
      progressCurrent: (community && (quest.quest_type === "COMMUNITY") && community.current) ? community.current.value : 0,
      topList: (community) ? community.users : [],
      minContribution: (community && qmeta.quest_config) ? qmeta.quest_config.min_contribution : 0,
      goal: (community && qmeta.quest_config) ? qmeta.quest_config.goal : 0,
    }})
    if(qmeta.quest_locales){
      const transMeta = qmeta.quest_locales || {}
      Object.keys(transMeta).forEach(fieldName => {
        if(transMeta[fieldName][userLang] && quest[fieldName] && (transMeta[fieldName][userLang] !== "")){
          quest = update(quest, {$merge: {
            [fieldName]: transMeta[fieldName][userLang]
          }})
        }
      })
    }
    return quest
  }

  help(path, fullUrl, target) {
    const helpURL = (fullUrl)?fullUrl:this.state.session.provider.brands[this.state.session.provider.brand].help
    const url = (path) ? helpURL+"/"+path : helpURL
    const element = document.createElement("A")
    element.setAttribute("href", url)
    element.setAttribute("target", (target)?target:"_blank")
    document.body.appendChild(element)
    element.click()
    return <Spinner />
  }

  openUrlWithAuth(fullUrl, target) {
    const token = localStorage.getItem("omni_token") || null
    let url = fullUrl
    if (token) {
      url = fullUrl + '#access_token=' + token
    }
    const element = document.createElement("a")
    element.setAttribute("href", url)
    element.setAttribute("target", (target) ? target : "_blank")
    document.body.appendChild(element)
    element.click()
    return <Spinner />
  }

  updateUserQuests = async () => {
    const result = await this.requestData("/quests", { 
      core: false,
      schema: this.state.session.app.venus_api.frontend_quests 
    })
    if(result.error){
      return this.setError(result.error)
    }
    const sessionData = update(this.state.session.data, { $merge: {quest: result}});
    this.setData("session", { data: sessionData })
    let cards = update(this.state.cards, {$merge: {
      quests: result.map(quest => this.getQuestProps(quest)),
    }});
    this.setData("cards", cards)
  }

  sseEvent(event) {
    const updateGalleryPage = () => {
      if(this.state.current.module === "gallery" ){
        const gallery = update(this.state.gallery, {$merge: {
          begin_date: null,
          requery: new Date().getUTCMilliseconds()
        }});
        this.setData("gallery", gallery)
      }
    }
    const updateVideoPage = () => {
      if(this.state.current.module === "video" ){
        this.setData("video", {
          ...this.state.video, 
          requery: new Date().getUTCMilliseconds()
        })
      }
    }
    const getMessage = (message) => {
      if (message.match_status.includes("FAIL")) {
        return this.getText("sse_error_match_analysis");
      }

      switch(message.match_status) {
        case "DONE":
          return this.getText("sse_done_match_analysis")
        case "VULCAN_SUCCESS":
          return this.getText("sse_match_analysis_vulcan_success")
        case "MINERVA_SUCCESS":
          return this.getText("sse_match_analysis_minerva_success")
        case "VIDEO_CHECK_SUCCESS":
          return this.getText("sse_upload_check_success")
        default:
          return this.getText("sse_info_match_analyzing")
      }
    }

    if (event.type === "message"){
      if(event.data){
        const message = this.parseJSON(event.data || "{}", {})
        switch (message.event_type) {
          case "HIGHLIGHT_CREATE_RESULT":
            this.showToast({
              id: `sse_message_highlight_${message.match_id}`,
              type: "success",
              message: this.getText("video_highlight_sse_info"),
              autoClose: true
            })
            updateGalleryPage()
            updateVideoPage()
            break;

          case "VULCAN_PERCENT":
            this.consoleLog(message)
            if(message.percent < 100){
              let value = normalizeNumberBetween(message.percent, 0, 100, 0, 95)
              this.setData("current", {
                indicatorEvent: { type: UploadProgressState.ANALYZING, value: value,
                label: String(this.getText("menu_indicator_progress_analysis")).replace("{value}", value) }
              })
            }
            return

          case "MATCH_STATUS":
            if (message.match_status === 'UPLOAD_SUCCESS' || message.match_status === 'UPLOAD_IN_PROGRESS') {
              updateGalleryPage()
              return
            }

            if((message.match_status !== "DONE") && message.progress && (message.progress > 0)){
              if(message.progress < 100){
                this.setData("current", {
                  indicatorEvent: { type: UploadProgressState.ANALYZING, value: message.progress, secondsRemaining: 0,
                  label: String(this.getText("menu_indicator_progress_analysis")).replace("{value}", message.progress) }
                })
              }
            }

            if(message.match_status === "DONE"){
              this.updateUserQuests()
            }

            if((message.match_status === "DONE") || message.match_status.includes("FAIL")){
              const itype = (message.match_status === "DONE") ? UploadProgressState.DONE : UploadProgressState.ERROR
              this.setData("current", {
                indicatorEvent: { type: itype, value: 100, secondsRemaining: undefined,
                label: this.getText(`menu_indicator_${itype}`) }
              })
            }

            this.showToast({
              id: `sse_message_${message.match_id}_${message.match_status}`,
              type: (message.match_status === "DONE") ? "success" :
                (message.match_status.includes("FAIL")) ? "error" : "info",
              message: getMessage(message),
              autoClose: ((message.match_status === "DONE") || message.match_status.includes("FAIL")) ? false : true
            })
            updateGalleryPage()
            break;

          case "HEARTBEAT":
            break;

          default:
            this.consoleLog(message)
        }
      }
    }
  }

  async setSSE() {
    if(this.state.session.app.sseEnabled && (this.state.session.app.token !== "GUEST")){
      const token = await this.getToken()
      this.venusSSE = new EventSource(
        this.state.session.app.proxy+this.state.session.app.corePath+"/sse/subscribe", {
          headers: {
            "Content-Type": "application/json",
            "x-fqdn": this.state.session.app.providerPath,
            "Authorization": "Bearer "+token,
            withCredentials: true
          }
        });
      this.venusSSE.addEventListener("open", (event) => this.sseEvent(event));
      this.venusSSE.addEventListener("message", (event) => this.sseEvent(event));
      this.venusSSE.addEventListener("error", (event) => this.sseEvent(event));
    }
  }

  async setPublic(video_uid){
    this.actions.setData("request", true)
    const result = await this.requestData(`/match/${video_uid}/id`, {
      headers: {
        "Authorization": "GUEST"
      }
    }, true)
    if(result.error){
      //this.setError(result.error)
      return window.location.replace("/")
    }

    const session_app = {
      ...this.state.session.app,
      token: "GUEST",
    }
    this.setData("current", { 
      module: "video", params: [result], fallback: "login", disabled: true
    }, ()=>{
      this.actions.setData("session", { app: session_app }, ()=>{
        this.loadConfig()
      })
    })
  }

  getBackground = (game, mdKey) => {
    if(this.state[mdKey].backgrounds[game]){
      return this.state[mdKey].backgrounds[game][0]
    }
    return this.state[mdKey].backgrounds.ALL
  }

  getLabels = (_labels, mdKey) => {
    let labels = _labels
    Object.keys(this.state[mdKey].labels).forEach(key => {
      if(typeof(this.state[mdKey].labels[key]) === "object"){
        labels[key] = {}
        Object.keys(this.state[mdKey].labels[key]).forEach(skey => {
          labels[key][skey] = this.getText(this.state[mdKey].labels[key][skey])
        })
      } else {
        labels[key] = this.getText(this.state[mdKey].labels[key])
      }
    });
    return labels
  }

}
