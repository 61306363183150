import PropTypes from 'prop-types'
import styled from 'styled-components'

import DropDown from 'components/Product/Atoms/DropDown/DropDown'
import { appTheme } from "config/theme"

const fixedHeight = 30

const HorizontalTabbedMenuContainer = styled.div`
  align-items: center;
  cursor: pointer;
  flex-direction: row;
  height: ${fixedHeight}px;
  width: 100%;

  & ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    @media (max-width:600px){
      display: none;
    }
 }

  & li {
    border-top: 1px solid ${props => props.theme.colors.borderColor};
    border-left: 1px solid ${props => props.theme.colors.borderColor};
    border-bottom: 1px solid ${props => props.theme.colors.borderColor};
    align-items: center;
    color: ${props => props.theme.colors.textSecondary};
    display: flex;
    flex-direction: column;
    flex: 1;
    font-family: ${props => props.theme.fonts.secondary};
    font-size: ${props => props.theme.fonts.normal}px;
    font-weight: bold;
    gap: 11px;
    padding: ${props => props.theme.style.paddingNormal[1]}px;
    background: ${props => props.theme.colors.darkPanelBackground};
    
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    
    &:last-child {
      border-radius: 0 4px 4px 0;
      border-right: 1px solid ${props => props.theme.colors.borderColor};
    }

    &.active {
      background: ${props => props.theme.colors.midPanelBackground};
      color: ${props => props.theme.colors.textPrimary};
    }
    
    &.disabled {
      cursor: not-allowed;
      opacity: 0.2;
      &:hover {
        opacity: 0.3;
      }
    }
    
    &:hover {
      opacity: 0.9;
    }
 }
`
const MobileContainer = styled.div`
  display: none;
  @media (max-width:600px){
    display: block;
  }
`

export default function HorizontalTabbedMenu({
  menuElements, selected,
  onClick,
  ...props
}) {

  return <HorizontalTabbedMenuContainer {...props}>
    <MobileContainer>
      <DropDown id={`tabbed_menu`}
        value={selected} theme={props.theme}
        searchable={false} sortBy="index" required={true}
        onChange={(value) => onClick(value)}
        options={menuElements.map((element, index) => {
          return { value: element.id, label: element.label, index: index, key: index }
        })}
      />
    </MobileContainer>
    <ul>
      {menuElements.map((element, index) => {
        return <li key={index}
          {...props}
          id={`${props.id}_${element.id.toLowerCase()}`}
          disabled={element.disabled}
          style={menuElements.length === 1 ? {borderRadius: "4px"} : null}
          className={(element.id === selected || element.disabled) ? `${element.id === selected ? "active" : ''}${element.disabled ? " disabled" : ''}` : undefined}
          onClick={!element.disabled ? (event) => {
            event.preventDefault();
            onClick(element.id)
          } : undefined}
        >
          {element.label}
        </li>
      })}
    </ul>
  </HorizontalTabbedMenuContainer>
}

HorizontalTabbedMenu.propTypes = {
  /**
   * Menu elements
   */
  menuElements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
  })).isRequired,
  /**
   * Selected element name
   */
  selected: PropTypes.string.isRequired,
  /**
   * Component brand theme
   */
  theme: PropTypes.object.isRequired,
  /**
   * Menu element onClick handler
   */
  onClick: PropTypes.func,
}

HorizontalTabbedMenu.defaultProps = {
  value: false,
  menuElements: [],
  theme: {...appTheme},
  onClick: undefined
}