/* istanbul ignore file */

import { lazy } from 'react'

import Fallback from 'components/Fallback'

const Home = lazy(() => import('containers/Home'));
const Login = lazy(() => import('containers/Login'));
const Signup = lazy(() => import('containers/Signup'));
const Gallery = lazy(() => import('containers/Gallery'));
const Cards = lazy(() => import('containers/Cards'));
const Video = lazy(() => import('containers/Video'));
const Upload = lazy(() => import('containers/Upload'));
const Quests = lazy(() => import('containers/Quests'));
const Users = lazy(() => import('containers/Users'));
const Rewards = lazy(() => import('containers/Rewards'));
const Exercises = lazy(() => import('containers/Exercises'));
const UserSubscription = lazy(() => import('containers/UserSubscription'));
const Sharing = lazy(() => import('containers/Sharing'));

const Modules = {
  "fallback": Fallback,
  "login": Login,
  "signup": Signup,
  "gallery": Gallery,
  "cards": Cards,
  "video": Video, 
  "rewards": Rewards, 
  "quests": Quests,
  "community": Quests, 
  "users": Users, 
  "upload": Upload,
  "home": Home,
  "exercises": Exercises,
  "subscription": UserSubscription,
  "sharing": Sharing
}

export default Modules;