import React from 'react';
import { withTheme } from 'styled-components'

import { Middle } from 'components/Controls';
import Icon from 'components/Product/Atoms/Icon'

export default withTheme(React.memo((props) => {
  return (
    <Middle>
      <Icon iconKey="UserLock" style={{
        width: "100%", height: "100%", fill: props.theme.primary, verticalAlign:"middle"
      }} />
    </Middle>
  )
}));
