import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components'

import NotificationSlide, { SlideType } from 'components/Product/Molecules/NotificationSlide/NotificationSlide'
import Icon from 'components/Product/Atoms/Icon'

import { appTheme } from "config/theme";

const Row = styled.div`
  display: table;
  width: 100%;
  margin-bottom: 8px;
  &:before {
    content: "";
    display: table;
    clear: both;
  };
  &:after {
    content: "";
    display: table;
    clear: both;
  };
`
const Cell = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`

export const StyledIconOnlyView = styled.span`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 15px;

  svg {
    fill: ${props =>props.theme.colors.iconActiveColor}
  }

  border-radius: 50%;
  overflow: hidden;
  animation: 4s ${keyframes`
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(211, 210, 209, 0.4);
      box-shadow: 0 0 0 0 rgba(211, 210, 209, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 30px rgba(204,169,44, 0);
      box-shadow: 0 0 0 30px rgba(204,169,44, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }`} infinite;
`

export default function NotificationSlider({
  id, slides, sliderDisplayTimeInSec, iconView,
  ...props
}) {
  const [currentSlideIndex, setcurrentSlideIndex] = useState(0)

  useEffect(() => {
    let timer

    const startAnimation = () => {
      return setInterval(() => {
        setcurrentSlideIndex(idx => {
          const nextIndex = idx + 1;
          if (nextIndex >= slides.length)
            return 0
          return nextIndex
        })
      }, sliderDisplayTimeInSec * 1000)
    }
    if (slides.length > 0) {
      timer = startAnimation()
    }

    return () => {
      if (timer) {
        clearInterval(timer)
      }
    };
  }, [sliderDisplayTimeInSec, slides])

  if(slides.length === 0)
    return null
    
  if (iconView) {
    return <Row id={id}>
      <Cell>
        <StyledIconOnlyView theme={props.theme}>
          <Icon iconKey="ExclamationCircle" style={{width: props.theme.style.iconSize, height: props.theme.style.iconSize, verticalAlign:"middle" }} />
        </StyledIconOnlyView>
      </Cell>
    </Row>
  }

  return <NotificationSlide slide={slides[currentSlideIndex]} {...props} id={`${id}_${currentSlideIndex}`} ></NotificationSlide>
}

NotificationSlider.propTypes = {
  /**
   * Component ID
   */
  id: PropTypes.string,
  /**
   * In case there are multiple sliders, how much time is one displayed
   */
  sliderDisplayTimeInSec: PropTypes.number,
  /**
   * Language code needed to display the proper slider
   */
  language: PropTypes.string,
  /**
   * Component brand theme
   */
  theme: PropTypes.object.isRequired,
  /**
   * Slider definitions
   */
  slides: PropTypes.arrayOf(
    PropTypes.arrayOf(
      SlideType
    )
  ),
  /**
   * Is the current state a "icon only" view?
   */
  iconView: PropTypes.bool,
}

NotificationSlider.defaultProps = {
  sliderDisplayTimeInSec: undefined,
  language: undefined,
  theme: {...appTheme},
  slides: [],
  iconView: false,
}
