import styled from 'styled-components'

export const AuthFormContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 16px;
	justify-content: center;
	margin: 0 1rem;

	@media screen and (max-width: 800px) {
		margin: 0 1.5rem;
	}
`

export const EulaContainer = styled.div`
	margin: 12px 0;
	display: flex;
	overflow: hidden;
	transition: 0.3s;

	animation: fadeinout 0.6s;
	@keyframes fadeinout {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
`
