import styled, { keyframes } from 'styled-components'
import { Link as BaseLink } from "wouter";
import { Select as DropdownSelect } from "react-dropdown-select";

export const Modal = styled.div`
  z-index: 50;
  position: fixed;
  left: 0;
  top: 0;
  width:100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.6);
  padding: ${props => props.padding ? props.padding : `10px 5px`};
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  ${props => props.background_image ? `background: url(${props.background_image});${props.background_settings ? props.background_settings: ""};` : null}
`

export const Middle = styled.div`
  ${props => props.zIndex ? `z-index:`+props.zIndex+`;` : ``}
  ${props => props.background ? `background:`+props.background+`;` : ``}
  ${props => props.opacity ? `opacity:`+props.opacity+`;` : ``}
  ${props => props.padding ? `padding:`+String(props.padding).split(",").join("px ")+`px;` : ``}
  ${props => props.round ? `border-radius:`+props.round+`px;` : ``}
  @media only screen and (min-width: 601px){
    margin: 0px;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    -ms-transform:translate(-50%,-50%)
  }
`

export const MenuBar = styled.div`
  z-index: 5;
  position: fixed;
  top: 0;
  width: 100%;
  ${props => props.app ? `height:`+props.app.topMenuHeight+`px!important;` : ``}
  padding: 0px 8px;
  background-color: ${props => props.theme.secondary};
  ${props => props.shadow ? 
    `box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);` : ``}
  font-size: 14px;
  overflow:hidden;
  display: table;
  @media (min-width:601px){
    display:none;
  }
  &:before {
    content: "";
    display: table;
    clear: both;
  };
  &:after {
    content: "";
    display: table;
    clear: both;
  };
  -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; 
  -moz-user-select: none; -ms-user-select: none; user-select: none;
`

export const MenuItem = styled.div`
  text-align: center;
  padding: 8px 2px;
  font-weight: bold;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  ${props => props.right ? `float: right;` : ``}
  ${props => props.left ? `float: left;` : ``}
  ${props => props.align ? `text-align: `+props.align+`;` : ``}
  ${props => props.padding ? `padding:`+String(props.padding).split(",").join("px ")+`px;` : ``}
  ${props => props.margin ? `margin:`+String(props.margin).split(",").join("px ")+`px;` : ``}
  ${props => props.center ? `text-align: center;` : ``}
  ${props => props.selected ? (props.menubar) ? 
    `background-color: ${props.theme.secondary};border-radius: 6px;`
    :`border-bottom: 1px solid `+props.theme.primary+`;` : ``}
  color: rgba(${props => props => (props.selected || props.primary) ? props.theme.text : parseInt(props.theme.text.substring(1,3),16)+","+parseInt(props.theme.text.substring(3,5),16)+","+parseInt(props.theme.text.substring(5,7),16)},0.6);
  ${props => props.primary ? 
    `background-color:`+props.theme.primary+`;
     border-radius: 6px; font-size: 13px;` 
    : ``}
  &:hover {
    color: ${props => props.theme.text};
  };
  ${props => props.width ? `width: `+props.width+`;` : `width: auto;`}
  ${props => props.height ? `height: `+props.height+`;` : ``}
  ${props => props.display ? `display: `+props.display+`;` : `display: table-cell;`}
  cursor: ${props => props.cursor ? props.cursor : `pointer`};
  ${props => props.hideSmall ? 
    `@media (max-width:600px){ display:none!important; };` : ``}
  ${props => props.hideMedium ? 
    `@media (max-width:992px) and (min-width:601px){ display:none!important; };` : ``}
  ${props => props.hideLarge ? 
    `@media (min-width:993px){ display:none!important; };` : ``}
  ${props => props.hide ? 
    `display:none!important;` : ``}
  ${props => props.block ? `width: 100%;` : ``}
  ${props => props.borderTop ? `border-top: 1px solid `+props.borderTop+`;` :``}
  ${props => props.borderBottom ? `border-bottom: 1px solid `+props.borderBottom+`;` :``}
`
export const SideBar = styled.div`
  z-index: 60;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  @media (max-width:600px){
    display: ${props => props.show ? `block`:`none`};
  }
  @media (max-width:992px) and (min-width:601px){ 
    max-width: ${props => props.app.sideIconMenuWidth}px!important; 
  };
  @media (min-width:993px) {
    width: ${props => props.iconView ? props.app.sideIconMenuWidth : props.app.sideMenuWidth}px;
  }
  /*
  animation: 0.4s ${keyframes`from{left:-${props => props.app.sideMenuWidth ? props.app.sideMenuWidth+"px" : `250px`}; opacity:0;} to{left:0; opacity:1;}`};
  */
  background-color: ${props => props.theme.secondary};
  box-shadow:0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; 
  -moz-user-select: none; -ms-user-select: none; user-select: none;
`

export const SideBarPanel = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px 0px 50px;
  background-color: rgba(${props => parseInt(props.theme.highlight.substring(1,3),16)+","+parseInt(props.theme.highlight.substring(3,5),16)+","+parseInt(props.theme.highlight.substring(5,7),16)},0.2);
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: bold;
`

export const SideMenu = styled.div`
  z-index: 60;
  ${props => props.top ? `top:`+props.top+`px!important;` : ``}
  position: fixed;
  right: 0px;
  margin-right: 1px;
  padding: 8px 8px;
  background-color: ${props => props.theme.highlight};
  border: 0.5px solid rgba(${props => parseInt(props.theme.text.substring(1,3),16)+","+parseInt(props.theme.text.substring(3,5),16)+","+parseInt(props.theme.text.substring(5,7),16)},0.5);
  border-radius: 8px;
  font-size: 14px;
  min-width: 160px;
  overflow: auto;
  overflow-y: auto;
  overflow-x: none;
  ${props => props.shadow ? 
    `box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);` : ``}
  text-align: left;
  -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; 
  -moz-user-select: none; -ms-user-select: none; user-select: none;
  ${props => props.hideSmall ? 
    `@media (max-width:600px){ display:none!important; };` : ``}
  ${props => props.hideMedium ? 
    `@media (max-width:992px) and (min-width:601px){ display:none!important; };` : ``}
  ${props => props.hideLarge ? 
    `@media (min-width:993px){ display:none!important; };` : ``}
`

export const Dropdown = styled.div`
  position: relative; 
  display: inline-block; 
  cursor: pointer;
  ${props => props.right ? `float: right;` : ``}
`
export const DropdownContent = styled.div`
  cursor: auto;
  background-color: ${props => props.theme.highlight};
  border: 0.5px solid rgba(${props => parseInt(props.theme.text.substring(1,3),16)+","+parseInt(props.theme.text.substring(3,5),16)+","+parseInt(props.theme.text.substring(5,7),16)},0.5);
  border-radius: 8px;
  box-shadow:0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19);
  position: absolute;
  margin: 4px 0px 0px; 
  padding: 8px 8px;
  z-index: 10;
`

export const DropMenuItem = styled(MenuItem)`
  text-align: left;
  width: 100%;
  display: table-row;
  padding: 12px 2px;
  ${props => props.selected ? `background-color: rgba(`+parseInt(props.theme.separator.substring(1,3),16)+","+parseInt(props.theme.separator.substring(3,5),16)+","+parseInt(props.theme.separator.substring(5,7),16)+`,0.5);` : ``}
`

export const Dialog = styled.div`
  border-radius: 0px;
  border: 1px solid ${props => props.theme.primary};
  box-shadow:0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19);
  animation: 0.8s ${keyframes`from{transform:scale(0);} to{transform:scale(1);}`} ease-out;
  max-width: calc(768px + 16px * 2);
  margin: 0 auto;
  @media (max-width:600px){
    margin: 5px;
  };
  background-color: ${props => props.theme.secondary};
  ${props => props.padding ? `padding:`+String(props.padding).split(",").join("px ")+`px;` : ``}
  ${props => props.margin ? `margin:`+String(props.margin).split(",").join("px ")+`px;` : ``}
  ${props => props.minWidth ? 
  `@media (min-width:`+props.minWidth+`px){
     min-width: `+props.minWidth+`px;
   };` : ``}
  ${props => props.maxWidth ? `max-width:`+props.maxWidth+`px;` :``}
`

export const MainPage = styled.div`
  position: absolute; // TODO
  bottom: 0;
  width: 100%;
  transition: margin-left .4s;
  top: 0;
  // z-index: -1; // TODO
  ${props => props.app ? 
    `@media (max-width:600px){ 
      top:`+props.app.topMenuHeight+`px!important; 
    }` : ``}
  .zoomOut {
    animation: 0.8s ${keyframes`from{transform:scale(1);} to{transform:scale(0);}`} ease-out;
  }
  .pulse {
    border-radius: 50%;
    animation: 4s ${keyframes`
      0% {
        -moz-box-shadow: 0 0 0 0 rgba(211, 210, 209, 0.4);
        box-shadow: 0 0 0 0 rgba(211, 210, 209, 0.4);
      }
      70% {
        -moz-box-shadow: 0 0 0 30px rgba(204,169,44, 0);
        box-shadow: 0 0 0 30px rgba(204,169,44, 0);
      }
      100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      }`} infinite;
  }
  .update {
    color: ${props => props.theme.success};
    &:hover {
      color: ${props => props.theme.highlight};
    }
  }
  .cancel {
    color: ${props => props.theme.error};
    &:hover {
      color: ${props => props.theme.highlight};
    }
  }
`

export const PageBox = styled.div`
  margin-left: 0;
  @media (min-width:601px) {
    ${props => props.app ? 
      `margin-left:`+props.app.sideIconMenuWidth+`px;` : ``}
  }
  @media (min-width:993px) {
    ${props => props.app && props.iconView ? 
      `margin-left:`+ props.app.sideIconMenuWidth +`px;` : `margin-left:`+ props.app.sideMenuWidth +`px;`}
  }
`

export const Group = styled.div`
  .s1 { float:left; width: 8.33333%; } .s2 { float:left; width: 16.66666%; } .s3 { float:left; width: 24.99999%; }
  .s4 { float:left; width: 33.33333%; } .s5 { float:left; width: 41.66666%; } .s6 { float:left; width: 49.99999%; }
  .s7 { float:left; width: 58.33333%; } .s8 { float:left; width: 66.66666%; } .s9 { float:left; width: 74.99999%; }
  .s10 { float:left; width: 83.33333%; } .s11 { float:left; width: 91.66666%; } .s12 { float:left; width:99.99999%; }
  .half,.third,.quarter,.twothird{ float:left; width:100% }

  @media (max-width:600px){
    .mobile{ display:block; width:100%!important }
  }
  @media (max-width:993px) {
    .tablet{ display:block; width:100%!important }
  }
  @media (min-width:601px) {
    .m1 { float:left; width: 8.33333%; } .m2 { float:left; width: 16.66666%; } .m3 { float:left; width:24.99999%; }
    .m4 { float:left; width: 33.33333%; } .m5 { float:left; width: 41.66666%; } .m6 { float:left; width: 49.99999%; }
    .m7 { float:left; width: 58.33333%; } .m8 { float:left; width: 66.66666%; } .m9 { float:left; width: 74.99999%; }
    .m10 { float:left; width: 83.33333%; } .m11 { float:left; width: 91.66666%; } .m12 { float:left; width: 99.99999%; }
    .half { width:49.99999% } .third{ width:33.33333% } .quarter{ width:24.99999% } .twothird{ width:66.66666% } }
  }
  @media (min-width:993px) {
    .l1 { float:left; width: 8.33333%; } .l2 { float:left; width: 16.66666%; } .l3 { float:left; width: 24.99999%; }
    .l4 { float:left; width: 33.33333%; } .l5 { float:left; width: 41.66666%; } .l6 {float:left; width: 49.99999%; }
    .l7 { float:left; width: 58.33333%; } .l8 { float:left; width: 66.66666%; } .l9 { float:left; width: 74.99999%; }
    .l10 { float:left; width:83.33333%; } .l11 { float:left; width: 91.66666%; } .l12 { float:left; width: 99.99999%; }
  }
  &:hover {
    ${props => (props.hoverBackground) ? `background-color: `+props.hoverBackground+`;` : ``}
    ${props => (props.hoverColor) ? `color: `+props.hoverColor+`;` : ``}
  };
  ${props => props.width ? `width: `+props.width+`;` : ``}
  ${props => props.minWidth ? `min-width: `+props.minWidth+`;` : ``}
  ${props => props.maxWidth ? `max-width: `+props.maxWidth+`!important;` : ``}
  ${props => props.height ? `height: `+props.height+`;` : ``}
  ${props => props.maxHeight ? `max-height: `+props.maxHeight+`!important;` : ``}
  ${props => props.section ? `margin-top: 16px!important; margin-bottom: 16px!important;` : ``}
  ${props => props.sectionBottom ? `margin-bottom: 16px!important;` : ``}
  ${props => props.sectionTop ? `margin-top: 16px!important;` : ``}
  ${props => props.sectionTopLarge ? `margin-top: 24px!important;` : ``}
  ${props => props.sectionTopXLarge ? `margin-top: 32px!important;` : ``}
  ${props => props.sectionBottomXLarge ? `margin-bottom: 32px!important;` : ``}
  ${props => props.sectionSmall ? `padding-top: 8px!important; padding-bottom: 8px!important;` : ``}
  ${props => props.sectionSmallBottom ? `padding-bottom: 8px!important;` : ``}
  ${props => props.sectionSmallTop ? `padding-top: 8px!important;` : ``}
  ${props => props.container ? 
    `padding: 0.01em 16px; &:after, &:before { content: ""; display: table; clear: both }; @media (max-width:600px){ padding: 0px 8px; }; ` : ``}
  ${props => props.mobileContainer ? 
    `padding: 0.01em 16px; &:after, &:before { content: ""; display: table; clear: both }; @media (max-width:600px){ padding: 0px; }; ` : ``}
  ${props => props.halfPadding ? 
    `padding: 0px; @media (min-width:601px){ padding: 0px 0px 0px 16px; }; ` : ``}
  ${props => props.right ? `float: right;` : ``}
  ${props => props.left ? `float: left;` : ``}
  ${props => props.align ? `text-align: `+props.align+`;` : ``}
  ${props => props.top ? `vertical-align: top;` : ``}
  ${props => props.middle ? `vertical-align: middle;` : ``}
  ${props => props.bottom ? `vertical-align: bottom;` : ``}
  ${props => props.center ? `text-align: center;` : ``}
  ${props => props.centered ? `margin: auto;` : ``}
  ${props => props.padding ? `padding:`+String(props.padding).split(",").join("px ")+`px;` : ``}
  ${props => props.margin ? `margin:`+String(props.margin).split(",").join("px ")+`px;` : ``}
  ${props => props.block ? `width: 100%;` : ``}
  ${props => props.bold ? `font-weight: bold;` : ``}
  ${props => props.outline ? `outline: 1px solid `+props.theme.text+`;` : ``}
  ${props => props.primaryOutline ? `outline: 1px solid `+props.theme.primary+`;` : ``}
  ${props => props.responsive ? 
    `display:block; overflow-x:auto;` : ``}
  ${props => props.hideSmall ? 
    `@media (max-width:600px){ display:none!important; };` : ``}
  ${props => props.hideMedium ? 
    `@media (max-width:992px) and (min-width:601px){ display:none!important; };` : ``}
  ${props => props.hideLarge ? 
    `@media (min-width:993px){ display:none!important; };` : ``}
  ${props => props.background ? `background-color: `+props.background+`;` : ``}
  ${props => props.highlight ? `background-color: `+props.theme.highlight+`;` : ``}
  ${props => props.round ? `border-radius:`+props.round+`px;` : ``}
  ${props => props.roundBottom ? `border-bottom-left-radius:`+props.roundBottom+`px;border-bottom-right-radius:`+props.roundBottom+`px;` : ``}
  ${props => props.roundTop ? `border-top-left-radius:`+props.roundTop+`px;border-top-right-radius:`+props.roundTop+`px;` : ``}
  ${props => props.circle ? `border-radius: 50%;` : ``}
  ${props => props.paddingTiny ? `padding: 2px 4px; @media (max-width:600px){ padding: 1px 2px; };` : ``}
  ${props => props.paddingSmall ? `padding: 4px 8px; @media (max-width:600px){ padding: 2px 4px; };` : ``}
  ${props => props.paddingNormal ? `padding: 8px 16px; @media (max-width:600px){ padding: 4px 8px; };` : ``}
  ${props => props.paddingLarge ? `padding: 12px 24px; @media (max-width:600px){ padding: 6px 12px; };` : ``}
  ${props => props.paddingXLarge ? `padding: 16px 32px; @media (max-width:600px){ padding: 8px 16px; };` : ``}
  ${props => props.marginTiny ? `margin: 2px 4px!important; @media (max-width:600px){ margin: 1px 2px!important;; };` : ``}
  ${props => props.marginSmall ? `margin: 4px 8px!important; @media (max-width:600px){ margin: 2px 4px!important;; };` : ``}
  ${props => props.marginNormal ? `margin: 8px 16px!important; @media (max-width:600px){ margin: 4px 8px!important;; };` : ``}
  ${props => props.marginLarge ? `margin: 12px 24px!important; @media (max-width:600px){ margin: 6px 12px!important;; };` : ``}
  ${props => props.marginXLarge ? `margin: 16px 32px!important; @media (max-width:600px){ margin: 8px 16px!important;; };` : ``}
  ${props => props.topMarginTiny ? `margin-top: 2px!important; @media (max-width:600px){ margin-top: 1px!important;; };` : ``}
  ${props => props.topMarginSmall ? `margin-top: 4px!important; @media (max-width:600px){ margin-top: 2px!important;; };` : ``}
  ${props => props.topMarginNormal ? `margin-top: 8px!important; @media (max-width:600px){ margin-top: 4px!important;; };` : ``}
  ${props => props.topMarginLarge ? `margin-top: 12px!important; @media (max-width:600px){ margin-top: 6px!important;; };` : ``}
  ${props => props.topMarginXLarge ? `margin-top: 16px!important; @media (max-width:600px){ margin-top: 8px!important;; };` : ``}
  ${props => props.bottomMarginTiny ? `margin-bottom: 2px!important; @media (max-width:600px){ margin-bottom: 1px!important;; };` : ``}
  ${props => props.bottomMarginSmall ? `margin-bottom: 4px!important; @media (max-width:600px){ margin-bottom: 2px!important;; };` : ``}
  ${props => props.bottomMarginNormal ? `margin-bottom: 8px!important; @media (max-width:600px){ margin-bottom: 4px!important;; };` : ``}
  ${props => props.bottomMarginLarge ? `margin-bottom: 12px!important; @media (max-width:600px){ margin-bottom: 6px!important;; };` : ``}
  ${props => props.bottomMarginXLarge ? `margin-bottom: 16px!important; @media (max-width:600px){ margin-bottom: 8px!important;; };` : ``}
  ${props => props.primaryBorder ? `border: 1px solid `+props.theme.primary+`;` :``}
  ${props => props.border ? `border: 1px solid `+props.border+`;` :``}
  ${props => props.borderTop ? `border-top: 1px solid `+props.borderTop+`;` :``}
  ${props => props.borderLeft ? `border-left: 1px solid `+props.borderLeft+`;` :``}
  ${props => props.borderRight ? `border-right: 1px solid `+props.borderRight+`;` :``}
  ${props => props.borderBottom ? `border-bottom: 1px solid `+props.borderBottom+`;` :``}
  ${props => props.leftbar ? `border-left: 6px solid `+props.theme.text+`;` : ``}
  ${props => props.dottedTop ? `border-top: 1px dotted `+props.theme.text+`;` : ``}
  ${props => props.dottedBottom ? `border-bottom: 1px dotted `+props.theme.text+`;` : ``}
  ${props => props.bottomSeparator ? `border-bottom: ${props.bottomSeparator[0]}px solid rgba(${parseInt(props.theme.separator.substring(1,3),16)},${parseInt(props.theme.separator.substring(3,5),16)},${parseInt(props.theme.separator.substring(5,7),16)},${props.bottomSeparator[1]});`: ``}
  ${props => props.line ? `line-height: `+props.line+`;` : ``}
  ${props => props.sticky ? `position: -webkit-sticky; position: sticky; top: `+props.sticky+`;z-index: 5;` : ``}
  ${props => props.stickyPage ? 
    `position: -webkit-sticky; position: sticky; top: `+props.stickyPage+`;z-index: 5;
    @media (min-width:601px){ top:0!important; };` : ``}
  ${props => props.opacity ? `opacity:`+props.opacity+`;` : ``}
  ${props => props.cursor ? `cursor: `+props.cursor+`;` : ``}
  ${props => props.transition ? `transition: `+props.transition+`;` : ``}
  ${props => props.color ? `color: `+props.color+`;` : ``}
`
export const Bottom = styled(Group)`
  position: fixed;
  width: 100%;
  z-index: 1;
  bottom: 0;
`
export const Page = styled(Group)`
  padding-bottom: 20px;
  ${props => props.withStickyFooter ? 
    `padding-bottom: 80px;` : ``}
  ${props => props.maxWidth ? 
  `max-width: calc(`+props.maxWidth+`px + 16px * 2);
  margin: 0 auto;
  @media (max-width:600px){
    margin: 2px;
  };` : ``}
`

export const Row = styled(Group)`
  display: table;
  &:before {
    content: "";
    display: table;
    clear: both;
  };
  &:after {
    content: "";
    display: table;
    clear: both;
  };
`

export const TRow = styled(Group)`
  display: table-row;
`

export const Cell = styled(Group)`
  display: table-cell;
  ${props => props.hide ? 
    `display:none!important;` : `display:table-cell;`}

  ${props => props.selected ? (props.menubar) ? 
    `background-color: ${props.theme.secondary};border-radius: 6px;`
    :`border-bottom: 1px solid `+props.theme.primary+`;` : ``}
`

export const Label = styled.span`
  &:hover {
    ${props => (props.hoverColor && !props.highlight) ? `color: `+props.theme.highlight+`;` : ``}
    ${props => (props.hoverPrimary) ? `color: `+props.theme.primary+`;` : ``}
  };
  ${props => props.displayBlock ? `display: block;` : ``}
  ${props => props.uppercase ? `text-transform: uppercase;` : ``}
  ${props => props.nowrap ? `white-space: nowrap;` : ``}
  ${props => props.align ? `text-align: `+props.align+`;` : ``}
  ${props => props.right ? `float: right;` : ``}
  ${props => props.left ? `float: left;` : ``}
  ${props => props.top ? `vertical-align: top;` : ``}
  ${props => props.middle ? `vertical-align: middle;` : ``}
  ${props => props.bottom ? `vertical-align: bottom;` : ``}
  ${props => props.center ? `text-align: center;` : ``}
  ${props => props.centered ? `margin: auto;` : ``}
  ${props => props.padding ? `padding:`+String(props.padding).split(",").join("px ")+`px;` : ``}
  ${props => props.paddingTiny ? `padding: 2px 4px; @media (max-width:600px){ padding: 1px 2px; };` : ``}
  ${props => props.paddingSmall ? `padding: 4px 8px; @media (max-width:600px){ padding: 2px 4px; };` : ``}
  ${props => props.paddingNormal ? `padding: 8px 16px; @media (max-width:600px){ padding: 4px 8px; };` : ``}
  ${props => props.paddingLarge ? `padding: 12px 24px; @media (max-width:600px){ padding: 6px 12px; };` : ``}
  ${props => props.margin ? `margin:`+String(props.margin).split(",").join("px ")+`px;` : ``}
  ${props => props.block ? `width: 100%;` : ``}
  ${props => props.tiny ? `font-size:10px!important;` : ``}
  ${props => props.small ? `font-size:12px!important;` : ``}
  ${props => props.medium ? `font-size:15px!important;` : ``}
  ${props => props.large ? `font-size:18px!important;` : ``}
  ${props => props.xlarge ? `font-size:24px!important;` : ``}
  ${props => props.xxlarge ? `font-size:33px!important;` : ``}
  ${props => props.xxxlarge ? `font-size:48px!important;` : ``}
  ${props => props.jumbo ? `font-size:64px!important;` : ``}
  ${props => props.fontSize ? `font-size:`+props.fontSize+`px!important;` : ``}
  ${props => props.bold ? `font-weight: bold;` : ``}
  ${props => props.italic ? `font-style: italic;` : ``}
  ${props => props.underline ? `text-decoration: underline; cursor: pointer;` : ``}
  ${props => props.pointer ? `cursor: pointer;` : ``}
  ${props => props.primaryTextColor ? `color: `+props.theme.text+`;` : ``}
  ${props => props.color ? `color: `+props.color+`;` : ``}
  ${props => props.primary ? `color: `+props.theme.primary+`;` : ``}
  ${props => props.border ? `border: 1.5px solid `+props.theme.text+`; border-radius: 12px; ` :``}
  ${props => props.primaryBorder ? `border: 1px solid `+props.theme.primary+`;` :``}
  ${props => props.background ? `background-color: `+props.background+`;` : ``}
  ${props => props.highlight ? `background-color: `+props.theme.highlight+`;` : ``}
  ${props => props.letterSpacing ? `letter-spacing: `+props.letterSpacing+`;` : ``}
  ${props => props.opacity ? `opacity:`+props.opacity+`;` : ``}
  ${props => props.weight ? `font-weight:`+props.weight+`;` : ``}
  ${props => props.inverseColor ? `filter: invert(1) hue-rotate(180deg);` : ``}
`

export const Link = styled(BaseLink)`
  ${props => props.primary ? `color: `+props.theme.primary+`;` : `color: `+props.theme.text+`;`}
  ${props => props.secondary ? `color: `+props.theme.secondary+`;` : `color: `+props.theme.text+`;`}
  ${props => props.color ? `color: `+props.color+`;` : `color: `+props.theme.text+`;`}
  ${props => props.tiny ? `font-size:10px!important;` : ``}
  ${props => props.small ? `font-size:12px!important;` : ``}
  ${props => props.medium ? `font-size:15px!important;` : ``}
  ${props => props.large ? `font-size:18px!important;` : ``}
  ${props => props.xlarge ? `font-size:24px!important;` : ``}
  ${props => props.xxlarge ? `font-size:33px!important;` : ``}
  ${props => props.xxxlarge ? `font-size:48px!important;` : ``}
  ${props => props.jumbo ? `font-size:64px!important;` : ``}
  ${props => props.fontSize ? `font-size:`+props.fontSize+`px!important;` : ``}
`

export const Separator = styled.hr`
  border: 2px solid rgba(${props => parseInt(props.theme.text.substring(1,3),16)+","+parseInt(props.theme.text.substring(3,5),16)+","+parseInt(props.theme.text.substring(5,7),16)},0.2);
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  ${props => props.hideSmall ? 
    `@media (max-width:600px){ display:none!important; };` : ``}
  ${props => props.hideMedium ? 
    `@media (max-width:992px) and (min-width:601px){ display:none!important; };` : ``}
  ${props => props.hideLarge ? 
    `@media (min-width:993px){ display:none!important; };` : ``}
  ${props => props.background ? `background-color: `+props.background+`;` : ``}
`

export const SmallSeparator = styled.hr`
  border: 1px solid ${props => props.color ? props.color : props.theme.separator};
  float: ${props => props.float ? props.float : `left`};
  width: 430px;
  height: 0px;
`

export const Gap = styled.div`
  width: 100%;
  ${props => props.hideSmall ? 
    `@media (max-width:600px){ display:none!important; };` : ``}
  ${props => props.hideMedium ? 
    `@media (max-width:992px) and (min-width:601px){ display:none!important; };` : ``}
  ${props => props.hideLarge ? 
    `@media (min-width:993px){ display:none!important; };` : ``}
  height: ${props => props.height ? props.height : `6`}px!important;
`

export const Line = styled.hr`
  height: ${props => props.size ? props.size : `1`}px;
  background-color: ${props => props.color ? props.color : props.theme.text};
  border: none;
  margin-top: 0;
`

export const TitleLine = styled.hr`
  height: ${props => props.height ? props.height : `4px`};
  width: ${props => props.width ? props.width : `40px`};
  margin: ${props => props.margin ? props.margin : `9px 10px 2px 10px`};
  background-color: ${props => props.color ? props.color : props.theme.text};
  border-style: none;
`

export const Button = styled.button`
  font-family: ${props => props.theme.fontFamily};
  font-weight: bold;
  font-size: 13px;
  display:inline-block;
  padding: ${props => props.padding ? String(props.padding).split(",").join("px ")+`px;` : `8px 24px`};
  ${props => props.margin ? `margin:`+String(props.margin).split(",").join("px ")+`px;` : ``}
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  text-transform: uppercase;
  color: ${props => props.primary ? props.theme.text : props.theme.separator};
  background-color:${props => props.primary ? props.theme.primary : props.theme.text};
  text-align: center;
  ${props => props.align ? `text-align: `+props.align+`;` : ``}
  cursor: ${props => props.cursor ? props.cursor : `pointer`};
  white-space: ${props => props.wrap ? `normal` : `nowrap`};
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  border-image: linear-gradient(to right,${props => props.theme.primary},${props => props.theme.separator}) 1 stretch;
  border:0.9px solid ${props => props.primary ? props.theme.primary : props.theme.text};
  border-radius: ${props => props.radius ? props.radius : `12`}px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  &:hover {
    color: ${props => props.theme.highlight};
  };
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  };
  &:disabled:hover {
    box-shadow:none;
  };
  &.required {
    
  }
  ${props => props.block ? `display:block; width:100%;` : ``}
  ${props => props.borderButton ? `border: 1.5px solid ${props.borderButton}; background: none; color: ${props.borderButton};` :``}
`

export const Select = styled.select`
  border-radius: 3px;
  border: 1px solid rgba(${props => 
    parseInt(((props.missing)?props.theme.error.substring(1,3):props.theme.text.substring(1,3)),16)+","+
    parseInt(((props.missing)?props.theme.error.substring(3,5):props.theme.text.substring(3,5)),16)+","+
    parseInt(((props.missing)?props.theme.error.substring(5,7):props.theme.text.substring(5,7)),16)},0.5);
  background-color: rgba(${props => parseInt(props.theme.highlight.substring(1,3),16)+","+parseInt(props.theme.highlight.substring(3,5),16)+","+parseInt(props.theme.highlight.substring(5,7),16)},0.1);
  color: ${props => props.focus ? props.theme.primary : (props => props.color)?props.color:props.theme.text };
  ${props => props.align ? `text-align: `+props.align+`;` : ``}
  font-size: 13px;
  padding: 8px;
  width: 100%;
  ${props => props.auto ? `width: auto;` : ``}
  ${props => props.bold ? `font-weight: bold;` : ``}
  &:disabled {
    opacity: ${props => props.opacity ? props.opacity : `0.5`};
    color: ${props => props.color ? props.color : props.theme.text };
  };
  opacity: ${props => props.opacity ? props.opacity : `1`};
  ${props => props.uppercase ? `text-transform: uppercase;` : ``}
`

export const Option = styled.option`
  background-color: ${props => props.theme.secondary}!important;
  color: ${props => props.color ? props.color : props.theme.text};
  font-size: 13px;
  &:disabled {
    opacity: 0.5;
  };
`

export const DropSelect = styled(DropdownSelect)`
  border-radius: 3px;
  border: 1px solid rgba(${props => 
    parseInt(((props.missing)?props.theme.error.substring(1,3):props.theme.text.substring(1,3)),16)+","+
    parseInt(((props.missing)?props.theme.error.substring(3,5):props.theme.text.substring(3,5)),16)+","+
    parseInt(((props.missing)?props.theme.error.substring(5,7):props.theme.text.substring(5,7)),16)},0.5);
  background-color: rgba(${props => parseInt(props.theme.highlight.substring(1,3),16)+","+parseInt(props.theme.highlight.substring(3,5),16)+","+parseInt(props.theme.highlight.substring(5,7),16)},0.1);
  color: ${props => props.focus ? props.theme.primary : (props => props.color)?props.color:props.theme.text };
  ${props => props.align ? `text-align: `+props.align+`;` : ``}
  font-size: 13px;
  min-height: 34px!important;
  ${props => props.auto ? `width: auto;` : ``}
  ${props => props.bold ? `font-weight: bold;` : ``}
  &:disabled {
    opacity: ${props => props.opacity ? props.opacity : `0.5`};
    color: ${props => props.color ? props.color : props.theme.text };
  };
  opacity: ${props => props.opacity ? props.opacity : `1`};
  ${props => props.uppercase ? `text-transform: uppercase;` : ``}
  .react-dropdown-select-dropdown {
    width: auto;
    white-space: nowrap;
    background-color: ${props => props.theme.secondary};
    color: ${props => props.focus ? props.theme.primary : (props => props.color)?props.color:props.theme.text };
  }
  .react-dropdown-select-item {
    border: none;
    background-color: ${props => props.theme.secondary}!important;
    color: ${props => props.color ? props.color : props.theme.text};
    font-size: 13px;
    min-height: 20px;
    &:disabled {
      opacity: 0.5;
    };
    :hover {
      color: ${props => props.theme.highlight};
   }
  }
  .react-dropdown-select-input {
    color: ${props => props.theme.primary};
  }
  .react-dropdown-select-item.react-dropdown-select-item-selected,
  .react-dropdown-select-item.react-dropdown-select-item-active {
    color: ${props => props.theme.primary};
    border: none;
  }
`

export const DropSelectElement = styled.div`
  padding: 0px 3px;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
  }
  input {
    margin-right: 10px;
  }
`;

export const Input = styled.input`
  border-radius: 3px;
  border: 1px solid rgba(${props => 
    parseInt(((props.missing)?props.theme.error.substring(1,3):props.theme.text.substring(1,3)),16)+","+
    parseInt(((props.missing)?props.theme.error.substring(3,5):props.theme.text.substring(3,5)),16)+","+
    parseInt(((props.missing)?props.theme.error.substring(5,7):props.theme.text.substring(5,7)),16)},0.5);
  background-color: rgba(${props => parseInt(props.theme.highlight.substring(1,3),16)+","+parseInt(props.theme.highlight.substring(3,5),16)+","+parseInt(props.theme.highlight.substring(5,7),16)},0.1);
  color: ${props => props.focus ? props.theme.primary : props.theme.text };
  ${props => props.align ? `text-align: `+props.align+`;` : ``}
  font-size: 13px;
  padding: 8px;
  display: ${props => props.display ? props.display : `block` };
  width: ${props => props.width ? props.width : `100%` };
  ${props => props.maxWidth ? `max-width: `+props.maxWidth+`;` : ``}
  &:placeholder {
    color: rgba(${props => parseInt(props.theme.text.substring(1,3),16)+","+parseInt(props.theme.text.substring(3,5),16)+","+parseInt(props.theme.text.substring(5,7),16)},0.5);
  };
  &:-ms-input-placeholder {
    color: rgba(${props => parseInt(props.theme.text.substring(1,3),16)+","+parseInt(props.theme.text.substring(3,5),16)+","+parseInt(props.theme.text.substring(5,7),16)},0.5);
  };
  &:-ms-input-placeholder {
    color: rgba(${props => parseInt(props.theme.text.substring(1,3),16)+","+parseInt(props.theme.text.substring(3,5),16)+","+parseInt(props.theme.text.substring(5,7),16)},0.5);
  };
  &:disabled {
    opacity: 0.5;
  };
`

export const TextArea = styled.textarea`
  border-radius: 3px;
  border: 1px solid rgba(${props => 
    parseInt(((props.missing)?props.theme.error.substring(1,3):props.theme.text.substring(1,3)),16)+","+
    parseInt(((props.missing)?props.theme.error.substring(3,5):props.theme.text.substring(3,5)),16)+","+
    parseInt(((props.missing)?props.theme.error.substring(5,7):props.theme.text.substring(5,7)),16)},0.5);
  background-color: rgba(${props => parseInt(props.theme.highlight.substring(1,3),16)+","+parseInt(props.theme.highlight.substring(3,5),16)+","+parseInt(props.theme.highlight.substring(5,7),16)},0.1);
  color: ${props => props.focus ? props.theme.primary : props.theme.text };
  ${props => props.align ? `text-align: `+props.align+`;` : ``}
  font-size: 13px;
  padding: 8px;
  ${props => props.align ? `text-align: `+props.align+`;` : ``}
  ${props => props.height ? `height: `+props.height+`;` : `100%;`}
  display: block;
  width: ${props => props.width ? props.width : `100%` };
  opacity: 1;
  &:placeholder {
    color: rgba(${props => parseInt(props.theme.text.substring(1,3),16)+","+parseInt(props.theme.text.substring(3,5),16)+","+parseInt(props.theme.text.substring(5,7),16)},0.5);
  };
  &:-ms-input-placeholder {
    color: rgba(${props => parseInt(props.theme.text.substring(1,3),16)+","+parseInt(props.theme.text.substring(3,5),16)+","+parseInt(props.theme.text.substring(5,7),16)},0.5);
  };
  &:-ms-input-placeholder {
    color: rgba(${props => parseInt(props.theme.text.substring(1,3),16)+","+parseInt(props.theme.text.substring(3,5),16)+","+parseInt(props.theme.text.substring(5,7),16)},0.5);
  };
  &:disabled {
    opacity: 0.5;
  };
`

export const Image = styled.img`
  ${props => props.maxHeight ? `max-height: `+props.maxHeight+`!important;` : ``}
  @media (max-width:600px){
    ${props => props.mobile ? `max-width: 100%;` : ``}
  };
  ${props => props.character ? 
    `max-height: 40px;
     max-width: 40px;
     vertical-align: middle;` : ``}
`

export const Href = styled.a`
  text-decoration: underline;
  color: ${props => props.color ? props.color : props.theme.text };
  ${props => props.tiny ? `font-size:10px!important;` : ``}
  ${props => props.small ? `font-size:12px!important;` : ``}
  ${props => props.medium ? `font-size:15px!important;` : ``}
  ${props => props.large ? `font-size:18px!important;` : ``}
  &:hover {
    color: ${props => props.theme.primary};
    text-decoration: underline;
  };
`

export const URLButton = styled.a`
  text-decoration: underline;
  font-family: ${props => props.theme.fontFamily};
  font-weight: bold;
  font-size: 13px;
  display:inline-block;
  padding: ${props => props.padding ? String(props.padding).split(",").join("px ")+`px;` : `6px 24px`};
  ${props => props.margin ? `margin:`+String(props.margin).split(",").join("px ")+`px;` : ``}
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  text-transform: uppercase;
  color: ${props => props.primary ? props.theme.text : props.theme.separator};
  background-color:${props => props.primary ? props.theme.primary : props.theme.text};
  text-align: center;
  ${props => props.align ? `text-align: `+props.align+`;` : ``}
  cursor: ${props => props.cursor ? props.cursor : `pointer`};
  white-space: ${props => props.wrap ? `normal` : `nowrap`};
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  border-image: linear-gradient(to right,${props => props.theme.primary},${props => props.theme.separator}) 1 stretch;
  border:0.9px solid ${props => props.primary ? props.theme.primary : props.theme.text};
  border-radius: ${props => props.radius ? props.radius : `12`}px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  &:hover {
    color: ${props => props.theme.highlight};
  };
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  };
  &:disabled:hover {
    box-shadow:none;
  };
  ${props => props.block ? `display:block; width:100%;` : ``}
  ${props => props.borderButton ? `border: 1.5px solid ${props.borderButton}; background: none; color: ${props.borderButton};` :``}
`

export const DropBox = styled.div`
  width: 100%;
  .file-drop {
    /* relatively position the container bc the contents are absolute */
    position: relative;
    height: 310px;
    width: 100%;
    border: 2px dashed rgba(${props => parseInt(props.theme.text.substring(1,3),16)+","+parseInt(props.theme.text.substring(3,5),16)+","+parseInt(props.theme.text.substring(5,7),16)},0.5);
    box-sizing: border-box;
    border-radius: 16px;
  };

  .file-drop > .file-drop-target {
    /* basic styles */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 2px;
    /* --------------- */

    /* horizontally and vertically center all content */
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;

    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;

    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;

    align-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;

    text-align: center;
    /* --------------- */
  };

  .file-drop > .file-drop-target.file-drop-dragging-over-frame {
    /* overlay a black mask when dragging over the frame */
    border: none;
    background-color: rgba(0, 0, 0, 0.65);
    box-shadow: none;
    z-index: 5;
    opacity: 1.0;
    /* --------------- */

    /* typography */
    color: ${props => props.theme.text};
    /* --------------- */
  };

  .file-drop > .file-drop-target.file-drop-dragging-over-target {
    /* turn stuff orange when we are dragging over the target */
    color: ${props => props.theme.primary};
    box-shadow: 0 0 13px 3px ${props => props.theme.primary};
  };
`

export const FileInput = styled.input`
  width: 1px;
  height: 1px;
  background-color: ${props => props.theme.secondary};
  opacity: 0;
  overflow: hidden;
	position: absolute;
	z-index: -1;
`

export const ColorInput = styled.input`
  border-radius: 0;
  border: none;
  background: rgba(${props => parseInt(props.theme.highlight.substring(1,3),16)+","+parseInt(props.theme.highlight.substring(3,5),16)+","+parseInt(props.theme.highlight.substring(5,7),16)},0.1);
  padding: 0px;
  display: block;
  width: 100%;
  ${props => props.maxWidth ? `max-width: `+props.maxWidth+`;` : ``}
  height: 34px;
  opacity: 1;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
  };
`