import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button, { BUTTON_STYLE, BUTTON_SIZE } from 'components/Product/Atoms/Button/Button'
import UploadProgressIndicator from 'components/Product/Molecules/UploadProgressIndicator'
import NotificationSlider from 'components/Product/Molecules/NotificationSlider'
import Icon, { ICON_KEY } from 'components/Product/Atoms/Icon/Icon'
import Text from 'components/Product/Atoms/Text/Text'

import { appTheme } from "config/theme"
import { RESTRICTIONS } from 'config/enums'

export const MENU_STATE = {
  EXPANDED: "EXPANDED",
  COLLAPSED: "COLLAPSED",
  MOBILE: "MOBILE",
  HIDDEN: "HIDDEN",
}

const getStyleBasedOnState = (props, menuState, attribute) => {
  const styles = {
    [MENU_STATE.EXPANDED]: {
      width: `width: ${props.fixedWidth}px;`,
      positionConstraints: `top:0; left: 0;`,
      background: `background: ${props.theme.colors.menuBackground};`,
      logoRowWidth: `padding: 30px 30px 16px 30px;`,
      logoContent: props.logoWide ? `content: url(${props.logoWide}); width: 100%` : `content: url(${props.logoSmall});`,
      menuIconCellPadding: "padding-left: 32px;",
      logoCellTextAlign: "text-align: left;",
      adminRowWidth: `padding: 0 32px 16px 32px; width: 100%;`,
      adminDropdownIconCellAlign: `text-align: right;`,
      buttonRowPadding: `padding: 16px 0 16px 32px;`,
      menuRowPadding: "padding: 16px 0;",
      menuRowBeforeMarginTop: "margin-top: -5px;"
    },
    [MENU_STATE.COLLAPSED]: {
      width: `width: ${props.fixedCollapsedWidth}px;`,
      positionConstraints: `top:0; left: 0;`,
      background: `background: ${props.theme.colors.menuBackground};`,
      logoRowWidth: `padding: 30px 0 16px 0; width: 100%;`,
      logoContent: `content: url(${props.logoSmall});`,
      logoCellTextAlign: "text-align: center;",
      menuContentCellDisplay: `display: none;`,
      adminRowWidth: `padding: 0 0 19px 0; width: 100%;`,
      adminDropdownTextCellDisplay: `display: none;`,
      adminDropdownIconCellAlign: `text-align: center;`,
      buttonRowPadding: `padding: 22px 0 22px 22px;`,
      menuRowPadding: "padding: 19px 0;",
      menuRowBeforeMarginTop: "margin-top: -8px;"
    },
    [MENU_STATE.MOBILE]: {
      width: `width: ${props.fixedWidth}px;`,
      positionConstraints: `top: ${props.theme.style.topMenuHeight}px;right : 0;`,
      background: `background: ${props.theme.colors.darkPanelBackground};`,
      logoRowWidth: `padding: 15px 30px 0 30px;`,
      logoRowDisplay: `display: none;`,
      adminRowWidth: `padding: 0 16px 16px 16px; width: calc(100% - 32px);`,
      adminDropdownIconCellAlign: `text-align: right;`,
      buttonRowPadding: `padding: 16px 0 16px 32px;`,
      menuIconCellPadding: "padding-left: 16px;",
    },
  }
  if (styles[menuState] && styles[menuState][attribute]) {
    return styles[menuState][attribute]
  }
  return ``
}


export const Row = styled.div`
display: table;
${props => props.block ? `width: 100%;` : ``}
&:before {
  content: "";
  display: table;
  clear: both;
};
&:after {
  content: "";
  display: table;
  clear: both;
};
`

export const Cell = styled.div`
${props => props.block ? `width: 100%;` : ``}
display: table-cell;
vertical-align: middle;
`

export const IconRow = styled.div`
  @media (min-width:601px){
    display:none;
  }
  display: table;
  text-align: center;
  width: 100%;
  padding: 16px 0;
`

export const SideMenuContainer = styled.div`
${props => getStyleBasedOnState(props, props.menuState, "positionConstraints")}
${props => getStyleBasedOnState(props, props.menuState, "background")}
${props => getStyleBasedOnState(props, props.menuState, "width")}
display: flex;
flex-direction: column;
justify-content: space-between;
z-index: 50;
position: fixed;
height: 100%;
${props => props.logoBackground ?
    `:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    opacity: 1;
    background-image: url('${props.logoBackground}');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    z-index: -1;
  }` : null}
border-right: 1px solid ${props => props.theme.colors.menuBorder};
-webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; 
-moz-user-select: none; -ms-user-select: none; user-select: none;
overflow-x: hidden;
overflow-y: auto;
`

export const LogoRow = styled(Row)`
${props => getStyleBasedOnState(props, props.menuState, "logoRowWidth")}
${props => getStyleBasedOnState(props, props.menuState, "logoRowDisplay")}
`

export const LogoCell = styled(Cell)`
${props => getStyleBasedOnState(props, props.menuState, "logoCellTextAlign")}
cursor: pointer;
vertical-align: middle;
`

export const Logo = styled.img`
max-height: 30px;
${props => getStyleBasedOnState(props, props.menuState, "logoContent")}
`

export const MenuRow = styled(Row)`
${props => getStyleBasedOnState(props, props.menuState, "menuRowPadding")}
vertical-align: middle;
width: 100%;
cursor: pointer;
height: 24px;

&.active {
  background: ${props => props.theme.colors.menuActiveShine};
  cursor: auto;
  
  :before {
    ${props => getStyleBasedOnState(props, props.menuState, "menuRowBeforeMarginTop")}
    position: absolute;
    width: 3px;
    margin-right: -3px;
    height: 32px;
    background: ${props => props.theme.colors.brandColor};
  }
}
:hover {
  background: ${props => props.theme.colors.menuActiveShine};
}
`

export const MenuIconCell = styled(Cell)`
line-height: 12px;
width: 18px;
${props => getStyleBasedOnState(props, props.menuState, "menuIconCellPadding")}
text-align: center;
${props => props.flipped ? `svg {transform: scale(-1,1);}` : null}
`

export const MenuContentCell = styled(Cell)`
${props => getStyleBasedOnState(props, props.menuState, "menuContentCellDisplay")}
text-align: left;
padding-left: 16px;
`

export const ButtonRow = styled(Row)`
${props => getStyleBasedOnState(props, props.menuState, "buttonRowPadding")}
width: fit-content;
text-align: center;
`

export const ShinyIcon = styled(Icon)`
filter: drop-shadow(0px 0px 5px ${props => props.theme.colors.textHighlightShadow});
`

export const ShinyAnchor = styled.a`
font-family: ${props => props.theme.fonts.primary};
font-size: ${props => props.theme.fonts.small}px;
color: ${props => props.theme.colors.textHighlight};
filter: drop-shadow(0px 0px 5px ${props => props.theme.colors.textHighlightShadow});
text-decoration: none;
`

export const AdminRow = styled(Row)`
cursor: pointer;
color: ${props => props.theme.colors.textSecondary};
${props => getStyleBasedOnState(props, props.menuState, "adminRowWidth")}
`
export const AdminDropdownTextCell = styled(Cell)`
${props => getStyleBasedOnState(props, props.menuState, "adminDropdownTextCellDisplay")}
`
export const AdminDropdownIconCell = styled(Cell)`
line-height: 0;
${props => getStyleBasedOnState(props, props.menuState, "adminDropdownIconCellAlign")}
`

export default function SideMenu({
  id,
  logoSmall, logoWide, menuState, language,
  menuItems, activeMenuItem, adminMenuItems, restrictions, showAdmin, showHelp,
  onMenuItemClicked,
  ...props
}) {
  const _renderMenuElement = (menuItem, index, isActive) => {
    return (
      <MenuRow id={"mnu_" + menuItem.menuKey} className={isActive ? "active" : undefined} key={index}
        onClick={!isActive ? () => onMenuItemClicked(menuItem.menuKey) : undefined} theme={props.theme}
        menuState={menuState}
      >
        <MenuIconCell menuState={menuState} theme={props.theme}>
          <Icon iconKey={menuItem.iconKey} title={menuItem.label}
            style={{ height: props.theme.style.iconSize, width: props.theme.style.iconSize }}
            color={isActive ? props.theme.colors.textPrimary : props.theme.colors.textSecondary}
          />
        </MenuIconCell>
        <MenuContentCell menuState={menuState} theme={props.theme}>
          <Text theme={props.theme}
            color={isActive ? props.theme.colors.textPrimary : props.theme.colors.textSecondary}>{menuItem.label}</Text>
        </MenuContentCell>
      </MenuRow>
    )
  }

  const renderLogo = () => {
    if (menuState === MENU_STATE.MOBILE) {
      return null
    }
    return (
      <LogoRow id={"mnu_logo"} {...props} menuState={menuState} onClick={() => onMenuItemClicked("home")}>
        <LogoCell id="logo_icon" menuState={menuState} {...props}>
          <Logo logoSmall={logoSmall} logoWide={logoWide} menuState={menuState} alt="logo" theme={props.theme} />
        </LogoCell>
      </LogoRow>
    )
  }
  const renderIconRow = () => {
    return(
      <IconRow>
        {(showHelp)?<Icon 
          style={{ height: 18, width: 18, cursor: "pointer", marginRight: 24 }} 
          fill={props.theme.colors.iconActiveColor} 
          iconKey="QuestionCircle" id={id + "_help"} 
          onClick={() => onMenuItemClicked("help")} />:null}
        <Icon 
          style={{ height: 18, width: 18, cursor: "pointer", marginRight: 24 }} 
          fill={props.theme.colors.iconActiveColor} 
          iconKey="Profile" id={id + "_profile"} 
          onClick={() => onMenuItemClicked("profile")} />
        <Icon 
          style={{ height: 18, width: 18, cursor: "pointer"}} 
          fill={props.theme.colors.iconActiveColor} 
          iconKey="ExitDoor" id={id + "_logout"} 
          onClick={() => onMenuItemClicked("logout")} />
      </IconRow>
    )
  }
  const renderUserMenuElements = () => {
    return (
      <div id={"mnu_items"}>
        {menuItems.map((menuItem, index) => _renderMenuElement(menuItem, index, activeMenuItem === menuItem.menuKey))}
      </div>)
  }
  const renderAdminMenuElements = () => {
    return (
      <div id={`mnu_admin`} style={{ padding: "16px 0" }} data-state={showAdmin?'open':'closed'}>
        <AdminRow id={`mnu_admin_button`} theme={props.theme} onClick={() => onMenuItemClicked("showAdmin")} menuState={menuState} >
          <AdminDropdownTextCell menuState={menuState} theme={props.theme} >
            <Text theme={props.theme}
              color={props.theme.colors.textSecondary}>{props.labels.adminMenuHeader}</Text>
          </AdminDropdownTextCell>
          <AdminDropdownIconCell menuState={menuState} theme={props.theme}>
            <Icon iconKey={showAdmin ? "Up" : "Down"} title={props.labels.adminMenuHeader}
              style={{ height: props.theme.style.iconSize, width: props.theme.style.iconSize }}
              fill={props.theme.colors.textSecondary}
            />
          </AdminDropdownIconCell>
        </AdminRow>
        {showAdmin ?
          <div id={"mnu_admin_items"} style={{ marginBottom: "30px" }}>
            {adminMenuItems.map((menuItem, index) => _renderMenuElement(menuItem, index, activeMenuItem === menuItem.menuKey))}
          </div>
          : null}
      </div>
    )
  }
  const renderCTA = () => {
    const buttonSettings = {
      [MENU_STATE.COLLAPSED]: {
        icon: "UploadIcon",
        showIcon: true,
        size: BUTTON_SIZE.SMALL
      },
      [MENU_STATE.EXPANDED]: {
        label: props.labels.uploadVideo
      },
      [MENU_STATE.MOBILE]: {
        label: props.labels.uploadVideo
      }
    }

    return (
      <ButtonRow id={`mnu_upload_button`} menuState={menuState} theme={props.theme} >
        <Cell>
          <Button {...buttonSettings[menuState]}
            buttonStyle={BUTTON_STYLE.THIRD} theme={props.theme}
            onClick={() => onMenuItemClicked("upload")} />
        </Cell>
      </ButtonRow>
    )
  }
  const renderUploadProgress = () => {
    const isCollapsed = menuState === MENU_STATE.COLLAPSED
    return <Row id={'mnu_upload_progress'} block style={{ padding: "16px 0" }} onClick={() => onMenuItemClicked("upload")}>
      <Cell block>
        <UploadProgressIndicator {...props.uploadProgress} theme={props.theme} minimal={isCollapsed} collapsed={isCollapsed} renderButton={false} />
      </Cell>
    </Row>
  }
  const renderNotifications = () => {
    return (
      <Row id={'mnu_notifications'} block style={{ padding: "16px 0" }}>
        <Cell block>
          <NotificationSlider {...props.notificationSlider} 
            id={"mnu_notifications_slider"} theme={props.theme} iconView={menuState === MENU_STATE.COLLAPSED} />
        </Cell>
      </Row>
    )
  }
  const renderCustomLinks = () => {
    return (
      <div id={"mnu_custom_links"} style={{ marginBottom: "30px" }}>
        {props.customLinks.map((menuItem, index) => {
          const current = menuItem.filter((element => element.lang === language))[0]
          return (
            <MenuRow id={"mnu_custom_" + index} key={index} theme={props.theme} menuState={menuState}>
              <MenuIconCell menuState={menuState} theme={props.theme}>
                <ShinyIcon iconKey={current.iconKey} theme={props.theme}
                  style={{ height: props.theme.style.iconSize, width: props.theme.style.iconSize }}
                  fill={props.theme.colors.textHighlight}
                />
              </MenuIconCell>
              <MenuContentCell menuState={menuState} theme={props.theme}>
                <ShinyAnchor href={current.url} target="_blank" theme={props.theme}>{current.label}</ShinyAnchor>
              </MenuContentCell>
            </MenuRow>
          )
        })}
      </div>)
  }
  const renderCollapse = () => {
    if (menuState === MENU_STATE.MOBILE) {
      return null
    }
    return (
      <MenuRow id={"mnu_collapse"} onClick={() => onMenuItemClicked("collapse")} theme={props.theme} menuState={menuState}>
        <MenuIconCell theme={props.theme} menuState={menuState} flipped={menuState === MENU_STATE.COLLAPSED} style={{lineHeight: 0}}>
          <Icon iconKey={"LeftArrowDouble"} title={props.labels.collapse}
            style={{ height: 8, width: 10 }}
            fill={props.theme.colors.textSecondary}
          />
        </MenuIconCell>
        <MenuContentCell menuState={menuState} theme={props.theme}>
          <Text theme={props.theme}
            color={props.theme.colors.textSecondary} >{props.labels.collapse}</Text>
        </MenuContentCell>
      </MenuRow>
    )
  }
  if (menuState === MENU_STATE.HIDDEN) {
    return null
  }

  return <SideMenuContainer
    id={id}
    menuState={menuState}
    {...props}
  >
    <div>
      {renderLogo()}
    </div>
    <div style={{flexGrow: "1", overflowY: "auto"}}>
      {!restrictions.includes(RESTRICTIONS.USER_MENU) ? renderIconRow() : null}
      {renderUserMenuElements()}
      {renderCTA()}
      {!restrictions.includes(RESTRICTIONS.ADMIN) ? renderAdminMenuElements() : null}
      {props.uploadProgress ? renderUploadProgress() : null}
      {props.notificationSlider !== undefined && props.notificationSlider.slides.length > 0 ? renderNotifications() : null}
      {props.customLinks !== undefined && props.customLinks.length > 0 ? renderCustomLinks() : null}
    </div>
    <div >
      {renderCollapse()}
    </div>
  </SideMenuContainer>
}

SideMenu.propTypes = {
  /**
   * Component ID
   */
  id: PropTypes.string,
  /** 
   * Wide logo displayed on expanded menu
   * Optional, small logo will be displayed if missing
   */
  logoWide: PropTypes.string,
  /**
   * Square aspect ratio logo (1:1) to display on forceCollapsed menu
   */
  logoSmall: PropTypes.string.isRequired,
  /**
   * Background logo to display on the bottom of the menu bar in expanded state
   * Recommended to have a really low opacity (0.05)
   */
  logoBackground: PropTypes.string,
  /** 
   * Menu display state
   */
  menuState: PropTypes.oneOf(Object.values(MENU_STATE)),
  /**
   * List of menu items to display
   */
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    iconKey: PropTypes.oneOf(ICON_KEY),
    label: PropTypes.string.isRequired,
  })).isRequired,
  /**
   * List of admin menu items to display
   */
  adminMenuItems: PropTypes.arrayOf(PropTypes.shape({
    iconKey: PropTypes.oneOf(ICON_KEY),
    label: PropTypes.string.isRequired,
  })).isRequired,
  /**
   * MenuKey of the active menu item
   */
  activeMenuItem: PropTypes.string.isRequired,
  /**
   * Current language
   */
  language: PropTypes.string.isRequired,
  restrictions: PropTypes.array.isRequired,
  /**
   * Show admin menus as opened?
   */
  showAdmin: PropTypes.bool,
  /**
   * Show help icon?
   */
  showHelp: PropTypes.bool,
  /**
   * Component labels
   */
  labels: PropTypes.shape({
    uploadVideo: PropTypes.string.isRequired,
    collapse: PropTypes.string.isRequired,
    adminMenuHeader: PropTypes.string.isRequired,
  }).isRequired,
  /** 
   * If an upload is in progress, display status
   */
  uploadProgress: PropTypes.shape({ ...UploadProgressIndicator.propTypes }),
  /** 
   * If notifications are set, display them
   */
  notificationSlider: PropTypes.shape({ ...NotificationSlider.propTypes }),
  /** 
   * If custom links are set, display them
   */
  customLinks: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    lang: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    iconKey: PropTypes.oneOf(ICON_KEY),
  }))),
  fixedWidth: PropTypes.number.isRequired,
  fixedCollapsedWidth: PropTypes.number.isRequired,
  /**
   * Component brand theme
   */
  theme: PropTypes.object.isRequired,
  /**
   * Event handler for menu item clicks
   * Will call the provided function with the label of the clicked menu
   * example: const onMenuItemClicked = (label) => {}
   */
  onMenuItemClicked: PropTypes.func,
}

SideMenu.defaultProps = {
  id: undefined,
  logoWide: undefined,
  logoSmall: undefined,
  logoBackground: undefined,
  menuState: MENU_STATE.EXPANDED,
  menuItems: undefined,
  adminMenuItems: undefined,
  activeMenuItem: undefined,
  language: undefined,
  restrictions: [],
  showAdmin: false,
  showHelp: true,
  labels: undefined,
  uploadProgress: undefined,
  notificationSlider: undefined,
  customLinks: undefined,
  fixedWidth: 250,
  fixedCollapsedWidth: 80,
  theme: {...appTheme},
  onMenuItemClicked: undefined,
}